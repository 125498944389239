import Vue from 'vue'
import Vuex from 'vuex'


// Example Apps

import AuthModule from '../views/auth/store'
import CustomersModule from '../views/customers/store'
import PetsModule from '../views/pets/store'
import MedicalModule from '../views/medical/store'
import FeedbackModule from '../views/feedbacks/store'
import SalesModule from '../views/sales/store'
Vue.use(Vuex)

/**
 * Main Vuex Store
 */
const store = new Vuex.Store({
  modules: {
    'auth-module': AuthModule,
    'customers-module': CustomersModule,
    'pets-module': PetsModule,
    'medical-module': MedicalModule,
    'feedbacks-module': FeedbackModule,
    'sales-module': SalesModule
  }
})

export default store
