import SalesPoint from '../views/sales/SalesPage.vue'

export default [
    {
        path: '/inventories',
        name: 'inventarios',
        component: ()=> import('../views/sales/Inventario/InventariosPage.vue'),
        meta: {requireAuth: true}
    },
    {
        path: '/sales/point',
        name: 'point',
        component: SalesPoint,
        meta: {
            layout: "salesPoint",
            requireAuth: true,
        }
    },
 
]