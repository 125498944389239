
export default {
    addUser:(state, payload)=>{
        state.user = payload
    },
    addClinicaUser(state, payload){
        state.clinicaUser = payload
    },
    addErrorLogin:(state, payload)=>{
        state.errorPassLogin = payload
    },
    addErrorLoginMsg:(state, payload)=>{
        state.errorPassLoginMsg = payload
    },
    addSedes(state, payload){
        state.sedes = payload
    },
    addErrorLoginUser(state, payload){
        state.errorLoginUser = payload
    },
    addErrorLoginLicen(state, payload){
        state.errorLoginLicen = payload
    },
    addLicen(state, payload){
        state.licen = payload
    },
    addSuccessChangePasswordLoginLicen(state, payload){
        state.successChangePasswordLoginLicen = payload
        if(payload){
            setTimeout(()=>{state.successChangePasswordLoginLicen = false}, 2000)
        }
    },
    addFailChangePasswordLoginLicen(state, payload){
        state.failChangePasswordLoginLicen = payload
        if(payload){
            setTimeout(()=>{state.failChangePasswordLoginLicen = false}, 2000)
        }
    },
    addSuccessCreateEsign(state, payload){
        state.successCreateEsign = payload
        if(payload){
            setTimeout(()=>{state.successCreateEsign = false}, 2000)
        }
    },
    addLogo(state, payload){
        state.logoUpload = payload
    },
    addClinica(state, payload){
        state.clinica = payload
    },
    setProfileNotFound(state, payload){
        state.profileNotFound = payload
    }
}