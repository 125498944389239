export default {
    activeLoadingSearchInventarios: false,
    activeLoadingSearchCarritosPendientes: false,
    carritosPendientes: [],
    vtasser: [],
    pagosvtaser: [],
    vtasserCliente: [],
    pagosVtasserCliente: [],
    activeLoadingPagosVtasser: false,
    loadingVtasserCliente: false,
    loadingPagosVtasserCliente: false,
    loadingVtasserByDoc: false,
    activeLoadingVtasser: false,
    tablaimptos: [],
    successCreateCarrito: false,
    successCancelarCarrito: false,
    movimientosCaja: [],
    cierresCaja: [],
    planCuentas: [],
    activetLoadingSearchVtaserByDoc:false,
    successCreateTomaInventario:false,
    cierreCajaHoy: {
        Id: 0,
        Fecha: "",
        Totalventa: 0,
        Totalingresos: 0,
        Diferencias: 0,
        Sucursal: ""
    },
    cierreDeCajaUltimo: {
        Id: 0,
        Fecha: "",
        Totalventa: 0,
        Totalingresos: 0,
        Diferencias: 0,
        Sucursal: ""
    },
    loadingMovimientosCaja: false,
    loadingMovimientosCajaRange:false,
    successCreateMovimientoCaja: false,
    movimientosCajaRange:[],
    successCreateCierreCaja: false,
    successCreateCarritoBorrador: false,
    tablaimptosSii:[],
    activeLoadingSearchAllInventarios: false,
    activeLoadingSearchInventariosVtaser: false,
    inventarios:[],
    activeLoadingSearchAllComprasByInventario:false,
    proveedores:[],
    successCreateReposicionCompras: false,
    successCreateOc: false,
    activeLoadingSearchOc:false,
}