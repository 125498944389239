
export default {
    addMedicalCareVisits(state, payload) {
        state.medicalCareVisits.push(payload)
    },
    addAtenciondias(state, payload) {
        state.atenciondias = payload
    },
    addDoctors(state, payload) {
        state.doctors = payload
    },
    addDoctor(state, payload) {
        state.doctor = payload
    },
    addMotivovisitas(state, payload) {
        state.motivovisitas = payload
    },
    addDiagnostico(state, payload) {
        state.diagnostico = payload
    },
    addFames(state, payload) {
        state.fames = payload
    },
    addEspecialidades(state, payload) {
        state.especialidades = payload
    },
    addProdus(state, payload) {
        state.produs = payload
    },
    addFilterProdus(state, payload) {
        state.filterProdus = payload
    },
    setSuccessUpdateEspecialidad(state, payload) {
        state.successUpdateEspecialidad = payload
        if (payload) {
            setTimeout(() => { state.successUpdateEspecialidad = false }, 3000)
        }
    },
    setSuccessDeleteEspecialidad(state, payload) {
        state.successDeleteEspecialidad = payload
        if (payload) {
            setTimeout(() => { state.successDeleteEspecialidad = false }, 3000)
        }
    },
    addServisHistory(state, payload) {
        payload.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.Fecha) - new Date(a.Fecha);
          });
        state.servisHistory = payload
    },
    addServisByDate(state, payload) {
        payload.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.Fecha) - new Date(a.Fecha);
          });
        state.servis = payload
    },
    addAtenciondiasByCliente(state, payload) {
        state.atenciondiasByCliente = payload
    },

    activeLoadingCargarProdus(state, payload) {
        state.activeLoadingCargarProdu = payload
    },
    activeLoadingCargarFamilias(state, payload) {
        state.activeLoadingCargarFamilias = payload
    },
    addAtencionciaById(state, payload) {
        state.atenciondia = payload
    },
    addFamilias(state, payload){
        state.familias = payload
    },
    addChecklists(state, payload) {
        state.checklists = payload
    },
    addChecklist(state, payload) {
        state.checklist = payload
    },
    addSuccessCreateAtenciondia(state, payload) {
        state.successCreateAtenciondia = payload
        if (payload) {
            setTimeout(() => { state.successCreateAtenciondia = false }, 2000)
        }
    },
    addSuccessCreateProdu(state, payload) {
        state.successCreateProdu = payload
        if (payload) {
            setTimeout(() => { state.successCreateProdu = false }, 2000)
        }
    },
    addActiveCreateServisEntregado(state, payload) {
        state.activeCreateServisEntregado = payload
        if (payload) {
            setTimeout(() => { state.activeCreateServisEntregado = false }, 2000)
        }
    },
    setLoadingImportProdu(state, payload){
        state.activeLoadingImportProdu = payload
    },
    addDirectories(state, payload) {
        state.directories = payload
    },
    addAces(state, payload) {
        state.aces = payload
    },
    addSuccessCreateEspecialidad(state, payload) {
        state.successCreateEspecialidad = payload
        if (payload) {
            setTimeout(() => { state.successCreateEspecialidad = false }, 2000)
        }
    },
    addSuccessCreateDoctor(state, payload) {
        state.successCreateDoctor = payload
        if (payload) {
            setTimeout(() => { state.successCreateDoctor = false }, 2000)
        }
    },
    addSuccessCreateDiagnostico(state, payload) {
        state.successCreateDiagnostico = payload
        if (payload) {
            setTimeout(() => { state.successCreateDiagnostico = false }, 2000)
        }
    },
    addSuccessCreateChecklist(state, payload) {
        state.successCreateChecklist = payload
        if (payload) {
            setTimeout(() => { state.successCreateChecklist = false }, 2000)
        }
    },
    addSuccessCreateService(state, payload) {
        state.successCreateService = payload
        if (payload) {
            setTimeout(() => { state.successCreateService = false }, 2000)
        }
    },
    setSuccessDeleteProdu(state, payload) {
        state.successDeleteProdu = payload
        if (payload) {
            setTimeout(() => { state.successDeleteProdu = false }, 2000)
        }
    },
    setDeleteProdu(state, payload) {
        console.log("borrare", payload)
        state.produs = state.produs.filter(item => item.Id !== payload)
    },

    //receta
    addSuccessCreateReceta(state, payload) {
        state.successCreateReceta = payload
        if (payload) {
            setTimeout(() => { state.successCreateReceta = false }, 2000)
        }
    },
    addRecetas(state, payload) {
        payload.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.Fecha) - new Date(a.Fecha);
          });
        state.recetas = payload
    },
    addReceta(state, payload) {
        state.receta = payload
    },
    addHorariospersonalByDoctor(state, payload) {
        var res = payload.sort(({ Var1: b }, { Var1: a }) => b - a);
        state.doctor.Horariospersonal = res
    },
    addFindSuccessHorarios(state, payload) {
        state.activefindsuccesshorarios = payload
    },
    addSuccessCreateHorariopersonal(state, payload){
        state.successCreateHorariopersonal = payload
        if (payload) {
            setTimeout(() => { state.successCreateHorariopersonal = false }, 2000)
        }
    },
    addSuccessCreateProgramacion(state, payload){
        state.successCreateProgramacion = payload
        if (payload) {
            setTimeout(() => { state.successCreateProgramacion = false }, 2000)
        }
    },
    addSuccessDeleteHorariopersonal(state, payload){
        state.successDeleteHorariopersonal = payload
        if (payload) {
            setTimeout(() => { state.successDeleteHorariopersonal = false }, 2000)
        }
    },
    addSuccessDeleteProgramacionById(state, payload){
        state.successDeleteProgramacionById = payload
        if (payload) {
            setTimeout(() => { state.successDeleteProgramacionById = false }, 2000)
        }
    },
    addSuccessDeleteAtenciondiaById(state, payload){
        state.successDeleteAtenciondiaById = payload
        if (payload) {
            setTimeout(() => { state.successDeleteAtenciondiaById = false }, 2000)
        }
    },
    addProgramaciones(state, payload){
        state.programaciones = payload
    },
    addMemos(state, payload){
        state.memos = payload
    },
    addSuccessSentEmail(state, payload){
        state.successSentEmail = payload
        if (payload) {
            setTimeout(() => { state.successSentEmail = false }, 2000)
        }
    },
    addHospitalizados(state, payload){
        state.hospitalizados = payload
    },
    addControlesHospitalizados(state, payload){
        state.controlesHospitalizados = payload
    },
    addProcedimientos(state, payload){
        state.procedimientos = payload
    },
    addSuccessCreateProcedimiento(state, payload){
        state.successCreateProcedimiento = payload
        if (payload) {
            setTimeout(() => { state.successCreateProcedimiento = false }, 2000)
        }
    },
    addSuccessCreateControlHospitalizado(state, payload){
        state.successCreateControlHospitalizado = payload
        if (payload) {
            setTimeout(() => { state.successCreateControlHospitalizado = false }, 2000)
        }
    },
    addSuccessUpdateControlHospitalizado(state, payload){
        state.successUpdateControlHospitalizado = payload
        if (payload) {
            setTimeout(() => { state.successUpdateControlHospitalizado = false }, 2000)
        }
    },
    addSuccessCreateAltaHospitalizado(state, payload){
        state.successCreateAltaHospitalizado = payload
        if (payload) {
            setTimeout(() => { state.successUpdateControlHospitalizado = false }, 2000)
        }
    },
    addSuccessCreateVentadepaso(state, payload){
        state.successCreateVentadepaso = payload
        if (payload) {
            setTimeout(() => { state.successCreateVentadepaso = false }, 2000)
        }
    },
    addSuccessDeleteVentadepaso(state, payload){
        state.successDeleteVentadepaso = payload
        if (payload) {
            setTimeout(() => { state.successDeleteVentadepaso = false }, 2000)
        }
    },
    addSuccessDeleteFile(state, payload){
        state.successDeleteFile = payload
        if (payload) {
            setTimeout(() => { state.successDeleteFile = false }, 2000)
        }
    },
    addSetControlHospitalizado(state, payload){
        state.hospitalizado = payload
    },
    addVentasdepaso(state, payload){
        state.ventasdepaso = payload
    },
    setSelectFichaCuentaHospital(state, payload){
        state.selectFichaCuentaHospital = payload
    },
    setSelectFichaHospitalizado(state, payload){
        state.selectFichaHospitalizado = payload
    },
    setSctiveLoadingSearchHospitalizados(state, payload){
        state.activeLoadingSearchHospitalizados = payload
    },
    setActiveLoadingSearchAtenciondias(state, payload){
        state.activeLoadingSearchAtenciondias = payload
    },
    setLoadingUpload(state, payload){
        state.loadingUpload = payload
    },
    setLoadingVentadepaso(state, payload){
        state.loadingVentadepaso = payload
    },
    addPackcodigos(state, payload){
        state.packcodigos = payload
    },
    addAgenda(state, payload){
        state.agendas = payload
    }

}
function compare(a, b) {
    if (a.Var1 < b.Var1) {
        return -1;
    }
    if (a.Var1 > b.Var1) {
        return 1;
    }
    return 0;
}
