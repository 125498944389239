import axios from "axios"
import { setDateNow } from "../../../config/utility"
import store from './../../../store/index'
function obtenerCantidadDias(mes) {
  const mesesCon31Dias = [1, 3, 5, 7, 8, 10, 12];
  const mesesCon30Dias = [4, 6, 9, 11];

  if (mes === 2) {
    return 28; // En este caso, asumiremos que el año no es bisiesto
  } else if (mesesCon31Dias.includes(mes)) {
    return 31;
  } else if (mesesCon30Dias.includes(mes)) {
    return 30;
  } else {
    return -1; // Si el mes no es válido, se devuelve -1
  }
}
export default {
  async getInventariosByCodigo({ commit }, data) {
    try {
      commit('setActiveLoadingSearchInventarios', true)
      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let query = `https://service.clivenapp.cl/inventarios?cod=${data.codigo}&token=${clinicaUser.Token}`
      let headers = {
        "content-type": "application/json"
      }
      let inventarios = []
      await axios.get(query, { headers: headers })
        .then((result) => {
          if (result.status == 200) {
            inventarios = result.data
          }
          commit('setActiveLoadingSearchInventarios', false)
        })
      return inventarios
    } catch (error) {

      throw new Error("no se pudo traer el inventarios por codigo")
    }
  },
  async getAllInventarios({ commit }, data) {
    try {
      commit('setActiveLoadingSearchAllInventarios', true)
      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let query = `https://service.clivenapp.cl/inventarios/all?token=${clinicaUser.Token}&sucursal=${data.sucursal}`
      let headers = {
        "content-type": "application/json"
      }
      let inventarios = []
      await axios.get(query, { headers: headers })
        .then((result) => {
          if (result.status == 200) {

            commit('addInventarios', result.data)
          }
          commit('setActiveLoadingSearchAllInventarios', false)
        })

      return inventarios
    } catch (error) {

      throw new Error("no se pudo traer el inventarios por codigo")
    }
  },
  async getAllInventariosByVtaser({ commit }, data) {
    try {

      commit('setActiveLoadingSearchAllInventariosVtaser', true)
      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let query = `https://service.clivenapp.cl/vtaser/inventarios?token=${clinicaUser.Token}&cod=${data.cod}&start_date=${data.start_date}&end_date=${data.end_date}&sucursal=${data.sucursal}`
      let headers = {
        "content-type": "application/json"
      }
      let inventarios = []
      await axios.get(query, { headers: headers })
        .then((result) => {
          if (result.status == 200) {

            inventarios = result.data
          }
          commit('setActiveLoadingSearchAllInventariosVtaser', false)
        })

      return inventarios
    } catch (error) {

      throw new Error("no se pudo traer el inventarios por codigo")
    }
  },
  async getAllComprasByInventario({ commit }, data) {
    try {
      commit('setActiveLoadingSearchAllComprasByInventario', true)
      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let query = `https://service.clivenapp.cl/inventarios/compras?token=${clinicaUser.Token}&cod=${data.cod}&start_date=${data.start_date}&end_date=${data.end_date}&sucursal=${data.sucursal}`
      let headers = {
        "content-type": "application/json"
      }
      let compras = []
      await axios.get(query, { headers: headers })
        .then((result) => {
          if (result.status == 200) {

            compras = result.data
          }
          commit('setActiveLoadingSearchAllComprasByInventario', false)
        })

      return compras
    } catch (error) {

      throw new Error("no se pudo traer el inventarios por codigo")
    }
  },
  async postVtasserpaso({ commit, dispatch }, data) {
    try {

      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))

      let query = `https://service.clivenapp.cl/punto/vtaserpaso?token=${clinicaUser.Token}&hospital=${data.hospital}`
      let headers = {
        "content-type": "application/json"
      }

      await axios.post(query, data.vtasserpaso, { headers: headers })
        .then((result) => {
          if (result.status == 201) {
            commit('addSuccessCreateCarrito', true)
            dispatch('getVtasserpasoPendientes', {})
            if (data.hospital) {

              store.dispatch('medical-module/getVentasdepaso');
            }
          }

        })
    } catch (error) {
      throw new Error("no se pudo grabar las ventas de carro")
    }
  },
  async postVtasserpasoBorrador({ commit, dispatch }, data) {
    try {

      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let query = `https://service.clivenapp.cl/punto/vtaserpaso/borrador?token=${clinicaUser.Token}`
      let headers = {
        "content-type": "application/json"
      }
      await axios.post(query, data.vtasserpaso, { headers: headers })
        .then((result) => {
          if (result.status == 201) {
            commit('addSuccessCreateCarritoBorrador', true)
          }

        })
    } catch (error) {
      throw new Error("no se pudo grabar las ventas de carro")
    }
  },
  async postVtasserpasoNotaBorrador({ commit, dispatch }, data) {
    try {
      let respuesta = ""
      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let query = `https://service.clivenapp.cl/punto/vtaserpaso/nota?token=${clinicaUser.Token}`
      let headers = {
        "content-type": "application/json"
      }
      await axios.post(query, data.nota, { headers: headers })
        .then((result) => {
          if (result.status == 201) {
            respuesta = result.data
          }

        })

      return respuesta
    } catch (error) {
      throw new Error("no se pudo grabar las ventas de carro")
    }
  },
  async postVtasserpasoGuiaBorrador({ commit, dispatch }, data) {
    try {
      let respuesta = ""
      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let query = `https://service.clivenapp.cl/punto/vtaserpaso/guia?token=${clinicaUser.Token}`
      let headers = {
        "content-type": "application/json"
      }
      await axios.post(query, data.guia, { headers: headers })
        .then((result) => {
          if (result.status == 201) {
            respuesta = result.data
          }

        })
      return respuesta
    } catch (error) {
      throw new Error("no se pudo grabar las ventas de carro")
    }
  },
  async postPDFDocumentByEmail({ commit }, data) {
    try {

      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let query = `https://service.clivenapp.cl/punto/vtaserpaso/email?token=${clinicaUser.Token}`
      let headers = {
        "content-type": "application/json"
      }
      let response = ""
      await axios.post(query, data.email, { headers: headers })
        .then((result) => {
          if (result.status == 201) {
            response = result.status
          }

        })
      return response
    } catch (error) {
      throw new Error("no se pudo grabar el pago del carro")
    }
  },
  async postPagoVtasser({ commit, dispatch }, data) {
    try {

      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let query = `https://service.clivenapp.cl/punto/vtaserpaso/pagos?token=${clinicaUser.Token}&uid=${data.uid}&`
      let headers = {
        "content-type": "application/json"
      }
      let response = ""
      await axios.post(query, data.pago, { headers: headers })
        .then((result) => {
          if (result.status == 201) {
            response = result.data
            let now = setDateNow()
            dispatch('getVtasserByDate', { startdate: now.toISOString().substring(0, 10), enddate: now.toISOString().substring(0, 10) })
            dispatch('getPagosVtasserByDate', { startdate: now.toISOString().substring(0, 10), enddate: now.toISOString().substring(0, 10) })
            dispatch('getVtasserpasoPendientes', {})
          }

        })
      return response
    } catch (error) {
      throw new Error("no se pudo grabar el pago del carro")
    }
  },
  async postPagoVtasserBorrador({ commit, dispatch }, data) {
    try {

      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let query = `https://service.clivenapp.cl/punto/vtaserpaso/pagos/borrador?token=${clinicaUser.Token}&uid=${data.uid}`
      let headers = {
        "content-type": "application/json"
      }
      let response = ""
      await axios.post(query, data.pago, { headers: headers })
        .then((result) => {
          if (result.status == 201) {
            response = result.data

            let now = setDateNow()
            dispatch('getVtasserByDate', { startdate: now.toISOString().substring(0, 10), enddate: now.toISOString().substring(0, 10) })
            dispatch('getPagosVtasserByDate', { startdate: now.toISOString().substring(0, 10), enddate: now.toISOString().substring(0, 10) })
          }

        })
      return response
    } catch (error) {
      throw new Error("no se pudo grabar el pago del carro")
    }
  },
  async postCancelarCarrito({ commit, dispatch }, data) {
    try {

      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let query = `https://service.clivenapp.cl/punto/vtaserpaso/cancelar?token=${clinicaUser.Token}&uid=${data.uid}`
      let headers = {
        "content-type": "application/json"
      }
      let response = ""
      await axios.post(query, data.pago, { headers: headers })
        .then((result) => {
          if (result.status == 201) {
            commit('addSuccessCancelarCarrito', true)
            dispatch('getVtasserpasoPendientes', {})
          }

        })
      return response
    } catch (error) {
      throw new Error("no se pudo grabar el pago del carro")
    }
  },
  async getVtasserpasoPendientes({ commit }, data) {
    try {
      commit('setActiveLoadingSearchCarritosPendiente', true)
      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let query = `https://service.clivenapp.cl/punto/vtaserpaso/pendiente?token=${clinicaUser.Token}`
      let headers = {
        "content-type": "application/json"
      }
      await axios.get(query, { headers: headers })
        .then((result) => {
          if (result.status == 200) {
            commit('addCarritosPendients', result.data)
          }
          commit('setActiveLoadingSearchCarritosPendiente', false)
        })
    } catch (error) {
      throw new Error("no se pudo traer el inventarios por codigo")
    }
  },
  async getTablaimptos({ commit }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let query = `https://service.clivenapp.cl/tablaimptos?token=${clinicaUser.Token}`
      let headers = {
        "content-type": "application/json"
      }
      await axios.get(query, { headers: headers })
        .then((result) => {
          if (result.status == 200) {
            let setresult = []
            result.data.forEach(element => {
              if (element.Tipodte == "0") {
                setresult.push(element)
              }
            });
            commit('addTablaimptos', setresult)
            let imptosii = []
            result.data.forEach(element => {
              if (element.Tipodte != "0") {
                imptosii.push(element)
              }
            });
            commit('addTablaimptosSII', imptosii)

          }
        })
    } catch (error) {
      throw new Error("no se pudo los tipos de imptos")
    }
  },
  async getVtasserpasoCarritoUid({ commit }, data) {
    try {

      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let query = `https://service.clivenapp.cl/punto/vtaserpaso/uid?uid=${data.uid}&token=${clinicaUser.Token}`
      let headers = {
        "content-type": "application/json"
      }
      let carritoPendiente = []
      await axios.get(query, { headers: headers })
        .then((result) => {
          if (result.status == 200) {
            carritoPendiente = result.data
          }

        })
      return carritoPendiente
    } catch (error) {
      throw new Error("no se pudo traer el inventarios por codigo")
    }
  },
  async getVtasserByDate({ commit }, data) {
    try {
      commit('addSetLoadingVtasser', true)
      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let userLicen = JSON.parse(localStorage.getItem('user'))
      let query = `https://service.clivenapp.cl/punto/vtaser/fecha?token=${clinicaUser.Token}&startdate=${data.startdate}&enddate=${data.enddate}`
      let headers = {
        "content-type": "application/json"
      }

      await axios.get(query, { headers: headers })
        .then((result) => {
          if (result.status == 200) {
            let filterresult = []
            result.data.forEach(element => {
              if (element.Sucursal == userLicen.Sucursal) {
                filterresult.push(element)
              }
            })
            commit('addVtasser', filterresult)
            commit('addSetLoadingVtasser', false)
          }

        })
    } catch (error) {
      throw new Error("no se pudo traer vtasser")
    }
  },
  async getVtasserByCliente({ commit }, data) {
    try {
      commit('addLoadingVtasserCliente', true)
      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let query = `https://service.clivenapp.cl/punto/vtaser/cliente?token=${clinicaUser.Token}&fichan=${data.fichan}`
      let headers = {
        "content-type": "application/json"
      }

      await axios.get(query, { headers: headers })
        .then((result) => {
          if (result.status == 200) {
            commit('addVtasserCliente', result.data)
            commit('addLoadingVtasserCliente', false,)
          }

        })
    } catch (error) {
      throw new Error("no se pudo traer vtasser")
    }
  },
  async getPagosVtasserByCliente({ commit }, data) {
    try {
      commit('addLoadingPagosVtasserCliente', true)
      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let query = `https://service.clivenapp.cl/punto/vtaserpaso/pagos/cliente?token=${clinicaUser.Token}&fichan=${data.fichan}`
      let headers = {
        "content-type": "application/json"
      }

      await axios.get(query, { headers: headers })
        .then((result) => {
          if (result.status == 200) {
            commit('addPagosVtasserCliente', result.data)
            commit('addLoadingPagosVtasserCliente', false,)
          }

        })
    } catch (error) {
      throw new Error("no se pudo traer los pagos de vtasser")
    }
  },
  async getVtasserByDocumento({ commit }, data) {
    try {
      commit('addLoadingVtasserByDoc', true)
      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let query = `https://service.clivenapp.cl/punto/vtaser/documento?token=${clinicaUser.Token}&ndoc=${data.ndoc}&tipodoc=${data.tipodoc}&fichan=${data.fichan}&fecha=${data.fecha}`
      let headers = {
        "content-type": "application/json"
      }
      let vtasser = []
      await axios.get(query, { headers: headers })
        .then((result) => {
          if (result.status == 200) {
            vtasser = result.data
            commit('addLoadingVtasserByDoc', false)
          }

        })
      return vtasser
    } catch (error) {
      throw new Error("no se pudo traer vtasser")
    }
  },
  async getVtasserBorradorByTipodoc({ commit }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let query = `https://service.clivenapp.cl/punto/vtaserpaso/borrador?token=${clinicaUser.Token}&ndoc=${data.ndoc}&tipodoc=${data.tipodoc}`
      let headers = {
        "content-type": "application/json"
      }
      let vtasser = []
      await axios.get(query, { headers: headers })
        .then((result) => {
          if (result.status == 200) {
            vtasser = result.data

          }

        })
      return vtasser
    } catch (error) {
      throw new Error("no se pudo traer vtasser")
    }
  },
  async getVtasserByTipodocValidarDte({ commit }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let query = `https://service.clivenapp.cl/punto/vtaserpaso/borrador?token=${clinicaUser.Token}&ndoc=${data.ndoc}&tipodoc=${data.tipodoc}`
      let headers = {
        "content-type": "application/json"
      }
      let vtasser = []
      await axios.get(query, { headers: headers })
        .then((result) => {
          if (result.status == 200) {
            vtasser = result.data

          }

        })
      return vtasser
    } catch (error) {
      throw new Error("no se pudo traer vtasser")
    }
  },
  async getMovimientosCajaByDate({ commit }, data) {
    try {
      commit('addLoadingMovimientosCaja', true)
      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let userLicen = JSON.parse(localStorage.getItem('user'))
      let query = `https://service.clivenapp.cl/punto/caja/movimientos?token=${clinicaUser.Token}&start_date=${data.start_date}&end_date=${data.end_date}&sucursal=${data.sucursal}&plancuenta=${data.plancuentas}`
      let headers = {
        "content-type": "application/json"
      }
      await axios.get(query, { headers: headers })
        .then((result) => {
          if (result.status == 200) {
            let filterresult = []
            result.data.forEach(element => {
              if (element.Sucursal == userLicen.Sucursal) {
                filterresult.push(element)
              }
            })
            commit('addMovimientosCaja', filterresult)
            commit('addLoadingMovimientosCaja', false)
          }

        })
    } catch (error) {
      throw new Error("no se pudo traer movimientos caja")
    }
  },
  async getMovimientosCajaByDateRange({ commit }, data) {
    try {
      commit('addLoadingMovimientosCajaRange', true)
      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let query = `https://service.clivenapp.cl/punto/caja/movimientos?token=${clinicaUser.Token}&start_date=${data.start_date}&end_date=${data.end_date}&sucursal=${data.sucursal}&plancuenta=${data.plancuentas}`
      let headers = {
        "content-type": "application/json"
      }
      await axios.get(query, { headers: headers })
        .then((result) => {
          if (result.status == 200) {
            commit('addMovimientosCajaRange', result.data)
            commit('addLoadingMovimientosCajaRange', false)
          }

        })
    } catch (error) {
      throw new Error("no se pudo traer movimientos caja")
    }
  },
  async getCierresCajaByDate({ commit }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let userLicen = JSON.parse(localStorage.getItem('user'))
      let query = `https://service.clivenapp.cl/punto/caja/cierres?token=${clinicaUser.Token}&start_date=${data.start_date}&end_date=${data.end_date}&sucursal=${data.sucursal}`
      let headers = {
        "content-type": "application/json"
      }
      await axios.get(query, { headers: headers })
        .then((result) => {
          if (result.status == 200) {
            let filterresult = []
            result.data.forEach(element => {
              if (element.Sucursal == userLicen.Sucursal) {
                filterresult.push(element)
              }
            })
            commit('addCierresCaja', filterresult)
          }

        })
    } catch (error) {
      throw new Error("no se pudo traer movimientos caja")
    }
  },
  async getPlanCuentas({ commit }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let query = `https://service.clivenapp.cl/punto/caja/plancuentas?token=${clinicaUser.Token}`
      let headers = {
        "content-type": "application/json"
      }
      await axios.get(query, { headers: headers })
        .then((result) => {
          if (result.status == 200) {
            commit('addPlanCuentas', result.data)
          }

        })
    } catch (error) {
      throw new Error("no se pudo traer los planes de cuenta")
    }
  },
  async getProveedores({ commit }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let query = `https://service.clivenapp.cl/proveedores?token=${clinicaUser.Token}`
      let headers = {
        "content-type": "application/json"
      }
      await axios.get(query, { headers: headers })
        .then((result) => {
          if (result.status == 200) {
            commit('addProveedores', result.data)
          }

        })
    } catch (error) {
      throw new Error("no se pudo traer los planes de cuenta")
    }
  },
  async postReposicionCompras({ commit, dispatch }, data) {
    try {

      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let query = `https://service.clivenapp.cl/inventarios/compras?token=${clinicaUser.Token}`
      let headers = {
        "content-type": "application/json"
      }
      let response = ""
      await axios.post(query, data.compras, { headers: headers })
        .then((result) => {
          if (result.status == 201) {
            commit('addSuccessCreateReposicionCompras', true)
            dispatch('getAllInventarios', { sucursal: data.compras[0].Sucursal })
          }

        })
      return response
    } catch (error) {
      throw new Error("no se pudo grabar el pago del carro")
    }
  },
  async postActualizacionTomaInventario({ commit, dispatch }, data) {
    try {

      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let query = `https://service.clivenapp.cl/inventarios/toma?token=${clinicaUser.Token}`
      let headers = {
        "content-type": "application/json"
      }
      let response = ""
      await axios.post(query, data.inventarios, { headers: headers })
        .then((result) => {
          if (result.status == 201) {
            commit('addSuccessCreateTomaInventario', true)
            dispatch('getAllInventarios', { sucursal: data.sucursal })
          }

        })
      return response
    } catch (error) {
      throw new Error("no se pudo grabar el inventario")
    }
  },
  async postActualizacionTomaInventarioIndividual({ commit, dispatch }, data) {
    try {

      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let query = `https://service.clivenapp.cl/inventarios/toma?token=${clinicaUser.Token}`
      let headers = {
        "content-type": "application/json"
      }
      let response = ""
      await axios.post(query, data.inventarios, { headers: headers })
        .then((result) => {
          if (result.status == 201) {
            commit('addSuccessCreateTomaInventario', true)
            //dispatch('getAllInventarios', { sucursal: data.sucursal })
          }

        })
      return response
    } catch (error) {
      throw new Error("no se pudo grabar el inventario")
    }
  },
  async postMovimientoCaja({ commit, dispatch }, data) {
    try {

      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let query = `https://service.clivenapp.cl/punto/caja/movimientos?token=${clinicaUser.Token}`
      let headers = {
        "content-type": "application/json"
      }
      let response = ""
      await axios.post(query, data.movimientoCaja, { headers: headers })
        .then((result) => {
          if (result.status == 201) {
            commit('addSuccessCreateMovimientoCaja', true)
            dispatch('getMovimientosCajaByDate', {
              start_date: data.date,
              end_date: data.date,
              sucursal: data.sucursal,
              plancuentas: "",
            })
          }

        })
      return response
    } catch (error) {
      throw new Error("no se pudo grabar el pago del carro")
    }
  },
  async postCierreCaja({ commit, dispatch }, data) {
    try {

      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let query = `https://service.clivenapp.cl/punto/caja/cierres?token=${clinicaUser.Token}&date=${data.date}&sucursal=${data.sucursal}`
      let headers = {
        "content-type": "application/json"
      }
      let response = ""
      let today = setDateNow().toISOString().substring(0, 10);
      const mes = parseInt(today.substring(6, 8));
      const cantidadDias = obtenerCantidadDias(mes);
      await axios.post(query, data.movimientoCaja, { headers: headers })
        .then((result) => {
          if (result.status == 201) {
            commit('addSuccessCreateCierreCaja', true)
            dispatch('getCierresCajaByDate', {
              start_date: today.substring(0, 8) + "01",
              end_date: today.substring(0, 8) + cantidadDias,
              sucursal: data.sucursal,
            })
          }

        })
      return response
    } catch (error) {
      throw new Error("no se pudo grabar el pago del carro")
    }
  },
  async getPagosVtasserByDate({ commit }, data) {
    try {
      commit('addSetLoadingPagoVtasser', true)
      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let userLicen = JSON.parse(localStorage.getItem('user'))
      let query = `https://service.clivenapp.cl/punto/vtaserpaso/pagos/fecha?token=${clinicaUser.Token}&startdate=${data.startdate}&enddate=${data.enddate}`
      let headers = {
        "content-type": "application/json"
      }
      await axios.get(query, { headers: headers })
        .then((result) => {
          if (result.status == 200) {
            let filterresult = []
            result.data.forEach(element => {
              if (element.Sucursal == userLicen.Sucursal) {
                filterresult.push(element)
              }
            })
            commit('addPagosvtaser', filterresult)
            commit('addSetLoadingPagoVtasser', false)
          }

        })
    } catch (error) {
      throw new Error("no se pudo traer los pagos de vtasser")
    }
  },
  async getVtaserByFechaDocTipodoc({ commit }, data) {
    try {
      commit('addSetLoadingSearchVtaserByDoc', true)
      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let userLicen = JSON.parse(localStorage.getItem('user'))
      let query = `https://service.clivenapp.cl/punto/vtaserpaso/search?token=${clinicaUser.Token}&fecha=${data.fecha}&tipodoc=${data.tipodoc}&ndoc=${data.ndoc}`
      let headers = {
        "content-type": "application/json"
      }
      // dconsole.log(query)
      let resultset = []
      await axios.get(query, { headers: headers })
        .then((result) => {
          if (result.status == 200) {
            //console.log(result)
            let filterresult = []
            result.data.forEach(element => {
              if (element.Sucursal == userLicen.Sucursal) {
                filterresult.push(element)
              }
            })
            resultset = filterresult
            commit('addSetLoadingSearchVtaserByDoc', false)
          }

        })
      return resultset
    } catch (error) {
      throw new Error("no se pudo traer los pagos de vtasser")
    }
  },
  async postOcs({ commit, dispatch }, data) {
    try {

      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let query = `https://service.clivenapp.cl/oc?token=${clinicaUser.Token}`
      let headers = {
        "content-type": "application/json"
      }
      let response = ""
      await axios.post(query, data.oc, { headers: headers })
        .then((result) => {
          if (result.status == 201) {
            commit('addSuccessCreateOc', true)
            response = result.data
          }

        })
      return response
    } catch (error) {
      throw new Error("no se pudo grabar el pago del carro")
    }
  },
  async getOcsByFilter({ commit, dispatch }, data) {
    try {
      commit('addActiveLoadingSearchOc', true)
      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let query = `https://service.clivenapp.cl/oc?token=${clinicaUser.Token}&numero=${data.numero}&start_date=${data.start_date}&end_date=${data.end_date}&proveedor=${data.proveedor}&filter=${data.filter}`
      let headers = {
        "content-type": "application/json"
      }
      let ocs = []
      await axios.get(query, { headers: headers })
        .then((result) => {
          if (result.status == 200) {
            commit('addActiveLoadingSearchOc', false)
            ocs = result.data
          }

        })
      return ocs
    } catch (error) {
      throw new Error("no se pudo grabar el pago del carro")
    }
  },
}