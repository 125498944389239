

export default {
  addClientes(state, payload) {
    state.clientes = payload;
  },
  addRazas(state, payload) {
    state.razas = payload;
  },
  addEspecies(state, payload) {
    state.especies = payload;
  },
  setLoadingClientes(state, payload) {
    state.loadingClientes = payload;
  },
  setSuccessCreateCliente(state, payload) {
    state.successCreateCliente = payload;
    if (payload) {
      setTimeout(() => {
        state.successCreateCliente = false;
      }, 3000);
    }
  },
  setSuccessUpdateCliente(state, payload) {
    state.successUpdateCliente = payload;
    if (payload) {
      setTimeout(() => {
        state.successUpdateCliente = false;
      }, 3000);
    }
  },
  addSelectFichaClienteId(state, payload) {
    state.selectFichaClienteId = payload;
  },
  addCliente(state, payload) {
    state.cliente = payload;
  },
  addSuccessCreateEspecie(state, payload) {
    state.successCreateEspecie = payload;
    if (payload) {
      setTimeout(() => {
        state.successCreateEspecie = false;
      }, 3000);
    }
  },
  addSuccessCreateRaza(state, payload) {
    state.successCreateRaza = payload;
    if (payload) {
      setTimeout(() => {
        state.successCreateRaza = false;
      }, 2000);
    }
  },
  addSuccessUpdateRaza(state, payload) {
    state.successUpdateRaza = payload;
    if (payload) {
      setTimeout(() => {
        state.successUpdateRaza = false;
      }, 3000);
    }
  },
  addPlanes(state, payload) {
    state.planes = payload;
  },
  setCustomerEmpty(state, payload) {
    state.cliente = {
      Fichan: 0,
      Nombre: "",
      Amo: "",
      Fono: "",
      Nacimiento: "",
      Raza: "",
      Direccion: "",
      Comuna: "",
      Ciudad: "",
      Email: "",
      Sexo: "",
      Fotoactiva: "",
      Madre: "",
      Padre: "",
      Peso: "",
      Altura: "",
      Edad: "",
      Reg: "",
      Color: "",
      Esp: "",
      Insc: "",
      Microchip: "",
      Fichaant: "",
      Clinica: "",
      Drtratante: "",
      Peluqueria: "",
      Rut: "",
      Id: 0,
    };
  },
  setSuccessUpdateEspecie(state, payload) {
    state.successUpdateEspecie = payload;
    if (payload) {
      setTimeout(() => {
        state.successUpdateEspecie = false;
      }, 3000);
    }
  },
  setSuccessUpdateRaza(state, payload) {
    state.successUpdateRaza = payload;
    if (payload) {
      setTimeout(() => {
        state.successUpdateRaza = false;
      }, 3000);
    }
  },
  setSuccessDeleteRaza(state, payload) {
    state.successDeleteRaza = payload;
    if (payload) {
      setTimeout(() => {
        state.successDeleteRaza = false;
      }, 3000);
    }
  },
  setSuccessDeleteEspecie(state, payload) {
    state.successDeleteEspecie = payload;
    if (payload) {
      setTimeout(() => {
        state.successDeleteEspecie = false;
      }, 3000);
    }
  },
};
