export default [
    {
        path: '/medical/visits',
        name: 'medical-visits',
        component: ()=> import('../views/medical/Atenciondia/AtenciondiaPage.vue'),
        meta: {requireAuth: true}
    },
    {
        path: '/medical/records',
        name: 'medical-records',
        component: ()=> import('../views/medical/FichaMedica/FichaMedicaPage.vue'),
        meta: {requireAuth: true}
    },
    {
        path: '/medical/hospital',
        name: 'medical-hospital',
        component: ()=> import('../views/medical/Hospital/HospitalPage.vue'),
        meta: {requireAuth: true}
    },
    {
        path: '/medical/sales',
        name: 'medical-sales',
        component: ()=> import('../views/medical/VentasClasificadas/VentasClasificadasPage.vue'),
        meta: {requireAuth: true}
    },
    {
        path: '/medical/agenda',
        name: 'medical-agenda',
        component: ()=> import('../views/medical/Agenda/AgendaPage.vue'),
        meta: {requireAuth: true}
    },
    {
        path: '/medical/specialties',
        name: 'medical-specialties',
        component: ()=> import('../views/medical/Specialties/SpecialtiesPage.vue'),
        meta: {requireAuth: true}
    },
    {
        path: '/medical/professionals',
        name: 'medical-professionals',
        component: ()=> import('../views/medical/Professionals/ProfessionalsPage.vue'),
        meta: {requireAuth: true}
    }
]