<template>
  <div>
    <!-- Contenedor de la lista -->
    <v-list-item
      v-if="!menuItem.items"
      :input-value="menuItem.value"
      :to="menuItem.link"
      :exact="menuItem.exact"
      :disabled="menuItem.disabled"
      active-class="white--text"
      link
      color="white"
      class="mt-2 mb-2"
    >
    <!-- Íconos -->
      <v-list-item-icon>
        <v-icon
          :small="small"
          color="white"
          size="30"
          :class="{ 'white--text': menuItem.disabled }"
        >
          {{ menuItem.icon || "mdi-circle-medium" }}
        </v-icon>
      </v-list-item-icon>
    <!-- Contenido de la lista -->
      <v-list-item-content class="list_content">
        <v-list-item-title>
          {{ menuItem.key ? $t(menuItem.key) : menuItem.text }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <!-- Lista -->
    <v-list-group
      v-else
      :value="menuItem.regex ? menuItem.regex.test($route.path) : false"
      :disabled="menuItem.disabled"
      :sub-group="subgroup"
      :to="menuItem.link"
      link
      color="white"
      class="mb-2"
    >

    <!-- Despliegue de lista -->
      <template v-slot:activator>
        <!-- Ícono de flecha -->
        <v-list-item-icon v-if="!subgroup" color="white">
          <v-icon color="white" :small="small" size="30">{{
            menuItem.icon || "mdi-circle-medium"
          }}</v-icon>
        </v-list-item-icon>
        <!-- Contenido de la lista -->
        <v-list-item-content class="titles">
          <v-list-item-title color="white">
         {{ menuItem.key ? $t(menuItem.key) : menuItem.text }}
          </v-list-item-title>
        </v-list-item-content>
      </template>
      <slot></slot>
    </v-list-group>
  </div>
</template>

<script>

// Artículos del menú de navegación
export default {
  props: {
    menuItem: {
      type: Object,
      default: () => {},
    },
    subgroup: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      user:"manager"
    }
  },
 
};
</script>

<style>
/* Títulos */
.titles {
  color: #fff;
}
/* Contenido de la lista */
.list_content {
  color: #ffff;
}
/* Color de ícono de despliegue */
.v-list .v-icon.v-icon {
  color: #ffff;
}

</style>
