
export default {
    setActiveLoadingSearchInventarios(state, payload) {
        state.activeLoadingSearchInventarios = payload
    },
    setActiveLoadingSearchAllInventariosVtaser(state, payload) {
        state.activeLoadingSearchInventariosVtaser = payload
    },
    setActiveLoadingSearchAllInventarios(state, payload) {
        state.activeLoadingSearchAllInventarios = payload
    },
    setActiveLoadingSearchCarritosPendiente(state, payload) {
        state.activeLoadingSearchCarritosPendientes = payload
    },
    setActiveLoadingSearchAllComprasByInventario(state, payload) {
        state.activeLoadingSearchAllComprasByInventario = payload
    },
    addCarritosPendients(state, payload) {
        state.carritosPendientes = payload
    },
    addProveedores(state, payload) {
        state.proveedores = payload
    },
    addVtasser(state, payload) {
        let setresult = []
        payload.forEach(element => {
            if(element.Tipodte == "0" || element.Tipodte == "39" || element.Tipodte == "41" || element.Tipodte == "33" || element.Tipodte == "34"){
                setresult.push(element)
            }
        })
        state.vtasser = setresult
    },
    addVtasserCliente(state, payload) {
        state.vtasserCliente = payload
    },
    addInventarios(state, payload) {
        state.inventarios = payload
    },

    addPagosVtasserCliente(state, payload) {
        state.pagosVtasserCliente = payload
    },
    addLoadingVtasserCliente(state, payload) {
        state.loadingVtasserCliente = payload
    },
    addLoadingMovimientosCaja(state, payload) {
        state.loadingMovimientosCaja = payload
    },
    addLoadingMovimientosCajaRange(state, payload) {
        state.loadingMovimientosCajaRange = payload
    },
    addLoadingPagosVtasserCliente(state, payload) {
        state.loadingPagosVtasserCliente = payload
    },
    addLoadingVtasserByDoc(state, payload) {
        state.loadingVtasserByDoc = payload
    },
    addTablaimptos(state, payload) {
        state.tablaimptos = payload
    },
    addTablaimptosSII(state, payload) {
        state.tablaimptosSii = payload
    },
    addPagosvtaser(state, payload) {
        state.pagosvtaser = payload
    },
    addSetLoadingVtasser(state, payload) {
        state.activeLoadingVtasser = payload
    },
    addSetLoadingPagoVtasser(state, payload) {
        state.activeLoadingPagosVtasser = payload
    },
    addSetLoadingSearchVtaserByDoc(state, payload) {
        state.activetLoadingSearchVtaserByDoc = payload
    },
    addMovimientosCaja(state, payload) {
        state.movimientosCaja = payload
    },
    addMovimientosCajaRange(state, payload) {
        state.movimientosCajaRange = payload
    },
    addCierresCaja(state, payload) {
        if (payload.length > 0) {
            payload.forEach(element => {
                if (element.Fecha == new Date().toISOString().substring(0, 10)) {
                    state.cierreCajaHoy = element
                }
            });
            payload.sort(function (a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(b.Fecha) - new Date(a.Fecha);
            });
            state.cierreDeCajaUltimo = payload[0]
        }
        state.cierresCaja = payload
    },
    addPlanCuentas(state, payload) {
        state.planCuentas = payload
    },
    addSuccessCreateCarrito(state, payload) {
        state.successCreateCarrito = payload
        if (payload) {
            setTimeout(() => { state.successCreateCarrito = false }, 2000)
        }
    },
    addSuccessCreateReposicionCompras(state, payload) {
        state.successCreateReposicionCompras = payload
        if (payload) {
            setTimeout(() => { state.successCreateReposicionCompras = false }, 2000)
        }
    },
    addSuccessCreateOc(state, payload) {
        state.successCreateOc = payload
        if (payload) {
            setTimeout(() => { state.successCreateOc = false }, 2000)
        }
    },
    addSuccessCreateCarritoBorrador(state, payload) {
        state.successCreateCarritoBorrador = payload
        if (payload) {
            setTimeout(() => { state.successCreateCarritoBorrador = false }, 2000)
        }
    },
    addSuccessCreateTomaInventario(state, payload) {
        state.successCreateTomaInventario = payload
        if (payload) {
            setTimeout(() => { state.successCreateTomaInventario = false }, 2000)
        }
    },
    addActiveLoadingSearchOc(state, payload) {
        state.activeLoadingSearchOc = payload

    },
    addSuccessCreateCierreCaja(state, payload) {
        state.successCreateCierreCaja = payload
        if (payload) {
            setTimeout(() => { state.successCreateCierreCaja = false }, 2000)
        }
    },
    addSuccessCreateMovimientoCaja(state, payload) {
        state.successCreateMovimientoCaja = payload
        if (payload) {
            setTimeout(() => { state.successCreateMovimientoCaja = false }, 2000)
        }
    },
    addSuccessCancelarCarrito(state, payload) {
        state.successCancelarCarrito = payload
        if (payload) {
            setTimeout(() => { state.successCancelarCarrito = false }, 2000)
        }
    },
}
