import axios from "axios";
import { generateUUID } from "../../../config/utility";
import { storage } from "../../../config/firebase";
import {
  uploadBytes,
  deleteObject,
  ref,
  list,
  listAll,
  getDownloadURL,
  getStream,
  getBytes,
} from "firebase/storage";
function setDateNow() {
  var currentDateObj = new Date();
  var numberOfMlSeconds = currentDateObj.getTime();
  var addMlSeconds = 60 * 180000;
  var newDateObj = new Date(numberOfMlSeconds - addMlSeconds);
  return newDateObj;
}
async function fileInput(file, token) {
  try {
    if (file && file.name) {
      let id = generateUUID();
      // console.log(id)
      let myFile = file;
      const fr = new FileReader();
      let fileURL = "";
      var file_ext = file.name.substr(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );
      let path = id + "." + file_ext;
      fr.readAsDataURL(file);
      fr.addEventListener("load", () => {
        // this is to load image on the UI
        // .. not related to file upload :)
        fileURL = fr.result;
      });

      const imgData = new FormData();
      const filePath = `products/${path}`;
      const metadata = { contentType: myFile.type };
      let refImages = ref(storage, `/logo/${token}/${path}`);
      uploadBytes(refImages, myFile)
        .then((snapshot) => {
          // console.log('Uploaded a blob or file!');
        })
        .then((resolve) => {})
        .catch((error) => {
          // console.log('Error', 'Error al subir la imagen😭')
          // console.log(error)
        });

      return {
        id: id,
        src: uri,
      };
    }
  } catch (e) {
  } finally {
  }
}
async function fileInputDoctor(file, token, rutdoctor) {
  try {
    if (file && file.name) {
      let id = generateUUID();
      // console.log(id)
      let myFile = file;
      const fr = new FileReader();
      let fileURL = "";
      var file_ext = file.name.substr(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );
      let path = id + "." + file_ext;
      fr.readAsDataURL(file);
      fr.addEventListener("load", () => {
        // this is to load image on the UI
        // .. not related to file upload :)
        fileURL = fr.result;
      });

      let refImages = ref(storage, `/imgprofile/${path}`);
      uploadBytes(refImages, myFile)
        .then((snapshot) => {
          // console.log('Uploaded a blob or file!');
        })
        .then((resolve) => {})
        .catch((error) => {
          // console.log('Error', 'Error al subir la imagen😭')
          // console.log(error)
        });

      return path;
    }
  } catch (e) {
  } finally {
  }
}
async function fileInputFileStorage(file) {
  try {
    if (file && file.name) {
      let fileSet = {
        url: "",
        name: "",
      };
      let id = generateUUID();
      // console.log(id)
      let myFile = file;
      const fr = new FileReader();
      let fileURL = "";
      var file_ext = file.name.substr(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );

      let path = id + "." + file_ext;
      fileSet.name = file.name;
      fileSet.url =
        "https://storage.googleapis.com/clivenapp-3909d.appspot.com/emails/attachment/" +
        path;

      fr.readAsDataURL(file);
      fr.addEventListener("load", () => {
        // this is to load image on the UI
        // .. not related to file upload :)
        fileURL = fr.result;
      });
      let refImages = ref(storage, `/emails/attachment/${path}`);
      await uploadBytes(refImages, myFile)
        .then((snapshot) => {
          // console.log('Uploaded a blob or file!');
        })
        .then((resolve) => {})
        .catch((error) => {
          // console.log('Error', 'Error al subir la imagen😭')
          // console.log(error)
        });

      return fileSet;
    }
  } catch (e) {
  } finally {
  }
}
async function fileInputImages(file, fichan, date, token) {
  try {
    if (file && file.name) {
      let id = generateUUID();
      // console.log(id)
      let myFile = file;
      const fr = new FileReader();
      let fileURL = "";
      var file_ext = file.name.substr(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );
      let path = file.name + id + "." + file_ext;
      fr.readAsDataURL(file);
      fr.addEventListener("load", () => {
        // this is to load image on the UI
        // .. not related to file upload :)
        fileURL = fr.result;
      });
      let refImages = ref(
        storage,
        `/files/${token}/${fichan}/${date}/imagenes/${path}`
      );
      await uploadBytes(refImages, myFile)
        .then((snapshot) => {
          // console.log('Uploaded a blob or file!');
        })
        .then((resolve) => {})
        .catch((error) => {
          // console.log('Error', 'Error al subir la imagen😭')
          // console.log(error)
        });

      return {
        id: id,
      };
    }
  } catch (e) {
  } finally {
  }
}
async function fileInputVideos(file, fichan, date, token) {
  try {
    if (file && file.name) {
      let id = generateUUID();
      // console.log(id)
      let myFile = file;
      const fr = new FileReader();
      let fileURL = "";
      var file_ext = file.name.substr(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );
      let path = file.name + id + "." + file_ext;
      fr.readAsDataURL(file);
      fr.addEventListener("load", () => {
        // this is to load image on the UI
        // .. not related to file upload :)
        fileURL = fr.result;
      });
      let refImages = ref(
        storage,
        `/files/${token}/${fichan}/${date}/videos/${path}`
      );
      await uploadBytes(refImages, myFile)
        .then((snapshot) => {
          // console.log('Uploaded a blob or file!');
        })
        .then((resolve) => {})
        .catch((error) => {
          // console.log('Error', 'Error al subir la imagen😭')
          // console.log(error)
        });

      return {
        id: id,
      };
    }
  } catch (e) {
  } finally {
  }
}
async function fileInputExamenes(file, fichan, date, token) {
  try {
    if (file && file.name) {
      let id = generateUUID();
      // console.log(id)
      let myFile = file;
      const fr = new FileReader();
      let fileURL = "";
      var file_ext = file.name.substr(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );
      let path = file.name + id + "." + file_ext;
      fr.readAsDataURL(file);
      fr.addEventListener("load", () => {
        // this is to load image on the UI
        // .. not related to file upload :)
        fileURL = fr.result;
      });
      let refImages = ref(
        storage,
        `/files/${token}/${fichan}/${date}/examenes/${path}`
      );
      await uploadBytes(refImages, myFile)
        .then((snapshot) => {
          // console.log('Uploaded a blob or file!');
        })
        .then((resolve) => {})
        .catch((error) => {
          // console.log('Error', 'Error al subir la imagen😭')
          // console.log(error)
        });

      return {
        id: id,
      };
    }
  } catch (e) {
  } finally {
  }
}
async function deleteFileByPath(path) {
  try {
    // Create a reference to the file to delete
    const desertRef = ref(storage, path);

    // Delete the file
    deleteObject(desertRef)
      .then(() => {
        // File deleted successfully
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
      });
  } catch (error) {}
}

async function readDirectoryByStorage(pathref) {
  const listRef = ref(storage, pathref);
  let list = [];
  // Find all the prefixes and items.
  await listAll(listRef)
    .then((res) => {
      res.prefixes.forEach((folderRef) => {
        // All the prefixes under listRef.
        // You may call listAll() recursively on them.

        list.push({ dir: folderRef.name, subdir: [] });
        // console.log(folderRef.name)
      });
    })
    .catch((error) => {
      //console.log("error", error)
    });
  return list;
}
async function readFilesDirectoryByStorage(pathref) {
  const pathcopy = pathref;
  const listRef = ref(storage, pathref);
  let list = [];
  // Find all the prefixes and items.
  await listAll(listRef).then((res) => {
    res.items.forEach((itemRef) => {
      var name = itemRef.name.toString();
      let file_ext = name.substr(name.lastIndexOf(".") + 1, name.length);
      // All the items under listRef.
      list.push({
        file: file_ext,
        name: itemRef.name,
        path: pathcopy + "/" + itemRef.name,
      });
    });
  });
  return list;
}
async function downloadFileStorage(pathref) {
  // let url = await getDownloadURL(ref(storage, pathref))
  // let metada = await getBytes(ref(storage, pathref))
  // console.log("metada", metada)
  let url = `https://storage.googleapis.com/clivenapp-3909d.appspot.com${pathref}`;
  let options = {
    method: "GET",
    headers: new Headers({
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    }),
    mode: "cors",
    cache: "default",
  };
  let strMimeType;
  let strFileName;

  //Perform a GET Request to server
  fetch(url, options)
    .then(function (response) {
      let contentType = response.headers.get("Content-Type"); //Get File name from content type
      strMimeType = response.headers.get("content-type").split(";")[0];
      strFileName = contentType.split(";")[1];
      // console.log(strFileName)
      return response.blob();
    })
    .then(function (myBlob) {
      let uri = URL.createObjectURL(new Blob([myBlob], { type: strMimeType }));
    })
    .catch((e) => {
      // console.log("e", e);
    });
}
export default {
  postMedicalCareVisit({ commit }, medicalCareVisit) {
    commit("addMedicalCareVisits", medicalCareVisit);
  },
  postMedicalRecord({ commit }, medicalRecord) {
    commit("addMedicalRecord", medicalRecord);
  },
  // métodos de atención día
  async getAtenciondias({ commit }, data) {
    commit("setActiveLoadingSearchAtenciondias", true);
    let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
    let query = `https://service.clivenapp.cl/atenciondia?token=${clinicaUser.Token}&fecha=${data.fecha}&sucursal=${data.sucursal}`;
    let headers = {
      "Content-type": "application/json",
    };
    await axios
      .get(query, { headers: headers })
      .then((result) => {
        commit("addAtenciondias", result.data);
      })
      .catch((error) => {
        // console.log(error)
      });
    commit("setActiveLoadingSearchAtenciondias", false);
  },

  //atenciondia
  // crear una atencion día
  async postAtenciondia({ commit, dispatch }, data) {
    let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `https://service.clivenapp.cl/atenciondia?token=${clinicaUser.Token}`;
    let headers = {
      "content-type": "application/json",
    };
    await axios
      .post(query, data.atenciondia, { headers: headers })
      .then(async (result) => {
        if (result.status == 201) {
          dispatch("getAtenciondias", {
            fecha: setDateNow().toISOString().substring(0, 10),
            sucursal: user.Sucursal,
          });
          commit("addSuccessCreateAtenciondia", true);
        }
      });
  },
  async postUpdateAtenciondia({ commit, dispatch }, data) {
    let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
    let user = JSON.parse(localStorage.getItem("user"));
    let query = `https://service.clivenapp.cl/atenciondia/id/${data.id}?token=${clinicaUser.Token}`;
    let headers = {
      "content-type": "application/json",
    };
    await axios
      .post(query, data.atenciondia, { headers: headers })
      .then(async (result) => {
        if (result.status == 201) {
          dispatch("getAtenciondias", {
            fecha: setDateNow().toISOString().substring(0, 10),
            sucursal: user.Sucursal,
          });
        }
      });
  },
  async deleteAtenciondiaById({ commit, dispatch }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let user = JSON.parse(localStorage.getItem("user"));
      let query = `https://service.clivenapp.cl/atenciondia/${data.id}?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios.delete(query, { headers: headers }).then((result) => {
        if (result.status == 200) {
          commit("addSuccessDeleteAtenciondiaById", true);
          dispatch("getAtenciondias", {
            fecha: setDateNow().toISOString().substring(0, 10),
            sucursal: user.Sucursal,
          });
        }
      });
    } catch (error) {
      throw new Error("no se pudo traer el horario del personal");
    }
  },

  //metodo para obtener directorio de archivos
  async getDirAllFiles({ commit }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let headers = {
        "content-type": "application/json",
      };
      let query = `https://service.clivenapp.cl/dir/all?fichan=${data.Fichan}&token=${clinicaUser.Token}`;
      await axios.get(query, { headers: headers }).then((result) => {
        if (result.status == 200) {
          let setdirectories = [
            {
              name: data.Fichan,
              children: [],
            },
          ];
          result.data.forEach((element) => {
            let subdir = {
              name: element.name,
              children: [],
            };
            element.subdir.forEach((subelemen) => {
              let subdirfile = {
                name: "",
                children: [],
              };
              subdirfile.name = subelemen.name;
              subdirfile.children = subelemen.children;
              subdir.children.push(subdirfile);
            });
            setdirectories[0].children.push(subdir);
          });
          commit("addDirectories", setdirectories);
        }
      });
    } catch (error) {
      throw new Error("no se puede obtener los directoris");
    }
  },

  // métodos de get doctors
  async getDoctors({ commit }) {
    let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
    let query = `https://service.clivenapp.cl/doctors?token=${clinicaUser.Token}`;
    let headers = {
      "content-type": "application/json",
    };
    await axios.get(query, { headers: headers }).then((result) => {
      if (result.status == 200) {
        commit("addDoctors", result.data);
      }
    });
  },
  actionSelectDoctor({ commit }, data) {
    commit("addDoctor", data);
  },

  //obtener los motivos de visita
  async getMotivovisitas({ commit }) {
    let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
    let query = `https://service.clivenapp.cl/motivovisita?token=${clinicaUser.Token}`;
    let headers = {
      "content-type": "application/json",
    };
    await axios.get(query, { headers: headers }).then((result) => {
      if (result.status == 200) {
        commit("addMotivovisitas", result.data);
      }
    });
  },

  //diagnostico
  //obtener el diagnostico por fecha y ficha de animal
  async getDiagnosticoByDateFichan({ commit }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/diagnostico/${data.fichan}?fecha=${data.fecha}&token=${clinicaUser.Token}`;
      // console.log(query)
      let headers = {
        "content-type": "application/json",
      };
      await axios.get(query, { headers: headers }).then((result) => {
        if ((result.status = 200)) {
          commit("addDiagnostico", result.data);
        }
      });
    } catch (error) {
      throw new Error("no se puede obtener el diagnostico");
    }
  },
  async getDiagnosticosByFichan({ commit }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/diagnostico/fichan/${data.fichan}?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios.get(query, { headers: headers }).then((result) => {
        return result.data;
      });
    } catch (error) {
      throw new Error(error);
    }
  },
  //actualizar el diagnostico por id
  async putDiagnosticoById({ commit, dispatch }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/diagnostico/update/${data.id}?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios
        .post(query, data.diagnostico, { headers: headers })
        .then((result) => {
          if ((result.status = 201)) {
            commit("addSuccessCreateDiagnostico", true);
            dispatch("postUpdateAtenciondia", {
              id: data.id_atenciondia,
              atenciondia: data.atenciondia,
            });
          }
        });
    } catch (error) {
      // console.log(error)
      throw new Error("no se pudo actualizar el diagnostico");
    }
  },

  //obtener checklist por fecha y ficha de animal
  async getChecklistByDateFichan({ commit }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/checklist/${data.fichan}?fecha=${data.fecha}&token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios.get(query, { headers: headers }).then((result) => {
        if ((result.status = 200)) {
          result.data[0].Fechare = result.data[0].Fechare.substring(0, 10);
          commit("addChecklist", result.data[0]);
        }
      });
    } catch (error) {
      throw new Error("no se puede obtener el checklist de la atención");
    }
  },

  //actualizar el checklist por id
  async putChecklistById({ commit }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/checklist/update/${data.id}?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios
        .post(query, data.checklist, { headers: headers })
        .then((result) => {
          if ((result.status = 201)) {
            commit("addSuccessCreateChecklist", true);
          }
        });
    } catch (error) {
      throw new Error("no se pudo actualizar el diagnostico");
    }
  },
  //obtener fames
  async getFames({ commit }) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/fames?token=${clinicaUser.Token}`;
      await axios
        .get(query)
        .then((result) => {
          commit("addFames", result.data);
        })
        .catch((error) => {
          // console.log(error)
        });
    } catch (error) {
      throw new Error("no se pudo obtener los registros de fames");
    }
  },
  //obtener especialidades
  async getEspecialidad({ commit }) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/especialidad?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios.get(query, { headers: headers }).then((result) => {
        if (result.status == 200) {
          commit("addEspecialidades", result.data);
        }
      });
    } catch (error) {
      throw new Error("no se pudo obtener las especialidades");
    }
  },
  //crear especiaidad
  async postEspecialidad({ commit, dispatch }, data) {
    let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
    let query = `https://service.clivenapp.cl/especialidad?token=${clinicaUser.Token}`;
    let headers = {
      "content-type": "application/json",
    };
    // console.log(data)
    await axios
      .post(query, data.especialidad, { headers: headers })
      .then((result) => {
        if (result.status == 201) {
          commit("addSuccessCreateEspecialidad", true);
          dispatch("getEspecialidad");
        }
      });
  },
  async postDoctor({ commit, dispatch }, data) {
    let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
    let query = "";
    if (data.update) {
      query = `https://service.clivenapp.cl/doctors?token=${clinicaUser.Token}&update=true`;
    } else {
      query = `https://service.clivenapp.cl/doctors?token=${clinicaUser.Token}`;
    }

    let headers = {
      "content-type": "application/json",
    };
    await axios
      .post(query, data.doctor, { headers: headers })
      .then((result) => {
        if (result.status == 201) {
          commit("addSuccessCreateDoctor", true);
          dispatch("getDoctors");
        }
        if (data.imgsrc != "") {
          dispatch("postImgDoctorStorage", {
            rutdoc: data.doctor.Rut,
            imgsrc: data.imgsrc,
          });
        }
      });
  },
  async getProdusByNameOrCode({ commit }, data) {
    try {
      commit("activeLoadingCargarProdus", true);
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/produ?token=${clinicaUser.Token}&search=${data.search}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios.get(query, { headers: headers }).then((result) => {
        if (result.status == 200) {
          commit("addProdus", result.data);
          commit("activeLoadingCargarProdus", false);
        }
      });
    } catch (error) {
      throw new Error("no se pudo obtener los productos o servicios");
    }
  },
  async getProdusByFamilia({ commit }, data) {
    try {
      commit("activeLoadingCargarProdus", true);
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/produ/familia?token=${clinicaUser.Token}&name=${data.search}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios.get(query, { headers: headers }).then((result) => {
        if (result.status == 200) {
          commit("addProdus", result.data);
          commit("activeLoadingCargarProdus", false);
        }
      });
    } catch (error) {
      throw new Error("no se pudo obtener los productos o servicios");
    }
  },
  async postProdu({ commit, dispatch }, data) {
    try {
      // console.log(data)
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/produ?token=${clinicaUser.Token}&tipo=${data.tipo}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios
        .post(query, data.produ, { headers: headers })
        .then((result) => {
          if (result.status == 201) {
            commit("addSuccessCreateProdu", true);
            dispatch("getProdus");
          }
        });
    } catch (error) {
      throw new Error("no se pudo crear el nuevo produ");
    }
  },
  async getFamilias({ commit }) {
    try {
      commit("activeLoadingCargarFamilias", true);
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/produ/familias?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios.get(query, { headers: headers }).then((result) => {
        if (result.status == 200) {
          commit("addFamilias", result.data);
          commit("activeLoadingCargarFamilias", false);
        }
      });
    } catch (error) {
      throw new Error("no se pudo obtener los productos o servicios");
    }
  },
  async getServisHistory({ commit }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/servis/ficha/${data.fichan}?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios.get(query, { headers: headers }).then((result) => {
        if (result.status == 200) {
          commit("addServisHistory", result.data);
        }
      });
    } catch (error) {
      throw new Error("no se pudo obtener el historial de servicios");
    }
  },
  async getServisByDate({ commit }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/servis/date?token=${
        clinicaUser.Token
      }&fecha=${new Date().toISOString().substring(0, 10)}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios.get(query, { headers: headers }).then((result) => {
        if (result.status == 200) {
          commit("addServisByDate", result.data);
        }
      });
    } catch (error) {
      throw new Error("no se pudo obtener el historial de servicios");
    }
  },
  async getAtenciondiasByCliente({ commit, dispatch }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/atenciondia/ficha/${data.fichan}?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios.get(query, { headers: headers }).then(async (result) => {
        if (result.status == 200) {
          let queryDiagnosticos = `https://service.clivenapp.cl/diagnostico/fichan/${data.fichan}?token=${clinicaUser.Token}`;
          let headersDiagnosticos = {
            "content-type": "application/json",
          };
          let diagnosticos = [];
          await axios
            .get(queryDiagnosticos, { headers: headersDiagnosticos })
            .then((result) => {
              diagnosticos = result.data;
            });

          for (let diagnostico in result.data) {
            result.data[diagnostico].Diagnostico = {
              Fichan: 0,
              Nombre: "",
              Fecha: "",
              Temperatura: "",
              Peso: "",
              Frecar: "",
              Freres: "",
              Anamnecis: "",
              Inspeccion: "",
              Palpacion: "",
              Auscultacion: "",
              Otros: "",
              Prediagnostico: "",
              Diagnostico: "",
              Categoria: "",
              Tratamiento: "",
              Diagnosticofamilia: "",
              Id: 0,
            };
          }

          diagnosticos.forEach((item) => {
            let index = result.data.findIndex(
              (atenciondia) => atenciondia.Fecha == item.Fecha.substring(0, 10)
            );
            // console.log(index)
            if (index != -1) {
              result.data[index].Diagnostico = item;
            }
          });

          commit("addAtenciondiasByCliente", result.data);
        }
      });
    } catch (error) {
      // console.log(error)
      throw new Error("no se pudo obtener el historial de atenciones");
    }
  },
  async getAtenciondiasById({ commit }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/atenciondia/id/${data.id}?token=${clinicaUser.Token}`;
      // console.log(query)
      let headers = {
        "content-type": "application/json",
      };
      await axios.get(query, { headers: headers }).then((result) => {
        if (result.status == 200) {
          commit("addAtencionciaById", result.data);
        }
      });
    } catch (error) {
      throw new Error("no se pudo obtener el historial de servicios");
    }
  },
  async postServis({ commit, dispatch }, data) {
    try {
      // console.log(data)
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/servis?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios
        .post(query, data.servi, { headers: headers })
        .then((result) => {
          if (result.status == 201) {
            dispatch("getServisHistory", { fichan: data.servi.Ficha });
            commit("addSuccessCreateService", true);
          }
        });
    } catch (error) {
      throw new Error("no se pudo crear el nuevo servicio");
    }
  },
  async postUpdateServisEntrega({ commit, dispatch }, data) {
    try {
      // console.log(data)
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/servis/entrega?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios
        .post(query, data.servi, { headers: headers })
        .then((result) => {
          if (result.status == 201) {
            dispatch("getServisHistory", { fichan: data.servi.Ficha });
            commit("addActiveCreateServisEntregado", true);
            //console.log("swervis con existo")
          }
        });
    } catch (error) {
      throw new Error("no se pudo crear el nuevo servicio");
    }
  },
  async getChecklistsByFicha({ commit }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/checklist/${data.ficha}?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios.get(query, { headers: headers }).then((result) => {
        if (result.status == 200) {
          commit("addChecklists", result.data);
        }
      });
    } catch (error) {
      throw new Error("no se pudo traer la lista de checklists por ficha");
    }
  },
  async postUpdateChecklist({ commit }, data) {
    try {
    } catch (error) {
      throw new Error("no se pudo grabar");
    }
  },
  async postUploadImage({ commit }, data) {
    try {
      var bodyFormData = new FormData();
      bodyFormData.append("file", data.image);
      let query = `https://service.clivenapp.cl/upload-image`;
      let headers = {
        "Content-type": "multipart/form-data",
      };
      await axios.post(query, bodyFormData, { headers: headers });
    } catch (error) {
      // console.log(error)
      throw new Error("no se pudo cargar la imagen");
    }
  },
  async getAces({ commit }) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/ace?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios.get(query, { headers: headers }).then((result) => {
        if (result.status == 200) {
          commit("addAces", result.data);
        }
      });
    } catch (error) {
      throw new Error("no se puede traer los clientes");
    }
  },

  //busqueda de edocument
  async getDocumentById({ commit }, id) {
    try {
      let query = `https://service.clivenapp.cl/edocument/${id}`;
      let headers = {
        "content-type": "application/json",
      };
      var edocumento = "";
      await axios.get(query, { headers: headers }).then((result) => {
        if (result.status == 200) {
          edocumento = result.data;
        }
      });
      return edocumento;
    } catch (error) {
      throw new Error("no se puede el documento");
    }
  },
  async postEdocument({ commit }, data) {
    try {
      let query = `https://service.clivenapp.cl/edocument`;
      let headers = {
        "content-type": "application/json",
      };

      await axios
        .post(query, data.edocument, { headers: headers })
        .then((result) => {
          if (result.status == 201) {
            //console.log("creado con exito")
          }
        });
    } catch (error) {
      throw new Error("no se puede crear el documento");
    }
  },

  //gestion de recetas
  //obtener todas las recetas por n de ficha
  async getRecetas({ commit }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/receta/fichan/${data.fichan}?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios.get(query, { headers: headers }).then((result) => {
        if (result.status == 200) {
          commit("addRecetas", result.data);
        }
      });
    } catch (error) {
      throw new Error(error);
    }
  },
  //crear una nueva receta
  async postReceta({ commit, dispatch }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/receta?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios
        .post(query, data.receta, { headers: headers })
        .then((result) => {
          if (result.status == 201) {
            commit("addSuccessCreateReceta", true);
            dispatch("getRecetas", { fichan: data.receta.Ficha });
            dispatch("postEdocument", { edocument: data.edocument });
          }
        });
    } catch (error) {
      throw new Error(error);
    }
  },
  selectReceta({ commit }, data) {
    commit("addReceta", data.receta);
  },

  //horariopersonal
  //obtener horario personal
  async getHorariospersonalByrut({ commit }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/horariopersonal/${data.rut}?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios.get(query, { headers: headers }).then((result) => {
        if (result.status == 200) {
          commit("addHorariospersonalByDoctor", result.data);
          if (result.data.length > 0) {
            commit("addFindSuccessHorarios", true);
          } else {
            commit("addFindSuccessHorarios", false);
          }
        }
      });
    } catch (error) {
      throw new Error("no se pudo traer el horario del personal");
    }
  },
  async postHorariopersonal({ commit, dispatch }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/horariopersonal?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios
        .post(query, data.horariopersonal, { headers: headers })
        .then((result) => {
          if (result.status == 201) {
            commit("addSuccessCreateHorariopersonal", true);
            dispatch("getHorariospersonalByrut", {
              rut: data.horariopersonal.Rut,
            });
          }
        });
    } catch (error) {
      throw new Error("no es posible crear el horario");
    }
  },
  async deleteHorariopersonal({ commit, dispatch }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/horariopersonal/${data.rut}?var1=${data.var1}&token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios.delete(query, { headers: headers }).then((result) => {
        if (result.status == 200) {
          commit("addSuccessDeleteHorariopersonal", true);
          dispatch("getHorariospersonalByrut", { rut: data.rut });
        }
      });
    } catch (error) {
      throw new Error("no se pudo traer el horario del personal");
    }
  },
  //programacion
  //grabar programacion
  async postProgramacion({ commit, dispatch }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/programacion?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios
        .post(query, data.programaciones, { headers: headers })
        .then((result) => {
          if (result.status == 201) {
            commit("addSuccessCreateProgramacion", true);
            dispatch("getProgramacionesByrut", { rut: data.rut });
          }
        });
    } catch (error) {
      throw new Error(error);
    }
  },
  async getProgramacionesByrut({ commit }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/programacion/${data.rut}?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios.get(query, { headers: headers }).then((result) => {
        if (result.status == 200) {
          commit("addProgramaciones", result.data);
        }
      });
    } catch (error) {
      throw new Error("no se pudo traer el horario del personal");
    }
  },
  async deleteProgramacionById({ commit, dispatch }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/programacion/${data.id}?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios.delete(query, { headers: headers }).then((result) => {
        if (result.status == 200) {
          commit("addSuccessDeleteProgramacionById", true);
          dispatch("getProgramacionesByrut", { rut: data.rut });
        }
      });
    } catch (error) {
      throw new Error("no se pudo traer el horario del personal");
    }
  },
  async deleteProgramacionByRutDateRange({ commit, dispatch }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/programacion/${data.rut}/date?desde=${data.desde}&hasta=${data.hasta}&token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios.delete(query, { headers: headers }).then((result) => {
        if (result.status == 200) {
          commit("addSuccessDeleteProgramacionById", true);
          dispatch("getProgramacionesByrut", { rut: data.rut });
        }
      });
    } catch (error) {
      throw new Error("no se pudo traer el horario del personal");
    }
  },

  //memos
  //obtener memo
  async getMemos({ commit }) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/memos?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios.get(query, { headers: headers }).then((result) => {
        if (result.status == 200) {
          commit("addMemos", result.data);
        }
      });
    } catch (error) {
      throw new Error("no se pudo traer el horario del personal");
    }
  },

  //email
  async postSentEmail({ commit, dispatch }, email) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/email/attachment?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      // console.log(email)
      await axios
        .post(query, email, { headers: headers })
        .then((result) => {
          if (result.status == 200) {
            commit("addSuccessSentEmail", true);
          }
        })
        .catch((error) => {
          error;
        });
    } catch (error) {
      throw new Error(error);
    }
  },

  //hospital
  async getHospitalizados({ commit }, data) {
    try {
      commit("setSctiveLoadingSearchHospitalizados", true);
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/hospitalizados?token=${clinicaUser.Token}&sucursal=${data.sucursal}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios.get(query, { headers: headers }).then(async (result) => {
        if (result.status == 200) {
          let set_hospitalizados = [];
          for (let hospitalizado of result.data) {
            // console.log(hospitalizado)
            let queryCliente = `https://service.clivenapp.cl/clientes/fichan/${hospitalizado.Ficha}?token=${clinicaUser.Token}`;
            await axios.get(queryCliente).then((resultCliente) => {
              if (resultCliente.status == 200) {
                set_hospitalizados.push(resultCliente.data);
              } else {
                set_hospitalizados.push({
                  Fichan: 0,
                  Nombre: "",
                  Amo: "",
                  Fono: "",
                  Nacimiento: "",
                  Raza: "",
                  Direccion: "",
                  Comuna: "",
                  Ciudad: "",
                  Email: "",
                  Sexo: "",
                  Fotoactiva: "",
                  Madre: "",
                  Padre: "",
                  Peso: "",
                  Altura: "",
                  Edad: "",
                  Reg: "",
                  Color: "",
                  Esp: "",
                  Insc: "",
                  Microchip: "",
                  Fichaant: "",
                  Clinica: "",
                  Drtratante: "",
                  Peluqueria: "",
                  Rut: "",
                  Id: 0,
                });
              }
            });
          }

          commit("addHospitalizados", set_hospitalizados);
        }
      });
      commit("setSctiveLoadingSearchHospitalizados", false);
    } catch (error) {
      // console.log(error)
      throw new Error("no se pudo traer los hospitalizado");
    }
  },
  async getControlesHospitalizados({ commit }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/hospitalizados/${data.ficha}?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios.get(query, { headers: headers }).then((result) => {
        if (result.status == 200) {
          commit("addControlesHospitalizados", result.data);
        }
      });
    } catch (error) {
      throw new Error("no se pudo traer el horario del personal");
    }
  },
  async postControlHospitalizadoByFicha({ commit, dispatch }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/hospitalizados?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios
        .post(query, data.controlHospitalizado, { headers: headers })
        .then((result) => {
          if (result.status == 201) {
            commit("addSuccessCreateControlHospitalizado", true);
            dispatch("getControlesHospitalizados", {
              ficha: data.controlHospitalizado.Ficha,
            });
            dispatch("getHospitalizados", { sucursal: data.sucursal });
          }
        });
    } catch (error) {
      throw new Error("no se pudo crear el el control de hospitalizado");
    }
  },
  async postUpdateControlHospitalizadoByFicha({ commit, dispatch }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/hospitalizados/update?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios
        .post(query, data.controlHospitalizado, { headers: headers })
        .then((result) => {
          if (result.status == 201) {
            commit("addSuccessUpdateControlHospitalizado", true);
          }
        });
    } catch (error) {
      throw new Error("no se pudo crear el el control de hospitalizado");
    }
  },
  async setControlHospitalizadoByFicha({ commit }, data) {
    commit("addSetControlHospitalizado", data.controlHospitalizado);
  },
  actionSelectFichaCuentaHospital({ commit }, selectFichaCuentaHospital) {
    commit("setSelectFichaCuentaHospital", selectFichaCuentaHospital);
  },
  async postDarAltaHospitalizadoByFicha({ commit, dispatch }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/hospitalizados/alta/${data.ficha}?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      // console.log(query)
      await axios
        .post(query, data.controlHospitalizado, { headers: headers })
        .then((result) => {
          if (result.status == 201) {
            commit("addSuccessCreateAltaHospitalizado", true);
            dispatch("getHospitalizados", { sucursal: data.sucursal });
          }
        });
    } catch (error) {
      throw new Error("no se pudo crear el el control de hospitalizado");
    }
  },
  //procedimientos
  async getProcedimientosByFicha({ commit }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/procedimientos/ficha/${data.ficha}?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios.get(query, { headers: headers }).then((result) => {
        if (result.status == 200) {
          commit("addProcedimientos", result.data);
        }
      });
    } catch (error) {
      throw new Error("no se pudo traer el horario del personal");
    }
  },
  async postProcedimientoByFicha({ commit, dispatch }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/procedimientos/ficha/${data.procedimiento.Ficha}?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios
        .post(query, data.procedimiento, { headers: headers })
        .then((result) => {
          if (result.status == 201) {
            commit("addSuccessCreateProcedimiento", true);
            dispatch("getProcedimientosByFicha", {
              ficha: data.procedimiento.Ficha,
            });
          }
        });
    } catch (error) {
      throw new Error("no se pudo crear el procedimiento");
    }
  },

  //ventadepaso
  async getVentasdepaso({ commit }, data) {
    try {
      commit("setLoadingVentadepaso", true);
      //console.log("actualizando el paso")
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/ventasdepaso?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios.get(query, { headers: headers }).then((result) => {
        if (result.status == 200) {
          commit("addVentasdepaso", result.data);
          commit("setLoadingVentadepaso", false);
        }
      });
    } catch (error) {
      throw new Error("no se pudo traer el horario del personal");
    }
  },
  async postVentadepaso({ commit, dispatch }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/ventasdepaso?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios
        .post(query, data.ventadepaso, { headers: headers })
        .then((result) => {
          if (result.status == 201) {
            dispatch("getVentasdepaso", { process: "ventadepaso" });
            setTimeout(() => {
              commit("addSuccessCreateVentadepaso", true);
            }, 2000);
          }
        });
    } catch (error) {
      throw new Error("no se pudo crear la venta de paso");
    }
  },
  async postDeleteVentadepasoById({ commit, dispatch }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/ventasdepaso/${data.id}?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios.post(query, { headers: headers }).then((result) => {
        if (result.status == 201) {
          dispatch("getVentasdepaso", { process: "ventadepaso" });
          setTimeout(() => {
            commit("addSuccessDeleteVentadepaso", true);
          }, 2000);
        }
      });
    } catch (error) {
      throw new Error("no se pudo crear la venta de paso");
    }
  },
  async postVentadepasoByPack({ commit, dispatch }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/ventasdepaso/pack?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios
        .post(query, data.ventapack, { headers: headers })
        .then((result) => {
          if (result.status == 201) {
            dispatch("getVentasdepaso", { process: "ventadepaso" });
            setTimeout(() => {
              commit("addSuccessCreateVentadepaso", true);
            }, 2000);
          }
        });
    } catch (error) {
      throw new Error("no se pudo crear la venta de paso");
    }
  },

  //inventario
  async getInventarioByCodigo({ commit }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/inventario?cod=${data.codigo}&token=${clinicaUser.Token}&sucursal=${data.sucursal}`;
      let headers = {
        "content-type": "application/json",
      };
      let inventario = {};
      await axios.get(query, { headers: headers }).then((result) => {
        if (result.status == 200) {
          inventario = result.data;
        }
      });
      return inventario;
    } catch (error) {
      throw new Error("no se pudo traer el inventario por codigo");
    }
  },

  //storage
  async getDirectoryFilesByToken({ commit }, data) {
    let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
    let path = `/files/${clinicaUser.Token}/${data.fichan}`;
    let list = await readDirectoryByStorage(path);
    let setdirectories = [
      {
        name: data.fichan,
        children: [],
      },
    ];
    for (let dir of list) {
      // console.log("dir", dir.dir)
      let subdir_set = {
        name: dir.dir,
        children: [],
      };
      let subpath = `${path}/${dir.dir}`;
      let subdir = await readDirectoryByStorage(subpath);
      for (let itemref of subdir) {
        let pathfiles = subpath + "/" + itemref.dir;
        let subdirfile = {
          name: "",
          children: [],
        };
        subdirfile.name = itemref.dir;

        itemref.files = await readFilesDirectoryByStorage(pathfiles);
        subdirfile.children = itemref.files;
        subdir_set.children.push(subdirfile);
      }
      dir.subdir = subdir;
      setdirectories[0].children.push(subdir_set);
    }

    // result.data.forEach(element => {

    //   element.subdir.forEach(subelemen => {
    //     let subdirfile = {
    //       name: "",
    //       children: []
    //     }
    //     subdirfile.name = subelemen.name
    //     subdirfile.children = subelemen.children
    //     subdir.children.push(subdirfile)
    //   })

    // });
    // commit('addDirectories', setdirectories)

    commit("addDirectories", setdirectories);
    // Create a reference under which you want to list
  },
  async getLogoStorageByToken({ commit }) {
    let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
    let path = `/logo/${clinicaUser.Token}`;
    let list = await readFilesDirectoryByStorage(path);
    let logoref = list[0];

    let url = `https://storage.googleapis.com/clivenapp-3909d.appspot.com${logoref}`;
    // let img = await downloadFileStorage(logoref.path)
    // console.log(img)
    // Create a reference under which you want to list
  },
  async actionDeleteFileByPath({ commit, dispatch }, data) {
    try {
      commit("addSuccessDeleteFile", true);
      await deleteFileByPath(data.path);
      dispatch("getDirectoryFilesByToken", { fichan: data.fichan });
    } catch (error) {}
  },

  async postLogoStorage({ commit, dispatch }, data) {
    let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
    await fileInput(data.logosrc, clinicaUser.Token);
    setTimeout(() => {
      dispatch("auth-module/getLogoStorageByToken", null, { root: true });
    }, 2000);
  },
  async postImgDoctorStorage({ commit, dispatch }, data) {
    let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
    let path = await fileInputDoctor(
      data.imgsrc,
      clinicaUser.Token,
      data.rutdoc
    );
    let srcurl =
      "https://storage.googleapis.com/clivenapp-3909d.appspot.com/imgprofile/" +
      path;

    let query = `https://service.clivenapp.cl/doctors/img?token=${clinicaUser.Token}&rut=${data.rutdoc}`;
    let headers = {
      "content-type": "application/json",
    };
    let datasent = {
      src: srcurl,
    };
    await axios.post(query, datasent, { headers: headers }).then((result) => {
      if (result.status == 201) {
      }
    });
  },
  async postFilesEmailStorage({ commit, dispatch }, data) {
    commit("setLoadingUpload", true);
    let filesList = [];
    for (let item of data.imagenes) {
      let result = await fileInputFileStorage(item);
      filesList.push(result);
    }
    commit("setLoadingUpload", false);
    return filesList;
  },
  async postFilesImagenes({ commit, dispatch }, data) {
    commit("setLoadingUpload", true);
    let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
    for (let item of data.imagenes) {
      let result = await fileInputImages(
        item,
        data.fichan,
        data.date,
        clinicaUser.Token
      );
    }
    commit("setLoadingUpload", false);
    dispatch("getDirectoryFilesByToken", { fichan: data.fichan });
  },
  async postFilesExamenes({ commit, dispatch }, data) {
    commit("setLoadingUpload", true);
    let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
    for (let item of data.examenes) {
      let result = await fileInputExamenes(
        item,
        data.fichan,
        data.date,
        clinicaUser.Token
      );
    }
    commit("setLoadingUpload", false);
    dispatch("getDirectoryFilesByToken", { fichan: data.fichan });
  },
  async postFilesVideos({ commit, dispatch }, data) {
    commit("setLoadingUpload", true);
    let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
    for (let item of data.videos) {
      let result = await fileInputVideos(
        item,
        data.fichan,
        data.date,
        clinicaUser.Token
      );
    }
    commit("setLoadingUpload", false);
    dispatch("getDirectoryFilesByToken", { fichan: data.fichan });
  },
  //packcodigo
  async getPackcodigos({ commit }) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/packcodigo?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios.get(query, { headers: headers }).then((result) => {
        if (result.status == 200) {
          commit("addPackcodigos", result.data);
        }
      });
    } catch (error) {
      throw new Error("no se pudo traer packcodigos");
    }
  },
  async getPackByCodigo({ commit }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/pack?token=${clinicaUser.Token}&packcodigo=${data.packcodigo}`;
      let headers = {
        "content-type": "application/json",
      };
      let pack = [];

      await axios.get(query, { headers: headers }).then((result) => {
        if (result.status == 200) {
          pack = result.data;
        }
      });
      return pack;
    } catch (error) {
      throw new Error("no se pudo traer packcodigos");
    }
  },
  //agenda
  async getAgendas({ commit }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/agenda?token=${clinicaUser.Token}&fecha=${data.fecha}&doctor=${data.doctor}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios.get(query, { headers: headers }).then((result) => {
        if (result.status == 200) {
          for (let i of result.data) {
            i.Tiempo = i.Hora;
          }
          commit("addAgenda", result.data);
        }
      });
    } catch (error) {
      throw new Error("no se pudo traer la agenda");
    }
  },

  //import produ
  async postProduImportFile({ commit, dispatch }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let tipo = "";
      let query = `https://service.clivenapp.cl/produ/import?token=${clinicaUser.Token}&tipo=${tipo}`;
      let headers = {
        "content-type": "application/json",
      };
      commit("setLoadingImportProdu", true);
      if (data.produ.produActualizar.length > 0) {
        query = `https://service.clivenapp.cl/produ/import?token=${clinicaUser.Token}&tipo=actualiza`;
        await axios
          .post(query, data.produ.produActualizar, { headers: headers })
          .then((result) => {
            if (result.status == 201) {
              //console.log("se ha actualizado con exito")
            }
          });
      }
      if (data.produ.produEliminar.length > 0) {
        query = `https://service.clivenapp.cl/produ/import?token=${clinicaUser.Token}&tipo=elimina`;
        await axios
          .post(query, data.produ.produEliminar, { headers: headers })
          .then((result) => {
            if (result.status == 201) {
              //console.log("se ha eliminado con exito")
            }
          });
      }
      if (data.produ.produCambiarCod.length > 0) {
        query = `https://service.clivenapp.cl/produ/import?token=${clinicaUser.Token}&tipo=codigo`;
        await axios
          .post(query, data.produ.produCambiarCod, { headers: headers })
          .then((result) => {
            if (result.status == 201) {
              //console.log("se ha eliminado con exito")
            }
          });
      }
      if (data.produ.produIngresar.length > 0) {
        query = `https://service.clivenapp.cl/produ/import?token=${clinicaUser.Token}&tipo=ingresa`;
        await axios
          .post(query, data.produ.produIngresar, { headers: headers })
          .then((result) => {
            if (result.status == 201) {
              //console.log("se ha ingresado con exito")
            }
          });
      }
      await commit("setLoadingImportProdu", false);
    } catch (error) {
      throw new Error("no se pudo crear la venta de paso");
    }
  },
  async updateEspecialidad({ commit, dispatch }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/especialidad/${data.especialidad.id}?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios
        .post(query, data.especialidad, { headers: headers })
        .then((result) => {
          if (result.status == 201) {
            commit("setSuccessUpdateEspecialidad", true);
            dispatch("getEspecialidad", null);
          }
        });
    } catch (error) {
      throw new Error("no se pudo traer el horario del personal");
    }
  },
  async deleteEspecialidad({ commit, dispatch }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/especialidad/${data.id}?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios.delete(query, { headers: headers }).then((result) => {
        if (result.status == 201) {
          commit("setSuccessDeleteEspecialidad", true);
          dispatch("getEspecialidad", null);
        }
      });
    } catch (error) {
      throw new Error("no se pudo traer el horario del personal");
    }
  },
  async deleteProdu({ commit, dispatch }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/produ/delete?id=${data.id}&token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios.delete(query, { headers: headers }).then((result) => {
        if (result.status == 201) {
          commit("setSuccessDeleteProdu", true);
          commit("setDeleteProdu", data.id);
        }
      });
    } catch (error) {
      throw new Error("no se pudo traer el horario del personal");
    }
  },
};
