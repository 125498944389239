import { render, staticRenderFns } from "./GenerateDocTributarioPdf.vue?vue&type=template&id=2b477e05"
import script from "./GenerateDocTributarioPdf.vue?vue&type=script&lang=js"
export * from "./GenerateDocTributarioPdf.vue?vue&type=script&lang=js"
import style0 from "./GenerateDocTributarioPdf.vue?vue&type=style&index=0&id=2b477e05&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports