<template>
  <div>
    <div v-if="licen.Var16 == 1">
      <v-card min-height="97vh" style="overflow-y-auto" :class="isMobile ? 'pa-3 elevation-0' : 'ma-3 rounded-xl pa-3'">
        <!-- Contenedor del título principal (vista escritorio) -->
        <div class="my-5 d-none d-sm-none d-md-flex d-lg-flex d-xl-flex">
          <!-- btn menú de venta -->
          <div class="d-flex">
            <div class="mx-2">
              <v-btn v-if="!menuCentral" class="text-capitalize" color="primary" outlined @click="menuCentral = true">Menú
                de venta</v-btn>
            </div>
          </div>
          <v-spacer></v-spacer>
          <!-- Título principal -->
          <h1 class="text-center">Punto de venta</h1>
          <v-spacer></v-spacer>
          <!-- Btn volver al menú principal -->
          <div class="">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="primary" dark v-bind="attrs" v-on="on" @click="$router.go(-1)"><v-icon
                    class="mx-2">mdi-home</v-icon></v-btn>
              </template>
              <span>Volver al inicio</span>
            </v-tooltip>
          </div>
        </div>
        <!-- Contenedor del título principal (vista móvil) -->
        <div class="my-5 d-flex d-sm-flex d-md-none d-lg-none d-xl-none flex-column">
          <!-- btn menú de venta -->
          <div class="d-flex justify-space-between">
            <div class="mx-2">
              <v-btn v-if="!menuCentral" class="text-capitalize" color="primary" outlined @click="menuCentral = true">Menú
                de venta</v-btn>
            </div>
            <!-- Btn volver al menú principal -->
            <div class="">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="primary" dark v-bind="attrs" v-on="on" @click="$router.go(-1)"><v-icon
                      class="mx-2">mdi-home</v-icon></v-btn>
                </template>
                <span>Volver al inicio</span>
              </v-tooltip>
            </div>
          </div>
          <!-- Título principal -->
          <div class="d-flex justify-center mt-5">
            <h1>Punto de venta</h1>
          </div>
        </div>
        <!-- menu del punto -->
        <div v-if="menuCentral">
          <div>
            <v-item-group>
              <v-container>
                <!-- vender y carro-->
                <v-row>
                  <v-col cols="12" md="6">
                    <v-hover v-slot="{ hover }" open-delay="0">
                      <v-card elevation="2" :color="hover ? 'primary' : 'white'" outlined
                        :class="{ 'on-hover': hover, color: 'red' }" class="pa-5 rounded-xl"
                        @click="btnSelectOptionMenu('sale')">
                        <div class="d-flex align-center flex-column justify-center">
                          <div>
                            <v-icon :color="hover ? 'background' : 'primary'" size="48">mdi-cart-outline</v-icon>
                          </div>
                          <h3 :class="hover ? 'background--text' : 'black--text'">
                            Vender
                          </h3>
                        </div>
                      </v-card>
                    </v-hover>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-hover v-slot="{ hover }" open-delay="0">
                      <v-card elevation="2" :color="hover ? 'primary' : 'white'" outlined
                        :class="{ 'on-hover': hover, color: 'red' }" class="pa-5 rounded-xl"
                        @click="btnSelectOptionMenu('continuarVenta')">
                        <div class="d-flex align-center flex-column justify-center">
                          <div>
                            <v-icon :color="hover ? 'background' : 'primary'"
                              size="48">mdi-receipt-text-clock-outline</v-icon>
                          </div>
                          <h3 :class="hover ? 'background--text' : 'black--text'">
                            Continuar con venta
                          </h3>
                        </div>
                      </v-card>
                    </v-hover>
                  </v-col>
                </v-row>
                <!-- busqueda de producto y ver pagos -->
                <v-row>
                  <v-col cols="12" md="6">
                    <v-hover v-slot="{ hover }" open-delay="0">
                      <v-card elevation="2" :color="hover ? 'primary' : 'white'" outlined
                        :class="{ 'on-hover': hover, color: 'red' }" class="pa-5 rounded-xl"
                        @click="btnSelectOptionMenu('buscarProducto')">
                        <div class="d-flex align-center flex-column justify-center">
                          <div>
                            <v-icon :color="hover ? 'background' : 'primary'" size="48">mdi-tag-search-outline</v-icon>
                          </div>
                          <h3 :class="hover ? 'background--text' : 'black--text'">
                            Buscar producto
                          </h3>
                        </div>
                      </v-card>
                    </v-hover>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-hover v-slot="{ hover }" open-delay="0">
                      <v-card elevation="2" :color="hover ? 'primary' : 'white'" outlined
                        :class="{ 'on-hover': hover, color: 'red' }" class="pa-5 rounded-xl"
                        @click="btnSelectOptionMenu('pagos')">
                        <div class="d-flex align-center flex-column justify-center">
                          <div>
                            <v-icon :color="hover ? 'background' : 'primary'" size="48">mdi-cash</v-icon>
                          </div>
                          <h3 :class="hover ? 'background--text' : 'black--text'">
                            Ver pagos
                          </h3>
                        </div>
                      </v-card>
                    </v-hover>
                  </v-col>
                </v-row>
                <!-- ver ventas y ver caja -->
                <v-row>
                  <!-- ver ventas -->
                  <v-col cols="12" md="6">
                    <v-hover v-slot="{ hover }" open-delay="0">
                      <v-card elevation="2" :color="hover ? 'primary' : 'white'" outlined
                        :class="{ 'on-hover': hover, color: 'red' }" class="pa-5 rounded-xl"
                        @click="btnSelectOptionMenu('sales')">
                        <div class="d-flex align-center flex-column justify-center">
                          <div>
                            <v-icon :color="hover ? 'background' : 'primary'" size="48">mdi-basket-check-outline</v-icon>
                          </div>
                          <h3 :class="hover ? 'background--text' : 'black--text'">
                            Ver ventas
                          </h3>
                        </div>
                      </v-card>
                    </v-hover>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-hover v-slot="{ hover }" open-delay="0">
                      <v-card elevation="2" :color="hover ? 'primary' : 'white'" outlined
                        :class="{ 'on-hover': hover, color: 'red' }" class="pa-5 rounded-xl"
                        @click="btnSelectOptionMenu('vercaja')">
                        <div class="d-flex align-center flex-column justify-center">
                          <div>
                            <v-icon :color="hover ? 'background' : 'primary'" size="48">mdi-point-of-sale</v-icon>
                          </div>
                          <h3 :class="hover ? 'background--text' : 'black--text'">
                            Ver caja
                          </h3>
                        </div>
                      </v-card>
                    </v-hover>
                  </v-col>
                </v-row>
                <!-- ver cta y generar guia -->
                <v-row>
                  <!-- ver cta cte -->
                  <v-col cols="12" md="6">
                    <v-hover v-slot="{ hover }" open-delay="0">
                      <v-card elevation="2" :color="hover ? 'primary' : 'white'" outlined
                        :class="{ 'on-hover': hover, color: 'red' }" class="pa-5 rounded-xl"
                        @click="btnSelectOptionMenu('ctacte')">
                        <div class="d-flex align-center flex-column justify-center">
                          <div>
                            <v-icon :color="hover ? 'background' : 'primary'" size="48">mdi-account-group-outline</v-icon>
                          </div>
                          <h3 :class="hover ? 'background--text' : 'black--text'">
                            Ver cta ctes
                          </h3>
                        </div>
                      </v-card>
                    </v-hover>
                  </v-col>
                  <!-- editar docs -->
                  <v-col cols="12" md="6">
                    <v-hover v-slot="{ hover }" open-delay="0">
                      <v-card elevation="2" :color="hover ? 'primary' : 'white'" outlined
                        :class="{ 'on-hover': hover, color: 'red' }" class="pa-5 rounded-xl"
                        @click="btnSelectOptionMenu('editardoc')">
                        <div class="d-flex align-center flex-column justify-center">
                          <div>
                            <v-icon :color="hover ? 'background' : 'primary'"
                              size="48">mdi-file-document-edit-outline</v-icon>
                          </div>
                          <h3 :class="hover ? 'background--text' : 'black--text'">
                            Guias y borradores
                          </h3>
                        </div>
                      </v-card>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-container>
            </v-item-group>
          </div>
        </div>
        <!-- contenidods de los menu -->
        <div v-else>
          <!-- contenido venta -->
          <v-row v-if="opcionVenta" class="pa-0 ma-0">
            <v-card width="100%" elevation="0">
              <v-row :class="isMobile ? 'flex-column' : 'pa-0 ma-0'">
                <!-- ajutes venta-->
                <v-col class="colventa">
                  <!-- cliente y forma -->
                  <div class="colventacenter">
                    <h3 class="py-3">Datos para la venta</h3>
                    <!-- tipo doc y vendedor -->
                    <div>
                      <!-- tipo doc -->
                      <div class="d-flex">
                        <v-autocomplete class="my-2" label="Seleccione documento" outlined v-model="selectTipoDoc"
                          :readonly="disabledCart" return-object item-text="Concepto" dense
                          :items="tablaimptosfix"></v-autocomplete>
                      </div>
                      <!-- vendedor -->
                      <v-autocomplete dense outlined :items="doctors" :readonly="disabledCart" v-model="selectVendedor"
                        item-text="Nombre" item-value="Rut" label="Seleccione vendedor"></v-autocomplete>
                    </div>
                    <!-- cliente y forma -->
                    <div class="">
                      <div class="">
                        <!-- Datos cliente -->
                        <div
                          class="d-flex my-2 align-center d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row">
                          <div class="d-flex align-center">
                            <v-icon>mdi-account-circle-outline</v-icon>
                            <h3 class="ml-2">Cliente</h3>
                          </div>
                          <v-chip class="mx-2 client-data" color="primary" outlined v-if="selectCliente.Nombre">{{
                            selectCliente.Nombre }} |
                            {{ selectCliente.Amo }}</v-chip>
                          <div class="d-flex">
                            <v-chip v-if="!disabledCart" class="ml-2" color="primary"
                              @click="activeSearchCliente = true">Seleccionar</v-chip>
                            <v-spacer></v-spacer>
                            <v-if v-if="!disabledCart">
                              <div v-if="selectCliente.Nombre != 'Cliente perdido'">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-chip @click="btnSelectClientePerdido" class="ml-1" color="primary" v-bind="attrs"
                                      v-on="on" label>
                                      <v-icon left>
                                        mdi-account-circle-outline
                                      </v-icon>
                                      Cliente perdido
                                    </v-chip>
                                  </template>
                                  <span>No seleccionar cliente para esta
                                    venta</span>
                                </v-tooltip>
                              </div>
                            </v-if>
                          </div>
                        </div>
                        <!-- select cliente -->
                        <v-dialog v-model="activeSearchCliente" transition="dialog-bottom-transition" class="rounded-xl"
                          max-width="600">
                          <template v-slot:default="">
                            <v-card width="100%" class="pa-3 rounded-xl">
                              <v-card-title class="mt-4 ml-10">
                                <h2>Buscar cliente</h2>
                              </v-card-title>
                              <div class="pa-3">
                                <div class="d-flex">
                                  <v-text-field v-model="search" append-icon="mdi-magnify"
                                    placeholder="Para buscar por nombre, rut o teléfono, debe presionar enter o presionar el boton buscar al final de esté campo"
                                    @keyup.enter="btnSearchCliente" hide-details class="mb-1"></v-text-field>
                                  <v-btn color="primary" outlined class="mt-2 ml-2 text-capitalize"
                                    @click="btnSearchCliente">Buscar</v-btn>
                                </div>
                                <v-data-table :headers="headersClientes" :items="clientes" :search="search"
                                  :loading="loadingClientes" loading-text="Buscando... Espere" class="mt-2" dense
                                  :footer-props="{
                                    'items-per-page-text': 'Filas por página',
                                  }">
                                  <template v-slot:item.action="{ item }">
                                    <div class="actions pa-1">
                                      <v-tooltip left>
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-btn color="primary" icon v-bind="attrs" v-on="on"
                                            class="text-capitalize rounded-lg"
                                            @click="btnSelectCliente(item)"><v-icon>mdi-check-circle-outline</v-icon>
                                          </v-btn>
                                        </template>
                                        <span>Seleccionar</span>
                                      </v-tooltip>
                                    </div>
                                  </template>
                                  <template v-slot:no-data>
                                    <v-alert :value="true">
                                      No hay resultados disponibles
                                    </v-alert>
                                  </template>
                                </v-data-table>
                              </div>
                            </v-card>
                          </template>
                        </v-dialog>
                      </div>
                      <!-- label pago -->
                      <div class="d-flex mt-3 mb-1 align-center">
                        <v-icon size="30">mdi-cash</v-icon>
                        <h3 class="ml-2">Pago</h3>
                      </div>
                      <!-- Tipo de pago -->
                      <div class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row">
                        <v-autocomplete class="" label="Forma de pago" outlined dense :items="formaPago"
                          v-model="selectFormaPago"></v-autocomplete>
                        <v-text-field v-if="selectFormaPago == 'Tarjeta D/C' ||
                          selectFormaPago == 'Cheque' ||
                          selectFormaPago == 'CDC' ||
                          selectFormaPago == 'Transferencia' ||
                          selectFormaPago == 'Deposito' ||
                          selectFormaPago == 'N.de Credito' ||
                          selectFormaPago == 'Canje'
                          " :class="isMobile ? 'mt-2' : 'ml-2'" outlined type="number" dense v-model="selectReg"
                          label="Referencia del pago"></v-text-field>
                      </div>
                      <!-- banco y vencimiento -->
                      <div class="mt-2 d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row">
                        <v-autocomplete class="" v-if="selectFormaPago == 'Cheque'" label="Banco" :items="bancos"
                          v-model="selectBancoCheque" dense outlined></v-autocomplete>
                        <v-menu v-if="selectFormaPago == 'Cheque'" v-model="menuFechaCheque"
                          :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
                          min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="selectFechaCheque" label="Fecha cheque" outlined dense
                              :class="isMobile ? 'mt-2' : 'ml-2'" readonly v-bind="attrs" v-on="on"></v-text-field>
                          </template>
                          <v-date-picker locale="ES-es" :min="today" v-model="selectFechaCheque"
                            @input="menuFechaCheque = false"></v-date-picker>
                        </v-menu>
                      </div>
                    </div>
                    <v-alert v-if="activeExento && activeAfecto" outlined color="primary">Esta venta va a generar dos
                      documentos tributarios afecto
                      y exento, por tener productos y servicios</v-alert>
                  </div>
                  <!-- Monto de pagar -->
                  <div>
                    <v-card elevation="0" :class="isMobile ? 'mt-16' : 'mb-3'">
                      <h3 class="mb-1">Monto a pagar</h3>
                      <v-text-field outlined class="pa-0 ma-0" min="0" v-model="selectMontoPagado"
                        type="number"></v-text-field>
                      <div class="d-flex justify-end">
                        <v-btn @click="btnPagar" :disabled="disabledBtnPay" :loading="activeLoadingPagar"
                          class="text-capitalize" color="primary">Pagar</v-btn>
                      </div>

                      <div class="pa-3">
                        <div class="d-flex justify-space-between">
                          <h3 class="mx-3">Total a pagar</h3>
                          <h3 class="mx-3">
                            $ {{ calculateTotal | formatCurrency }}
                          </h3>
                        </div>
                        <div class="d-flex justify-space-between">
                          <h3 class="mx-3">Pagado</h3>
                          <h3 class="mx-3">
                            $ {{ calculateTotalPagado | formatCurrency }}
                          </h3>
                        </div>
                        <div class="d-flex justify-space-between">
                          <h3 class="mx-3">Pendiente</h3>
                          <h3 class="mx-3">
                            $
                            {{
                              (calculateTotal - calculateTotalPagado)
                              | formatCurrency
                            }}
                          </h3>
                        </div>
                      </div>
                    </v-card>
                  </div>
                  <!-- Diálogo generacion y descargar documentos -->
                  <v-dialog v-model="activeGenerarSii" persistent max-width="500">
                    <v-card class="rounded-xl pa-3">
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn icon color="primary" @click="btnCloseDialogSii">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-card-actions>
                      <v-card-title class="text-h5 d-flex justify-center">
                        Documentos tributarios de la venta
                      </v-card-title>
                      <v-card-text>
                        <div>
                          <v-card class="my-2 pa-3" elevation="0" outlined v-for="(doc, index) in vtadoctributario"
                            :key="index">
                            <v-row>
                              <v-col class="d-flex" cols="3"><v-icon class="mr-1">mdi-file-document-outline</v-icon>
                                <div class="">
                                  {{ doc.Folio }}
                                </div>
                              </v-col>
                              <v-col>{{
                                doc.Vtasser[0].Tipo
                              }}</v-col>
                              <v-col>
                                <div :key="index">
                                  <GenerateDocTributarioPdfVue :dte="{
                                    cliente: doc.Cliente,
                                    vtaser: doc.Vtasser,
                                  }" />
                                </div>
                              </v-col>
                            </v-row>

                          </v-card>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </v-col>
                <v-divider vertical></v-divider>
                <!-- carro -->
                <v-col>
                  <div class="flex-column align-space-between colcart">
                    <!-- Búsqueda de productos -->
                    <div>
                      <div>
                        <div class="d-flex search-products" :class="isMobile ? 'mt-15' : 'ma-0'">
                          <div>
                            <v-icon class="mx-2 mt-1" size="33">mdi-barcode-scan</v-icon>
                          </div>
                          <!-- buscar -->
                          <v-autocomplete :loading="activeLoadingCargarProdu" :items="produs" :readonly="disabledCart" item-text="Despcod" outlined dense
                            :search-input.sync="searchProdus" label="Buscar productos" clearable return-object
                            v-model="selectProdu">
                          </v-autocomplete>
                          <v-btn color="primary" class="mt-1 ml-2" @click="btnSearchProductByServiece"
                            @keyup.enter="btnSearchProductByServiece">Buscar</v-btn>
                          <div>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" dark v-bind="attrs" v-on="on" icon class="mx-4 mt-1"
                                  @click="activeReaderCodeVentaNormal = true">
                                  <v-icon class="mx-2 mb-1" size="50">mdi-camera</v-icon>
                                </v-btn>
                              </template>
                              <span>Usar la cámara</span>
                            </v-tooltip>
                          </div>
                        </div>
                        <div v-if="activeReaderCodeVentaNormal">
                          <ReaderAllCode origen="ventanormal" @actualizarCodigoVenta="actualizarCodigoVenta" />
                        </div>
                      </div>
                    </div>
                    <!-- carro -->
                    <div class="mb-2 colcartcenter overflow-y-auto">
                      <!-- add product -->
                      <v-card color="" outlined elevation="1" class="pa-0 mb-2 ma-2 rounded-xl" v-if="selectProdu"
                        :loading="activeLoadingSearchInventarios">
                        <div class="pa-2">
                          <div class="d-flex justify-center">
                            <h3 class="black--text my-2 mx-3">
                              {{ selectProdu.Descripcion }}
                            </h3>
                          </div>
                          <!-- precio , descuento y cantidad -->
                          <div
                            class="ml-3 d-flex align-start align-sm-start align-md-center align-lg-center justify-center mb-3 flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row">
                            <div>
                              <h3 class="">Precio</h3>
                              <h2>
                                {{ selectProdu.Vtadeta | formatCurrency }}
                              </h2>
                            </div>
                            <v-divider vertical class="mx-3 mt-2 mb-1">
                            </v-divider>
                            <div>
                              <h3 class="ma-1">Aplicar descuento</h3>
                              <v-text-field dense outlined type="number" min="0" v-model="selectDescuento"
                                label="Descuento %"></v-text-field>
                            </div>
                            <v-divider vertical class="mx-3 mt-2 mb-1">
                            </v-divider>
                            <!-- cantidad a agregar -->
                            <div class="">
                              <h3 class="ma-1">Cantidad</h3>
                              <div class="d-flex">
                                <v-text-field outlined color="primary" type="number" min="1"
                                  v-model="cantidadAgregarCarro" dense></v-text-field>
                                <v-btn @click="btnAddCarrito(selectProdu.Id)" color="primary"
                                  class="mx-2 text-capitalize">Agregar</v-btn>
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-card>
                      <h3 class="mt-2">Carro</h3>
                      <!-- lista del carro -->
                      <div v-if="carrito.length > 0">
                        <!-- menu del carro -->
                        <v-row class="d-flex justify-center">
                          <v-col>Producto</v-col>
                          <v-col v-if="$vuetify.breakpoint.mdAndUp">Precio</v-col>
                          <v-col v-if="$vuetify.breakpoint.mdAndUp">Cantidad</v-col>
                          <v-col v-if="$vuetify.breakpoint.mdAndUp">Descuento</v-col>
                          <v-col>Total</v-col>
                          <v-col></v-col>
                        </v-row>
                        <v-row v-for="productocarro in carrito" :key="productocarro.Id">
                          <v-col sm="6" lg="3">
                            <div>
                              <v-chip small outlined color="primary" v-if="productocarro.Imp == 0">Exento</v-chip>
                              {{ productocarro.Nompro }}
                            </div>
                          </v-col>
                          <v-col v-if="$vuetify.breakpoint.mdAndUp">$
                            {{ productocarro.Precio | formatCurrency }}</v-col>
                          <v-col v-if="$vuetify.breakpoint.mdAndUp">{{
                            productocarro.Cantidad
                          }}</v-col>
                          <v-col v-if="$vuetify.breakpoint.mdAndUp">$ {{ productocarro.Des | formatCurrency }}</v-col>
                          <v-col>$ {{ productocarro.Total | formatCurrency }}</v-col>
                          <v-col sm="2" lg="2">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn @click="btnDeleteProduCart(productocarro.Cod)" icon :disabled="disabledCart"
                                  v-bind="attrs" v-on="on"><v-icon color="red">mdi-delete-outline</v-icon></v-btn>
                              </template>
                              <span>Quitar del carro</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </div>
                      <div v-else class="my-4">
                        No ha seleccionado ningún producto
                      </div>
                    </div>
                    <!-- total -->
                    <v-divider class="my-1"></v-divider>
                    <div class="my-2">
                      <div class="d-flex">
                        <h3>Total productos {{ calculateCantidad }}</h3>
                        <v-spacer></v-spacer>
                        <h3>Total ${{ calculateTotal | formatCurrency }}</h3>
                      </div>
                      <!-- Botones de limpiar y continuar después -->
                      <div class="d-flex justify-end mt-3 align-center" v-if="uidVenta">
                        <v-btn class="mx-2 mt-1 text-capitalize" color="red" outlined v-if="!disabledCart"
                          @click="btnLimpiarFormVenta">Limpiar</v-btn>
                        <v-btn class="mx-2 mt-1 text-capitalize" color="red" outlined v-if="selectActiveCuentaHospital"
                          @click="btnLimpiarFormVenta">Limpiar</v-btn>
                        <v-btn v-if="!disabledCart" class="mx-2 text-capitalize btn-continue-after" color="primary"
                          outlined @click="btnCreateCarritoPendiente"
                          :loading="activeLoadingCreateCarritoPendiente">Continuar después</v-btn>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
          <!-- contenido buscar -->
          <v-row v-if="opcionBuscarProducto" class="my-3">
            <v-card width="100%" elevation="0" class="pa-3">
              <h2 class="text-center">Productos</h2>
              <v-row>
                <!-- búsqueda -->
                <v-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <div>
                    <div class="pa-3">
                      <div class="d-flex my-3">
                        <v-text-field v-model="searchProdus" append-icon="mdi-magnify"
                          placeholder="Escriba para buscar servicio o producto" single-line outlined dense
                          class="mb-2 my-2" hide-details></v-text-field>
                        <v-btn color="primary" class="mt-1 ml-2" @click="btnSearchProductByServiece"
                          @keyup.enter="btnSearchProductByServiece">Buscar</v-btn>
                        <div>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn color="primary" dark v-bind="attrs" v-on="on" icon class="mx-4 mt-3"
                                @click="activeReaderSearchProdu = true">
                                <v-icon class="mx-2 mb-1" size="50">mdi-camera</v-icon>
                              </v-btn>
                            </template>
                            <span>Usar la cámara</span>
                          </v-tooltip>
                        </div>
                      </div>
                      <div v-if="activeReaderSearchProdu">
                        <ReaderAllCode origen="buscarprodu" @actualizarCodigoBuscarProdu="actualizarCodigoBuscarProdu
                          " />
                      </div>
                      <v-data-table locale="ES-ES" :headers="headersProdus" :items="produs"
                        :loading="activeLoadingCargarProdu" :search="searchProdus" hide-default-footer dense>
                        <template v-slot:item.Vtadeta="{ item }">
                          <div>$ {{ item.Vtadeta | formatCurrency }}</div>
                        </template>
                        <template v-slot:item.action="{ item }">
                          <div class="actions">
                            <v-btn class="ma-1" @click="btnSerachSaleProdu(item), irAProductoBuscado()" color="primary"
                              icon>
                              <v-icon>mdi-check-circle-outline</v-icon>
                            </v-btn>
                          </div>
                        </template>
                        <template v-slot:no-data>
                          <v-alert :value="true">
                            No hay resultados disponibles
                          </v-alert>
                        </template>
                      </v-data-table>
                    </div>
                  </div>
                </v-col>
                <!-- producto buscado -->
                <v-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <div>
                    <v-card elevation="0" class="pa-0 mb-2 ma-2 rounded-xl">
                      <div class="pa-2">
                        <div class="d-flex" v-if="selectSearchProdu">
                          <h3 class="black--text my-2 mx-3">
                            {{ selectSearchProdu }}
                          </h3>
                          <v-spacer></v-spacer>
                          <h3 class="black--text my-2 mx-3">
                            $ {{ selectProdu.Vtadeta | formatCurrency }}
                          </h3>
                        </div>
                        <!-- Sucursales -->
                        <div>
                          <h3 class="text-center my-3">
                            Disponibilidad en sucursales
                          </h3>
                          <div v-if="selectSearchProdu" ref="productoBuscado">
                            <v-card elevation="1" class="d-flex pa-3 justify-space-between my-2"
                              v-for="inventario in inventarios" :key="inventario.Id">
                              <div>
                                {{ inventario.Sucursal
                                }}<v-chip v-if="inventario.Sucursal == user.Sucursal" class="mx-2" outlined
                                  color="primary" small>Actual</v-chip>
                              </div>
                              <div class="mx-4">{{ inventario.Saldos }}</div>
                            </v-card>
                          </div>
                          <div v-else>
                            <div class="my-4 text-center">
                              No hay ningún producto seleccionado
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-card>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
          <!-- contenido continuar venta -->
          <v-row v-if="opcionContinuarVenta" class="my-3">
            <!-- Ventas por continuar -->
            <v-col>
              <h2 class="text-center">Ventas por continuar</h2>
              <div>
                <v-tabs v-model="tabContinuarVenta" class="d-flex my-2"
                  :class="isMobile ? 'justify-start' : 'justify-center'">
                  <v-tab key="carro" class="text-capitalize">Carros por continuar</v-tab>
                  <v-tab key="hospital" class="text-capitalize">Cuentas de hospital</v-tab>
                  <v-tab key="atencion" class="text-capitalize">Cuenta de atención</v-tab>
                </v-tabs>
              </div>
              <v-tabs-items v-model="tabContinuarVenta">
                <v-tab-item key="carro">
                  <div v-if="carritosPendientes.length > 0">
                    <div class="pa-3">
                      <!-- input de buscar -->
                      <v-text-field v-model="searchCarritoPendiente" append-icon="mdi-magnify"
                        placeholder="Busque por cualquier término" single-line outlined class="mb-2 my-2"
                        hide-details></v-text-field>
                      <!-- data de búsqueda -->
                      <v-data-table locale="ES-ES" :headers="headersCarritosPendiente" items-per-page="6"
                        :items="carritosPendientes" :search="searchCarritoPendiente">
                        <template v-slot:item.action="{ item }">
                          <v-btn color="primary" icon
                            @click="btnSelectCarritoPendienteByUid(item.Uid), scrollToInfo()"><v-icon>mdi-check-circle-outline</v-icon></v-btn>
                        </template>
                        <template v-slot:no-data>
                          <v-alert :value="true">
                            No hay resultados disponibles
                          </v-alert>
                        </template>
                      </v-data-table>
                    </div>
                  </div>
                  <div v-else class="my-4 text-center">
                    No hay ventas pendientes para continuar
                  </div>
                </v-tab-item>
                <v-tab-item key="hospital">
                  <div class="d-flex mb-3"></div>
                  <div class="d-flex flex-column align-center">
                    <v-col class="mb-3 d-flex justify-center">
                      <h3 class="black_h3 ma-2 mt-3">Cuenta de hospital</h3>
                    </v-col>
                    <v-col>
                      <v-row class="d-flex">
                        <v-col class="pa-2">
                          <h3 class="black_h3 ma-2">Cuenta de hospital</h3>
                          <v-autocomplete v-model="selectFichaCuenta" :items="setCuentaHospital" item-text="Ficha"
                            outlined :return-value.sync="val" dense label="Seleccione cuenta de hospital"
                            @change="btnSelectCuentaHospital(val)" class="ma-2 mt-4"></v-autocomplete>
                        </v-col>
                      </v-row>
                      <div v-if="cliente.Id">
                        <div>Cliente: {{ cliente.Nombre }}</div>
                        <div>Propietario: {{ cliente.Amo }}</div>
                      </div>
                    </v-col>
                  </div>
                  <v-divider class="mb-3"></v-divider>
                </v-tab-item>
                <v-tab-item key="atencion">
                  <div class="d-flex mb-3"></div>
                  <div class="d-flex flex-column align-center">
                    <v-col class="mb-3 d-flex justify-center">
                      <h3 class="black_h3 ma-2 mt-3">Cuenta de atención</h3>
                    </v-col>
                    <v-col>
                      <v-row class="d-flex">
                        <v-col class="pa-2">
                          <h3 class="black_h3 ma-2">Cuenta de cliente</h3>
                          <v-autocomplete v-model="selectFichaCuenta" :items="setCuentaAlCliente" item-text="Ficha"
                            outlined :return-value.sync="val" dense label="Seleccione cuenta del cliente"
                            @change="btnSelectCuentaCliente(val)" class="ma-2 mt-4"></v-autocomplete>
                        </v-col>
                      </v-row>
                      <div v-if="cliente.Id">
                        <div>Cliente: {{ cliente.Nombre }}</div>
                        <div>Propietario: {{ cliente.Amo }}</div>
                      </div>
                    </v-col>
                  </div>
                  <v-divider class="mb-3"></v-divider>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
            <v-divider vertical></v-divider>
            <!-- detalle de la venta para continuar -->
            <v-col>
              <h2 class="text-center">Carro</h2>
              <div v-if="selectCarroContinuar.length > 0" ref="infoSection">
                <div class="border solid 1px pa-3">
                  <div class="d-flex justify-space-between">
                    <h3 class="mx-3">Total a pagar</h3>
                    <h3 class="mx-3">
                      $ {{ calculateCarritoPendienteTotal | formatCurrency }}
                    </h3>
                  </div>
                  <div class="d-flex justify-space-between">
                    <h3 class="mx-3">Pagado</h3>
                    <h3 class="mx-3">
                      $
                      {{
                        calculateCarritoPendienteTotalPagado | formatCurrency
                      }}
                    </h3>
                  </div>
                  <div class="d-flex justify-space-between">
                    <h3 class="mx-3">Pendiente</h3>
                    <h3 class="mx-3">
                      $
                      {{
                        (calculateCarritoPendienteTotal -
                          calculateCarritoPendienteTotalPagado)
                        | formatCurrency
                      }}
                    </h3>
                  </div>
                </div>
                <!-- acciones -->
                <div class="d-flex" v-if="selectActiveCuentaHospital">
                  <v-autocomplete class="my-2 mr-3" label="Seleccione documento" outlined v-model="selectTipoDoc"
                    return-object item-text="Concepto" dense :items="tablaimptosfix"></v-autocomplete>
                  <v-btn color="primary" @click="btnContinuarCarritoPendiente"
                    class="my-2 text-capitalize rounded-lg">Pagar cuenta de hospital</v-btn>
                </div>
                <div v-else class="d-flex justify-end">
                  <v-btn color="red" outlined @click="activeDialogoDeleteCarro = true"
                    class="my-2 mx-2 text-capitalize rounded-lg">Cancelar carro</v-btn>
                  <v-btn color="primary" @click="btnContinuarCarritoPendiente"
                    class="my-2 text-capitalize rounded-lg">Continuar carro pendiente</v-btn>
                  <v-dialog v-model="activeDialogoDeleteCarro" max-width="500px" class="ma-8" persistent>
                    <v-card class="rounded-xl">
                      <div class="pa-3">
                        <v-card-title class="title-1 text--center">¿Seguro(a) que quiere cancelar el carro? Esto no se
                          puede deshacer. Se devolverán los artículos tomados al
                          inventario.
                        </v-card-title>
                        <v-card-actions class="d-flex justify-end">
                          <v-btn color="primary" outlined @click="activeDialogoDeleteCarro = false"
                            class="text-capitalize rounded-lg">Volver</v-btn>
                          <v-btn color="red" outlined :loading="activeLoadingCancelarCarrito"
                            @click="btnConfirmaDeleteCarrito" class="text-capitalize rounded-lg ml-3">Confirmar</v-btn>
                        </v-card-actions>
                      </div>
                    </v-card>
                  </v-dialog>
                </div>
                <v-row class="my-3">
                  <v-col>Producto</v-col>
                  <v-col>Precio</v-col>
                  <v-col>Cantidad</v-col>
                  <v-col>Descuento</v-col>
                  <v-col>Total</v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="my-3" v-for="productocarro in selectCarroContinuar" :key="productocarro.Id">
                  <v-col>{{ productocarro.Nompro }}</v-col>
                  <v-col>$ {{ productocarro.Precio | formatCurrency }}</v-col>
                  <v-col>{{ productocarro.Cantidad }}</v-col>
                  <v-col>$ {{ productocarro.Des | formatCurrency }}</v-col>
                  <v-col>$ {{ productocarro.Total | formatCurrency }}</v-col>
                </v-row>
              </div>
              <div v-else class="my-4 text-center">Sin carro seleccionado</div>
            </v-col>
          </v-row>
          <!-- contenido ver ventas -->
          <v-row v-if="opcionVerVentas" class="my-3">
            <v-card class="pa-3" width="100%" elevation="0">
              <div class="pa-3">
                <div class="d-flex">
                  <v-spacer></v-spacer>
                  <h2 class="mb-3">Ventas</h2>
                  <v-spacer></v-spacer>
                  <v-menu v-model="menuDateFilterVentas" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" left min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="dateFilterVentas" label="Filtrar por fecha" prepend-icon="mdi-calendar" dense
                        outlined v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="dateFilterVentas" :max="today" class="d-flex ma-auto">
                      <v-row class="d-flex justify-end pa-2">
                        <v-btn text color="primary" @click="btnFilterDateVtaser" class="text-capitalize">
                          Filtrar
                        </v-btn>
                      </v-row>
                    </v-date-picker>
                  </v-menu>
                </div>
              </div>
              <!-- barra de estadistica (escritorio) -->
              <v-card class="white my-2 pa-3 d-none d-sm-none d-md-none d-lg-flex d-xl-flex" elevation="1">
                <v-row class="justify-center">
                  <!-- Cantidad ventas -->
                  <v-col>
                    <p class="text-center">
                      <strong>Cantidad Ventas</strong>
                    </p>
                    <h1 class="text-center">{{ statsventas.total }}</h1>
                  </v-col>
                  <v-col>
                    <p class="text-center">
                      <strong>Monto Total</strong>
                    </p>
                    <h1 class="text-center">
                      $ {{ statsventas.totalmonto | formatCurrency }}
                    </h1>
                  </v-col>
                  <!-- monto pagado -->
                  <v-col>
                    <p class="text-center">
                      <strong>Monto pagado</strong>
                    </p>
                    <h1 class="text-center">
                      $ {{ statsventas.totalpagado | formatCurrency }}
                    </h1>
                  </v-col>
                  <v-col>
                    <p class="text-center">
                      <strong>Cantidad artículos</strong>
                    </p>
                    <h1 class="text-center">
                      {{ statsventas.totalarticulos }}
                    </h1>
                  </v-col>
                  <v-col>
                    <p class="text-center">
                      <strong>Total unidades</strong>
                    </p>
                    <h1 class="text-center">
                      {{ statsventas.totalunidades }}
                    </h1>
                  </v-col>
                </v-row>
              </v-card>
              <!-- barra de estadistica (móvil) -->
              <v-card class="white my-2 pa-3 d-flex d-sm-flex d-md-flex d-lg-none d-xl-none" elevation="1">
                <v-col class="justify-center">
                  <!-- Cantidad ventas -->
                  <v-row class="d-flex justify-space-between">
                    <p class="text-center">
                      <strong>Cantidad Ventas:</strong>
                    </p>
                    <h2>{{ statsventas.total }}</h2>
                  </v-row>
                  <v-row class="d-flex justify-space-between">
                    <p class="text-center">
                      <strong>Monto Total:</strong>
                    </p>
                    <h2>$ {{ statsventas.totalmonto | formatCurrency }}</h2>
                  </v-row>
                  <!-- monto pagado -->
                  <v-row class="d-flex justify-space-between">
                    <p>
                      <strong>Monto pagado:</strong>
                    </p>
                    <h2>$ {{ statsventas.totalpagado | formatCurrency }}</h2>
                  </v-row>
                  <v-row class="d-flex justify-space-between">
                    <p>
                      <strong>Cantidad artículos:</strong>
                    </p>
                    <h2>
                      {{ statsventas.totalarticulos }}
                    </h2>
                  </v-row>
                  <v-row class="d-flex justify-space-between">
                    <p>
                      <strong>Total unidades:</strong>
                    </p>
                    <h2>
                      {{ statsventas.totalunidades }}
                    </h2>
                  </v-row>
                </v-col>
              </v-card>
              <!-- barra de vistas y descarga -->
              <div class="my-2" :class="isMobile ? 'd-flex flex-column' : 'd-flex'">
                <v-tabs v-model="selectVistaVentas">
                  <v-tab key="ventas" class="text-capitalize">Ventas</v-tab>
                  <v-tab key="ventasdiferencia" class="text-capitalize">Ventas con diferencia</v-tab>
                </v-tabs>
                <v-spacer></v-spacer>
                <div :class="isMobile
                    ? 'd-flex mt-3 justify-center'
                    : 'd-flex justify-end'
                  ">
                  <GeneratePdfVtaserVue :dateVtaser="dateFilterVentas" />
                  <v-btn class="mx-2" color="primary" outlined>
                    <div class="d-flex" @click="btnExportExcelVtasser">
                      <v-icon>mdi-microsoft-excel</v-icon>
                      <div class="mt-1 rounded-lg text-capitalize">
                        Descargar excel
                      </div>
                    </div>
                  </v-btn>
                </div>
              </div>
              <!-- tabla y busqueda -->
              <div class="pa-3">
                <v-tabs-items v-model="selectVistaVentas">
                  <v-tab-item key="ventas">
                    <v-text-field v-model="searchVtasser" append-icon="mdi-magnify"
                      placeholder="Busque por cualquier término" single-line outlined class="mb-2 my-2"
                      hide-details></v-text-field>
                    <!-- tabla de datos -->
                    <v-data-table locale="ES-ES" :headers="headersVtasser" :items="vtasser"
                      :loading="activeLoadingVtasser" :search="searchVtasser">
                      <template v-slot:item.Precio="{ item }">
                        <div class="actions">
                          <div>$ {{ item.Precio | formatCurrency }}</div>
                        </div>
                      </template>
                      <template v-slot:item.Des="{ item }">
                        <div class="actions">
                          <div>$ {{ item.Des | formatCurrency }}</div>
                        </div>
                      </template>
                      <template v-slot:item.Montopagado="{ item }">
                        <div class="actions">
                          <div>$ {{ item.Montopagado | formatCurrency }}</div>
                        </div>
                      </template>
                      <template v-slot:item.action="{ item }">
                        <div class="actions">
                          <v-btn v-if="item.Frmt != 'A'" icon
                            @click="btnSearchVtasserByDocumentoImprimir(item)"><v-icon>mdi-printer-outline</v-icon></v-btn>
                          <div v-else>Sin documento</div>
                        </div>
                      </template>
                      <template v-slot:no-data>
                        <v-alert :value="true"> No hay resultados disponibles </v-alert>
                      </template>
                    </v-data-table>
                    <!-- imprimir boleta -->
                    <v-dialog v-model="activeDialogoImprimirDocVta" persistent max-width="768" class="rounded-xl">
                      <v-card class="pa-3 rounded-xl">
                        <div class="d-flex justify-end ma-2">
                          <v-spacer></v-spacer>
                          <v-btn icon color="primary" @click="btnCancelarSearchVtasserByDocumentoImprimir">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </div>
                        <v-card-title class="text-h5 justify-center mb-3">
                          Imprimir documento tributario de la venta
                        </v-card-title>
                        <v-card-text>Esta acción recupera toda la venta realizada por tipo
                          de documento
                          <v-data-table locale="ES-ES" :headers="headersVtasserPrint" :items="selectSearchVtabydocPrint">
                            <template v-slot:item.Precio="{ item }">
                              <div class="actions">
                                <div>$ {{ item.Precio | formatCurrency }}</div>
                              </div>
                            </template>
                            <template v-slot:item.Des="{ item }">
                              <div class="actions">
                                <div>$ {{ item.Des | formatCurrency }}</div>
                              </div>
                            </template>
                            <template v-slot:item.Montopagado="{ item }">
                              <div class="actions">
                                <div>
                                  $ {{ item.Montopagado | formatCurrency }}
                                </div>
                              </div>
                            </template>
                          </v-data-table>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end">
                          <div v-if="activePrintDoc">
                            <GenerateDocTributarioPdfVue :dte="dte" />
                          </div>
                          <div v-else>
                            <v-alert class="mx-2" color="red" outlined type="info">No se puede imprimir este documento
                              debido a que
                              no ha sido cancelado completamente</v-alert>
                          </div>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-tab-item>
                  <v-tab-item key="ventasdiferencia">
                    <!-- tabla de datos -->
                    <v-data-table locale="ES-ES" :headers="headersVtasserDiferencias" :items="vtasserDiferencias"
                      :loading="activeLoadingVtasser" :search="searchVtasser">
                      <template v-slot:item.Precio="{ item }">
                        <div class="actions">
                          <div>$ {{ item.Precio | formatCurrency }}</div>
                        </div>
                      </template>
                      <template v-slot:item.Des="{ item }">
                        <div class="actions">
                          <div>$ {{ item.Des | formatCurrency }}</div>
                        </div>
                      </template>
                      <template v-slot:item.Montopagado="{ item }">
                        <div class="actions">
                          <div>$ {{ item.Montopagado | formatCurrency }}</div>
                        </div>
                      </template>
                      <template v-slot:item.Pendiente="{ item }">
                        <div class="red--text">
                          <h2>
                            $
                            {{
                              (item.Total - item.Montopagado) | formatCurrency
                            }}
                          </h2>
                        </div>
                      </template>
                      <template v-slot:item.action="{ item }">
                        <div class="actions pa-1">
                          <div v-if="item.Tipodte != '0'">
                            <v-btn color="primary" @click="btnSearchVtasserByDocumento(item)">Pagar</v-btn>
                          </div>
                          <div v-else>
                            <v-alert type="info" color="primary" class="mt-2" outlined dense>
                              Esta venta no se puede pagar aquí, debe pagar en
                              "Otros documentos"
                            </v-alert>
                          </div>
                        </div>
                      </template>
                      <!-- <template v-slot:no-data>
                      <v-alert :value="true">
                        No hay resultados disponibles
                      </v-alert>
                    </template> -->
                    </v-data-table>
                    <v-dialog v-model="activeDialogoPagarPendiente" persistent max-width="768" class="rounded-xl">
                      <v-card class="pa-3 rounded-xl">
                        <v-card-title class="text-h5">
                          Pagar monto pendiente de la venta
                        </v-card-title>
                        <v-card-text>Esta acción recupera toda la venta realizada por tipo
                          de documentos para pagar los montos pendientes y
                          generar el documento timbrado
                          <v-data-table locale="ES-ES" :headers="headersVtasserDiferenciasPago"
                            :items="selectSearchVtabydoc" :loading="activeLoadingSearchVtasDiferenciaPago">
                            <template v-slot:item.Precio="{ item }">
                              <div class="actions">
                                <div>$ {{ item.Precio | formatCurrency }}</div>
                              </div>
                            </template>
                            <template v-slot:item.Des="{ item }">
                              <div class="actions">
                                <div>$ {{ item.Des | formatCurrency }}</div>
                              </div>
                            </template>
                            <template v-slot:item.Montopagado="{ item }">
                              <div class="actions">
                                <div>
                                  $ {{ item.Montopagado | formatCurrency }}
                                </div>
                              </div>
                            </template>
                            <template v-slot:item.Pendiente="{ item }">
                              <div class="red--text">
                                <h2>
                                  $
                                  {{
                                    (item.Total - item.Montopagado)
                                    | formatCurrency
                                  }}
                                </h2>
                              </div>
                            </template>

                            <!-- <template v-slot:no-data>
                      <v-alert :value="true">
                        No hay resultados disponibles
                      </v-alert>
                    </template> -->
                          </v-data-table>

                          <v-row>
                            <v-col></v-col>
                            <v-col>
                              <v-divider class="my-2"></v-divider>
                              <div>
                                <div class="d-flex justify-space-between">
                                  <h3 class="mx-3">Total</h3>
                                  <h3 class="mx-3">
                                    $
                                    {{
                                      calculateTotalVtaDiferencia
                                      | formatCurrency
                                    }}
                                  </h3>
                                </div>
                                <div class="d-flex justify-space-between">
                                  <h3 class="mx-3">Pagado</h3>
                                  <h3 class="mx-3">
                                    $
                                    {{
                                      calculateTotalPagadoVtaDiferencia
                                      | formatCurrency
                                    }}
                                  </h3>
                                </div>
                                <div class="d-flex justify-space-between">
                                  <h3 class="mx-3">Pendiente</h3>
                                  <h3 class="mx-3">
                                    $
                                    {{
                                      (calculateTotalVtaDiferencia -
                                        calculateTotalPagadoVtaDiferencia)
                                      | formatCurrency
                                    }}
                                  </h3>
                                </div>
                              </div>
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" outlined @click="btnCancelarSearchVtasserByDocumento">
                            Atras
                          </v-btn>
                          <v-btn color="primary" :disabled="selectSearchVtabydoc.length == 0"
                            @click="btnPagarVtaDiferencia">
                            Pagar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-tab-item>
                </v-tabs-items>
                <!-- input de busqueda -->
              </div>
            </v-card>
          </v-row>
          <!-- contenido ver pagos -->
          <v-row v-if="opcionVerPagos" class="my-3">
            <v-card width="100%" elevation="0" class="pa-3">
              <div class="pa-3">
                <!-- titulo -->
                <div class="pa-3">
                  <div class="d-flex">
                    <v-spacer></v-spacer>
                    <h2 class="mb-3">Pagos</h2>
                    <v-spacer></v-spacer>
                    <v-menu v-model="menuDateFilterPago" :close-on-content-click="false" :nudge-right="40"
                      transition="scale-transition" left min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="dateFilterPagos" label="Filtrar por fecha" prepend-icon="mdi-calendar"
                          dense outlined v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="dateFilterPagos" :max="today" class="d-flex ma-auto">
                        <v-row class="d-flex justify-end pa-2">
                          <v-btn text color="primary" @click="btnFilterDatePagosVtasser" class="text-capitalize">
                            Filtrar
                          </v-btn>
                        </v-row>
                      </v-date-picker>
                    </v-menu>
                  </div>
                </div>
                <!-- barra de estadistica pago (vista escritorio)-->
                <v-card class="white my-2 pa-2 d-none d-sm-none d-md-flex d-lg-flex d-xl-flex flex-column" elevation="1">
                  <v-row class="justify-center">
                    <!-- cantidad pagos -->
                    <v-col>
                      <p class="text-center">
                        <strong>Cantidad Pagos</strong>
                      </p>
                      <h1 class="text-center h3">{{ statspagos.total }}</h1>
                    </v-col>
                    <!-- monto pagado -->
                    <v-col>
                      <p class="text-center">
                        <strong>Monto Total</strong>
                      </p>
                      <h1 class="text-center h3">
                        $ {{ statspagos.totalpagado | formatCurrency }}
                      </h1>
                    </v-col>
                    <!-- efectivo -->
                    <v-col>
                      <p class="text-center">
                        <strong>Total efectivo</strong>
                      </p>
                      <h1 class="text-center h3">
                        $ {{ statspagos.totalefectivo | formatCurrency }}
                      </h1>
                    </v-col>
                    <!-- tarjeta -->
                    <v-col>
                      <p class="text-center">
                        <strong>Total Tarjeta</strong>
                      </p>
                      <h1 class="text-center h3">
                        $ {{ statspagos.totaltarjeta | formatCurrency }}
                      </h1>
                    </v-col>
                    <!-- transferencia -->
                    <v-col>
                      <p class="text-center">
                        <strong>Total Transferencia</strong>
                      </p>
                      <h1 class="text-center h3">
                        $ {{ statspagos.totaltransferencia | formatCurrency }}
                      </h1>
                    </v-col>
                    <!-- convenio -->
                    <v-col>
                      <p class="text-center">
                        <v-btn v-if="activeShowAllPagos" color="red" class="mt-3 text-capitalize rounded-lg" outlined
                          @click="activeShowAllPagos = false">
                          Ocultar pagos
                        </v-btn>
                        <v-btn v-else color="primary" class="mt-3 text-capitalize rounded-lg" outlined
                          @click="activeShowAllPagos = true">
                          Ver todos los pagos</v-btn>
                      </p>
                    </v-col>
                  </v-row>
                  <div v-if="activeShowAllPagos">
                    <v-row class="mt-8">
                      <!-- total depositos -->
                      <v-col>
                        <p class="text-center">
                          <strong>Total Depósito</strong>
                        </p>
                        <h1 class="text-center h3">
                          $ {{ statspagos.totaldeposito | formatCurrency }}
                        </h1>
                      </v-col>
                      <!-- cheque al dia -->
                      <v-col>
                        <p class="text-center">
                          <strong>Total Cheque al día</strong>
                        </p>
                        <h1 class="text-center h3">
                          $ {{ statspagos.totalchequedia | formatCurrency }}
                        </h1>
                      </v-col>
                      <!-- cheque al fecha -->
                      <v-col>
                        <p class="text-center">
                          <strong>Total Cheque a fecha</strong>
                        </p>
                        <h1 class="text-center h3">
                          $ {{ statspagos.totalchequefecha | formatCurrency }}
                        </h1>
                      </v-col>
                      <!-- total convenio -->
                      <v-col>
                        <p class="text-center">
                          <strong>Total Convenio</strong>
                        </p>
                        <h1 class="text-center h3">
                          $ {{ statspagos.totalconvenio | formatCurrency }}
                        </h1>
                      </v-col>
                      <!-- total canje -->
                      <v-col>
                        <p class="text-center">
                          <strong>Total Canje</strong>
                        </p>
                        <h1 class="text-center h3">
                          $ {{ statspagos.totalcanje | formatCurrency }}
                        </h1>
                      </v-col>
                      <!-- total n credito -->
                      <v-col>
                        <p class="text-center">
                          <strong>Total N° de Crédito</strong>
                        </p>
                        <h1 class="text-center h3">
                          $ {{ statspagos.totalndecredito | formatCurrency }}
                        </h1>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
                <!-- barra de estadistica pago (vista móvil)-->
                <v-card class="white d-flex d-sm-flex d-md-none d-lg-none d-xl-none flex-column mb-5" elevation="1">
                  <v-col class="ma-0 pa-3">
                    <!-- Cantidad pagos -->
                    <v-row class="ma-0 pa-0 d-flex align-center justify-space-between">
                      <div class="mr-2">
                        <strong>Cantidad Pagos:</strong>
                      </div>
                      <h2>{{ statspagos.total }}</h2>
                    </v-row>
                    <!-- Monto total -->
                    <v-row class="ma-0 pa-0 d-flex align-center justify-space-between">
                      <div class="mr-2">
                        <strong>Monto Total:</strong>
                      </div>
                      <h2>$ {{ statspagos.totalpagado | formatCurrency }}</h2>
                    </v-row>
                    <!-- total efectivo -->
                    <v-row class="ma-0 pa-0 d-flex align-center justify-space-between">
                      <div class="mr-2">
                        <strong>Total Efectivo:</strong>
                      </div>
                      <h2>$ {{ statspagos.totalefectivo | formatCurrency }}</h2>
                    </v-row>
                    <!-- Total tarjeta -->
                    <v-row class="ma-0 pa-0 d-flex align-center justify-space-between">
                      <div class="mr-2">
                        <strong>Total Tarjeta:</strong>
                      </div>
                      <h2>$ {{ statspagos.totaltarjeta | formatCurrency }}</h2>
                    </v-row>
                    <!-- total transferencia -->
                    <v-row class="ma-0 pa-0 d-flex align-center justify-space-between">
                      <div class="mr-2">
                        <strong>Total Transferencia:</strong>
                      </div>
                      <h2>
                        $ {{ statspagos.totaltransferencia | formatCurrency }}
                      </h2>
                    </v-row>
                    <div>
                      <!-- total depósitos -->
                      <v-row class="ma-0 pa-0 d-flex align-center justify-space-between">
                        <div class="mr-2">
                          <strong>Total Depósito:</strong>
                        </div>
                        <h2>
                          $ {{ statspagos.totaldeposito | formatCurrency }}
                        </h2>
                      </v-row>
                      <!-- cheque al dia -->
                      <v-row class="ma-0 pa-0 d-flex align-center justify-space-between">
                        <div class="mr-2">
                          <strong>Total Cheque al día:</strong>
                        </div>
                        <h2>
                          $ {{ statspagos.totalchequedia | formatCurrency }}
                        </h2>
                      </v-row>
                      <!-- cheque a fecha -->
                      <v-row class="ma-0 pa-0 d-flex align-center justify-space-between">
                        <div class="mr-2">
                          <strong>Total Cheque a fecha:</strong>
                        </div>
                        <h2>
                          $ {{ statspagos.totalchequefecha | formatCurrency }}
                        </h2>
                      </v-row>
                      <!-- total convenio -->
                      <v-row class="ma-0 pa-0 d-flex align-center justify-space-between">
                        <div class="mr-2">
                          <strong>Total Convenio:</strong>
                        </div>
                        <h2>
                          $ {{ statspagos.totalconvenio | formatCurrency }}
                        </h2>
                      </v-row>
                      <!-- total canje -->
                      <v-row class="ma-0 pa-0 d-flex align-center justify-space-between">
                        <div class="mr-2">
                          <strong>Total Canje:</strong>
                        </div>
                        <h2>$ {{ statspagos.totalcanje | formatCurrency }}</h2>
                      </v-row>
                      <!-- total n credito -->
                      <v-row class="ma-0 pa-0 d-flex align-center justify-space-between">
                        <div class="mr-2">
                          <strong>Total N° de Crédito:</strong>
                        </div>
                        <h2>
                          $ {{ statspagos.totalndecredito | formatCurrency }}
                        </h2>
                      </v-row>
                    </div>
                  </v-col>
                </v-card>
                <!-- barra de vistas y descarga -->
                <div :class="isMobile
                    ? 'd-flex flex-column justify-center'
                    : 'd-flex mx-3 justify-end mt-4'
                  ">
                  <GeneratePdfPagosVue :datePagos="dateFilterPagos" />
                  <v-btn class="mx-2" color="primary" @click="btnExportExcelPagos" outlined
                    :class="isMobile ? 'mt-2' : ''">
                    <div class="d-flex">
                      <v-icon>mdi-microsoft-excel</v-icon>
                      <div class="mt-1 ml-1 text-capitalize rounded-lg">
                        Descargar excel
                      </div>
                    </div>
                  </v-btn>
                </div>
                <!-- tabla y busqueda -->
                <div>
                  <div class="pa-3">
                    <v-text-field v-model="searchPagosVtasser" append-icon="mdi-magnify"
                      placeholder="Busque por cualquier término" single-line outlined class="mb-2 my-2"
                      hide-details></v-text-field>
                    <v-data-table locale="ES-ES" :headers="headersPagosVtasser" :items="pagosvtaser"
                      :loading="activeLoadingPagosVtasser" :search="searchPagosVtasser">
                      <template v-slot:item.Monto="{ item }">
                        <div class="actions">
                          <div>$ {{ item.Monto | formatCurrency }}</div>
                        </div>
                      </template>
                      <template v-slot:no-data>
                        <v-alert :value="true">
                          No hay resultados disponibles
                        </v-alert>
                      </template>
                    </v-data-table>
                  </div>
                </div>
              </div>
            </v-card>
          </v-row>
          <!-- contenido ver caja -->
          <v-row v-if="opcionVerCaja" class="my-3">
            <v-card width="100%" elevation="0">
              <div class="pa-3" v-if="!cerrarCaja">
                <!-- título (vista escritorio) -->
                <div class="mb-4 d-none d-sm-none d-md-flex d-lg-flex d-xl-flex justify-center">
                  <div class="d-flex flex-column">
                    <div class="d-flex justify-center">
                      <h2 class="mb-3 mt-1">Ver caja</h2>
                    </div>
                    <div class="d-flex">
                      <v-progress-circular v-if="activeLoadingVtasser | activeLoadingPagosVtasser" indeterminate
                        class="ml-3" color="primary"></v-progress-circular>
                      <v-spacer></v-spacer>
                      <GeneratePdfResumenCajaVue :dateCaja="dateFilterCaja" :statsPagos="statspagos"
                        :statsVentas="statsventas" />
                      <v-btn class="mx-2 text-capitalize rounded-lg" color="primary" @click="cerrarCaja = true">Cierre de
                        caja</v-btn>
                      <v-menu v-model="menuDateFilterCaja" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" left min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="dateFilterCaja" label="Filtrar por fecha" prepend-icon="mdi-calendar"
                            dense outlined v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="dateFilterCaja" :max="today" class="d-flex ma-auto">
                          <v-row class="d-flex justify-end pa-2">
                            <v-btn text color="primary" @click="btnFilterDateCaja" class="text-capitalize">
                              Filtrar
                            </v-btn>
                          </v-row>
                        </v-date-picker>
                      </v-menu>
                    </div>
                  </div>
                </div>
                <!-- título (vista móvil) -->
                <div class="d-flex d-sm-flex d-md-none d-lg-none d-xl-none justify-center">
                  <div class="d-flex flex-column">
                    <div class="d-flex justify-center">
                      <h2 class="mb-3 mt-1">Ver caja</h2>
                    </div>
                    <div class="d-flex ma-0 pa-0 align-start">
                      <v-progress-circular v-if="activeLoadingVtasser | activeLoadingPagosVtasser" indeterminate class=""
                        color="primary"></v-progress-circular>
                      <GeneratePdfResumenCajaVue :dateCaja="dateFilterCaja" :statsPagos="statspagos"
                        :statsVentas="statsventas" />
                      <v-btn class="text-capitalize rounded-lg" color="primary" @click="cerrarCaja = true">Cierre de
                        caja</v-btn>
                    </div>
                    <!-- <div class="my-2">
                      <v-menu
                        v-model="menuDateFilterCaja"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        left
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            class="my-1 ml-2"
                            v-model="dateFilterCaja"
                            label="Filtrar por fecha"
                            prepend-icon="mdi-calendar"
                            dense
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="dateFilterCaja"
                          :max="today"
                          class="d-flex ma-auto"
                        >
                          <v-row class="d-flex justify-end pa-2">
                            <v-btn
                              text
                              color="primary"
                              @click="btnFilterDateCaja"
                              class="text-capitalize"
                            >
                              Filtrar
                            </v-btn>
                          </v-row>
                        </v-date-picker>
                      </v-menu>
                    </div> -->
                  </div>
                </div>

                <!-- Contenido de "Ver caja" -->
                <v-row class="d-flex flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row">
                  <!-- resumen de documentos -->
                  <v-col class="">
                    <!-- titulo y cantidad de ventas -->
                    <v-row class="d-flex justify-space-between">
                      <v-col class="ml-4">
                        <h3>Resumen de ventas</h3>
                      </v-col>
                      <v-col class="d-flex justify-end mr-3">
                        {{ statsventas.total }} vtas
                      </v-col>
                    </v-row>
                    <v-divider class="my-3"></v-divider>
                    <v-row class="d-flex justify-space-between">
                      <v-col>
                        <div class="ml-4">Total ventas</div>
                      </v-col>
                      <v-col class="d-flex justify-end mr-3">
                        $ {{ statsventas.totalmonto | formatCurrency }}</v-col>
                    </v-row>
                    <v-row class="d-flex justify-space-between">
                      <v-col>
                        <div class="ml-4">Total a pagar</div>
                      </v-col>
                      <v-col class="d-flex justify-end mr-3">
                        $ {{ statsventas.totalmonto | formatCurrency }}
                      </v-col>
                    </v-row>
                    <v-row class="d-flex justify-space-between">
                      <v-col>
                        <div class="ml-4">Total pagado</div>
                      </v-col>
                      <v-col class="d-flex justify-end mr-3">
                        $ {{ statsventas.totalpagado | formatCurrency }}
                      </v-col>
                    </v-row>
                    <!-- tarjeta de resumen -->
                    <v-card outlined class="mt-3 pa-4">
                      <v-card-title>Resumen y diferencias</v-card-title>
                      <v-row class="px-3">
                        <v-col> Total caja anterior </v-col>
                        <v-col class="d-flex justify-end">
                          $ {{ 0 | formatCurrency }}
                        </v-col>
                      </v-row>
                      <v-divider class="mx-5 my-2"></v-divider>
                      <v-row class="px-3">
                        <v-col> Total monto ventas </v-col>
                        <v-col class="d-flex justify-end">
                          $ {{ statsventas.totalmonto | formatCurrency }}
                        </v-col>
                      </v-row>
                      <v-divider class="mx-5 my-2"></v-divider>
                      <v-row class="px-3">
                        <v-col> Total monto pagos </v-col>
                        <v-col class="d-flex justify-end">
                          $ {{ statspagos.totalpagado | formatCurrency }}
                        </v-col>
                      </v-row>
                      <v-divider class="mx-5 my-2"></v-divider>
                      <v-row class="px-3">
                        <v-col> Diferencia entre ventas y pagos </v-col>
                        <v-col class="d-flex justify-end">
                          $
                          {{
                            (statsventas.totalmonto - statspagos.totalpagado)
                            | formatCurrency
                          }}
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                  <!-- resumen de caja -->
                  <v-col>
                    <!-- caja -->
                    <v-row class="d-flex justify-space-between">
                      <v-col class="ml-4">
                        <h3>Resumen de caja</h3>
                      </v-col>
                      <v-col class="d-flex justify-end mr-3">
                        {{ statspagos.total }} pago(s)
                      </v-col>
                    </v-row>
                    <v-divider class="my-3"></v-divider>
                    <!-- total efectivo -->
                    <v-row class="d-flex justify-space-between">
                      <v-col>
                        <div class="ml-4">Efectivo</div>
                      </v-col>
                      <v-col class="d-flex justify-end mr-3">$ {{ statspagos.totalefectivo | formatCurrency }}
                      </v-col>
                    </v-row>
                    <!-- total tarjeta -->
                    <v-row class="d-flex justify-space-between">
                      <v-col>
                        <div class="ml-4">Tarjetas</div>
                      </v-col>
                      <v-col class="d-flex justify-end mr-3">
                        $ {{ statspagos.totaltarjeta | formatCurrency }}
                      </v-col>
                    </v-row>
                    <!-- transferecnia -->
                    <v-row class="d-flex justify-space-between">
                      <v-col>
                        <div class="ml-4">Transferencia</div>
                      </v-col>
                      <v-col class="d-flex justify-end mr-3">
                        $ {{ statspagos.totaltransferencia | formatCurrency }}
                      </v-col>
                    </v-row>
                    <!-- cheque al dia-->
                    <v-row class="d-flex justify-space-between">
                      <v-col>
                        <div class="ml-4">Cheque al dia</div>
                      </v-col>
                      <v-col class="d-flex justify-end mr-3">
                        $ {{ statspagos.totalchequedia | formatCurrency }}
                      </v-col>
                    </v-row>
                    <!-- cheque -->
                    <v-row class="d-flex justify-space-between">
                      <v-col>
                        <div class="ml-4">Cheque a fecha</div>
                      </v-col>
                      <v-col class="d-flex justify-end mr-3">
                        $ {{ statspagos.totalchequefecha | formatCurrency }}
                      </v-col>
                    </v-row>
                    <!-- convenio -->
                    <v-row class="d-flex justify-space-between">
                      <v-col>
                        <div class="ml-4">Convenio</div>
                      </v-col>
                      <v-col class="d-flex justify-end mr-3">
                        $ {{ statspagos.totalconvenio | formatCurrency }}
                      </v-col>
                    </v-row>
                    <!-- canje -->
                    <v-row class="d-flex justify-space-between">
                      <v-col>
                        <div class="ml-4">Canje</div>
                      </v-col>
                      <v-col class="d-flex justify-end mr-3">
                        $ {{ statspagos.totalcanje | formatCurrency }}
                      </v-col>
                    </v-row>
                    <!-- nota de credito -->
                    <v-row class="d-flex justify-space-between">
                      <v-col>
                        <div class="ml-4">Nota de crédito</div>
                      </v-col>
                      <v-col class="d-flex justify-end mr-3">
                        $ {{ statspagos.totalndecredito | formatCurrency }}
                      </v-col>
                    </v-row>
                    <!-- deposito -->
                    <v-row class="d-flex justify-space-between">
                      <v-col>
                        <div class="ml-4">Depósito</div>
                      </v-col>
                      <v-col class="d-flex justify-end mr-3">
                        $ {{ statspagos.totaldeposito | formatCurrency }}
                      </v-col>
                    </v-row>
                    <v-row class="d-flex justify-space-between">
                      <v-col> </v-col>
                      <v-col class="justify-end mr-3">
                        <v-divider></v-divider>
                        <div class="d-flex justify-end my-2">
                          <h3 class="mx-4">Total pagos</h3>
                          <h3>
                            $ {{ statspagos.totalpagado | formatCurrency }}
                          </h3>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <div class="pa-3" v-if="cerrarCaja">
                <div class="pa-3">
                  <div class="d-flex justify-center">
                    <h2 class="mb-3 mt-1">Cerrar caja</h2>
                  </div>
                  <v-spacer></v-spacer>
                  <div class="d-flex justify-center">
                    <v-btn class="mx-2 rounded-lg text-capitalize" color="primary" @click="cerrarCaja = false">Ver resumen
                      de caja</v-btn>
                  </div>
                  <v-row class="my-2">
                    <!-- movimientos de la caja -->
                    <v-col>
                      <!-- resumen del caja -->
                      <div>
                        <div class="border solid 1px pa-3">
                          <div class="d-flex justify-space-between">
                            <h3 class="mx-3 mt-2">Fecha caja</h3>
                            <v-spacer></v-spacer>
                            <v-menu ref="menuDateCaja" v-model="menuDateCaja" :close-on-content-click="false"
                              :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="dateCaja" label="Fecha caja" prepend-icon="mdi-calendar" readonly
                                  outlined dense v-bind="attrs" v-on="on"></v-text-field>
                              </template>
                              <v-date-picker locale="ES-es" :max="today" v-model="dateCaja" no-title scrollable>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="menuDateCaja = false">
                                  Atras
                                </v-btn>
                                <v-btn text color="primary" @click="btnFiltrarMovimientoCajaByDate">
                                  Filtrar
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </div>
                          <div class="d-flex justify-space-between">
                            <h3 class="mx-3">Saldo inicial caja</h3>
                            <h3 class="mx-3">
                              $
                              {{
                                cierreDeCajaUltimo.Diferencias | formatCurrency
                              }}
                            </h3>
                          </div>
                          <div class="d-flex justify-space-between">
                            <h3 class="mx-3">Recaudacion del dia</h3>
                            <h3 class="mx-3">
                              $ {{ statspagos.totalpagado | formatCurrency }}
                            </h3>
                          </div>
                          <div class="d-flex justify-space-between">
                            <h3 class="mx-3">A rendir</h3>
                            <h3 class="mx-3">
                              $
                              {{
                                (cierreDeCajaUltimo.Diferencias +
                                  statspagos.totalpagado)
                                | formatCurrency
                              }}
                            </h3>
                          </div>
                        </div>
                      </div>
                      <v-divider class="my-3"></v-divider>

                      <!-- statas y nuevo gasto -->
                      <div class="my-2">
                        <v-row>
                          <v-col>
                            <h3 class="text-center">Total gasto dia</h3>
                            <h1 class="text-center">
                              $
                              {{
                                calculateTotalMovimientosCaja | formatCurrency
                              }}
                            </h1>
                          </v-col>
                          <v-col>
                            <h3 class="text-center">En caja</h3>
                            <h1 class="text-center">
                              $
                              {{
                                (cierreDeCajaUltimo.Diferencias +
                                  statspagos.totalpagado -
                                  calculateTotalMovimientosCaja)
                                | formatCurrency
                              }}
                            </h1>
                          </v-col>
                          <v-col>
                            <v-btn color="primary" class="mt-1 text-capitalize rounded-lg"
                              @click="showCreateGasto = true">Ingresar nuevo gasto</v-btn>
                          </v-col>
                        </v-row>
                      </div>
                      <div v-if="showCreateGasto">
                        <h3 class="text-center">Nuevo gasto</h3>
                        <div>
                          <!-- n doc y plan cuenta -->
                          <div class="d-flex my-2">
                            <v-text-field label="N° doc" type="number" min="0" v-model="selectNDocMovimientoCaja" outlined
                              dense></v-text-field>
                            <v-autocomplete label="Plan de cuenta" v-model="selectPlanCuentaCreate" :items="planCuentas"
                              item-text="Descripcion" item-value="Descripcion" dense outlined
                              class="mx-2"></v-autocomplete>
                          </div>
                          <div class="d-flex">
                            <v-text-field label="Concepto del gasto" v-model="selectConceptoMovimientoCaja" dense
                              outlined></v-text-field>
                            <v-text-field label="Monto" v-model="selectMontoMovimientoCaja" dense type="number"
                              class="ml-2" min="0" outlined></v-text-field>
                            <v-btn class="mx-2 text-capitalize rounded-lg" color="primary"
                              :loading="activeLoadingCreateMovimientoCaja" @click="btnCreateMovimientoCaja">Grabar
                              gasto</v-btn>
                          </div>
                        </div>
                      </div>
                      <!-- movimientos de cjaa -->
                      <div class="mt-5">
                        <h3 class="text-center my-3">
                          Gastos del día
                          {{ setDateStringTimeZoneShort(dateCaja) }}
                        </h3>
                        <v-data-table locale="ES-ES" :headers="headersMovimientosCaja" :items="movimientosCaja"
                          :loading="loadingMovimientosCaja" items-per-page="5">
                          <template v-slot:item.Monto="{ item }">
                            <div>
                              <div>$ {{ item.Monto | formatCurrency }}</div>
                            </div>
                          </template>
                          <template v-slot:item.action="{ item }">
                            <div>
                              <v-btn icon><v-icon>mdi-delete-outline</v-icon></v-btn>
                            </div>
                          </template>
                        </v-data-table>
                      </div>
                    </v-col>
                    <v-divider vertical></v-divider>
                    <v-col>
                      <v-tabs v-model="tabcierrecaja">
                        <v-tab key="cajacierre">Cierres de caja</v-tab>
                        <v-tab key="cajagastofecha">
                          Busqueda de gastos por fecha</v-tab>
                      </v-tabs>
                      <v-tabs-items v-model="tabcierrecaja">
                        <v-tab-item key="cajacierre">
                          <div class="my-2">
                            <div class="mt-5">
                              <h3 class="text-center">
                                Realizar cierre de caja
                              </h3>
                            </div>
                            <!-- fecha y grabar ciere -->
                            <div class="d-flex justify-end my-4">
                              <v-text-field v-model="dateCaja" label="Fecha cierre de caja" prepend-icon="mdi-calendar"
                                readonly outlined dense v-bind="attrs" v-on="on"></v-text-field>
                              <v-spacer></v-spacer>
                              <v-btn class="mx-2" :loading="activeLoadingCreateCierreCaja" color="primary"
                                @click="btnCrearCierreCaja">Grabar cierre</v-btn>
                            </div>
                            <v-snackbar color primary v-model="successCreateCierreCaja">Cierre de caja creado</v-snackbar>
                            <!-- stats caja  -->
                            <v-row>
                              <v-col>
                                <h3 class="text-center">Saldo final</h3>
                                <h1 class="text-center">
                                  $
                                  {{
                                    cierreDeCajaUltimo.Diferencias
                                    | formatCurrency
                                  }}
                                </h1>
                              </v-col>
                              <v-col>
                                <h3 class="text-center">Recaudación</h3>
                                <h1 class="text-center">
                                  $
                                  {{ statspagos.totalpagado | formatCurrency }}
                                </h1>
                              </v-col>
                              <v-col>
                                <h3 class="text-center">Saldo inicial</h3>
                                <h1 class="text-center">
                                  $
                                  {{
                                    cierreDeCajaUltimo.Totalventa
                                    | formatCurrency
                                  }}
                                </h1>
                              </v-col>
                              <v-col>
                                <h3 class="text-center">Retiros</h3>
                                <h1 class="text-center">
                                  ${{
                                    calculateTotalMovimientosCaja
                                    | formatCurrency
                                  }}
                                </h1>
                              </v-col>
                            </v-row>
                            <v-divider class="my-3"></v-divider>
                            <h3 class="text-center my-2">Cajas cerradas</h3>
                            <v-data-table locale="ES-ES" :headers="headersCierresCaja" :items="cierresCaja">
                              <template v-slot:item.Fecha="{ item }">
                                <div>
                                  <div>
                                    {{ setDateStringTimeZoneShort(item.Fecha) }}
                                  </div>
                                </div>
                              </template>
                              <template v-slot:item.Totalventa="{ item }">
                                <div>
                                  <div>
                                    $ {{ item.Totalventa | formatCurrency }}
                                  </div>
                                </div>
                              </template>
                              <template v-slot:item.Diferencias="{ item }">
                                <div>
                                  <div>
                                    $ {{ item.Diferencias | formatCurrency }}
                                  </div>
                                </div>
                              </template>
                              <template v-slot:item.Totalingresos="{ item }">
                                <div>
                                  <div>
                                    $ {{ item.Totalingresos | formatCurrency }}
                                  </div>
                                </div>
                              </template>
                              <!-- <template v-slot:item.action="{ item }">
                                <div class="actions">
                                  <v-btn icon
                                    ><v-icon>mdi-delete-outline</v-icon></v-btn
                                  >
                                </div>
                              </template> -->
                            </v-data-table>
                          </div>
                        </v-tab-item>
                        <v-tab-item key="cajagastofecha">
                          <h3 class="text-center my-2">
                            Búsqueda de gastos por fecha
                          </h3>
                          <!-- filtro de fecha y buscar -->
                          <div class="d-flex my-2">
                            <v-autocomplete label="Plan de cuenta" v-model="selectPlanCuentaCreate" :items="planCuentas"
                              item-text="Descripcion" item-value="Descripcion" dense outlined
                              class="mx-2"></v-autocomplete>
                            <v-menu v-model="menuStartMovimientosCaja" :close-on-content-click="false" :nudge-right="40"
                              transition="scale-transition" offset-y min-width="auto">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="dateStartMovimientosCaja" outlined label="Desde" dense
                                  prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                              </template>
                              <v-date-picker locale="ES-es" v-model="dateStartMovimientosCaja"
                                @input="menuStartMovimientosCaja = false"></v-date-picker>
                            </v-menu>
                            <div>
                              <div v-if="dateStartMovimientosCaja">
                                <v-menu v-model="menuEndMovimientosCaja" :close-on-content-click="false" :nudge-right="40"
                                  transition="scale-transition" offset-y min-width="auto">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="dateEndMovimientosCaja" outlined label="Hasta" dense
                                      prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                  </template>
                                  <v-date-picker locale="ES-es" :min="dateStartMovimientosCaja"
                                    v-model="dateEndMovimientosCaja"
                                    @input="menuEndMovimientosCaja = false"></v-date-picker>
                                </v-menu>
                              </div>
                              <div v-else class="mt-1 mx-1">
                                Seleccione fecha de inicio
                              </div>
                            </div>

                            <v-btn class="mx-2" :disabled="!dateStartMovimientosCaja ||
                              !dateEndMovimientosCaja
                              " color="primary" @click="btnFiltrarMovimientosCajaByRangeDate">Buscar</v-btn>
                          </div>
                          <!-- total -->
                          <div class="my-2">
                            <v-row>
                              <v-col>
                                <h3 class="text-center">
                                  Cantidad movimientos
                                </h3>
                                <h1 class="text-center">
                                  {{ movimientosCajaRange.length }}
                                </h1>
                              </v-col>
                              <v-col>
                                <h3 class="text-center">Total movimientos</h3>
                                <h1 class="text-center">
                                  $
                                  {{
                                    calculateTotalMovimientosCajaRange
                                    | formatCurrency
                                  }}
                                </h1>
                              </v-col>
                            </v-row>
                          </div>
                          <!-- ftaba con busqeuda -->
                          <v-data-table locale="ES-ES" :headers="headersMovimientosCajaRange"
                            :items="movimientosCajaRange" :loading="loadingMovimientosCajaRange">
                            <template v-slot:item.Fecha="{ item }">
                              <div>
                                <div>
                                  {{ setDateStringTimeZoneShort(item.Fecha) }}
                                </div>
                              </div>
                            </template>
                            <template v-slot:item.Monto="{ item }">
                              <div>
                                <div>$ {{ item.Monto | formatCurrency }}</div>
                              </div>
                            </template>
                            <template v-slot:item.action="{ item }">
                              <div>
                                <v-btn icon><v-icon>mdi-delete-outline</v-icon></v-btn>
                              </div>
                            </template>
                          </v-data-table>
                        </v-tab-item>
                      </v-tabs-items>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-card>
          </v-row>
          <!-- contenido ver cuentas corriente -->
          <v-row v-if="opcionVerCtaCte" class="my-3">
            <v-card width="100%" elevation="0" class="pa-3">
              <!-- titulo -->
              <div class="d-flex justify-center mb-3">
                <h2>Ver Cuenta Corriente</h2>
              </div>
              <!-- cliente -->
              <div class="ml-4 mt-">
                <div class="d-flex my-2">
                  <v-icon>mdi-account-circle-outline</v-icon>
                  <h3 class="mt-1 ml-2">Cliente</h3>
                  <v-chip class="mx-2 capitalize-text" color="primary" outlined v-if="selectCliente.Id">{{
                    selectCliente.Nombre }} /
                    {{ selectCliente.Amo }}</v-chip>
                  <v-chip class="ml-2" color="primary" @click="activeSearchCliente = true">Seleccionar</v-chip>
                </div>
                <!-- seleccionar cliente -->
                <v-dialog v-model="activeSearchCliente" transition="dialog-bottom-transition" class="rounded-xl"
                  max-width="600">
                  <template v-slot:default="">
                    <v-card width="100%" class="pa-3 rounded-xl">
                      <v-card-title class="mt-4 ml-10">
                        <h2>Buscar cliente</h2>
                      </v-card-title>
                      <div class="pa-3">
                        <div class="d-flex">
                          <v-text-field v-model="search" append-icon="mdi-magnify"
                            placeholder="Para buscar por nombre, rut o teléfono, debe presionar enter o presionar el boton buscar al final de esté campo"
                            @keyup.enter="btnSearchCliente" hide-details class="mb-1"></v-text-field>
                          <v-btn color="primary" outlined class="mt-2 ml-2" @click="btnSearchCliente">Buscar</v-btn>
                        </div>
                        <v-data-table :headers="headersClientes" :items="clientes" :search="search"
                          :loading="loadingClientes" loading-text="Buscando... Espere" class="mt-2" dense :footer-props="{
                            'items-per-page-text': 'Filas por página',
                          }">
                          <template v-slot:item.action="{ item }">
                            <div class="actions pa-1">
                              <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn color="primary" icon v-bind="attrs" v-on="on" class="text-capitalize rounded-lg"
                                    @click="btnSelectClienteCtaCte(item)"><v-icon>mdi-check-circle-outline</v-icon>
                                  </v-btn>
                                </template>
                                <span>Seleccionar</span>
                              </v-tooltip>
                            </div>
                          </template>
                          <template v-slot:no-data>
                            <v-alert :value="true">
                              No hay resultados disponibles
                            </v-alert>
                          </template>
                        </v-data-table>
                      </div>
                    </v-card>
                  </template>
                </v-dialog>
              </div>
              <!-- barra de estadísticas pago (vista escritorio) -->
              <v-card class="white my-4 pa-3 d-none d-sm-none d-md-flex d-lg-flex d-xl-flex" elevation="1">
                <v-row class="justify-center">
                  <!-- cantidad pagos -->
                  <v-col>
                    <p class="text-center">
                      <strong>Cantidad ventas</strong>
                    </p>
                    <h1 class="text-center h3">
                      {{ statsventascliente.total }}
                    </h1>
                  </v-col>
                  <!--  total ventas $                -->
                  <v-col>
                    <p class="text-center">
                      <strong>Total ventas</strong>
                    </p>
                    <h1 class="text-center h3">
                      $ {{ statsventascliente.totalmonto | formatCurrency }}
                    </h1>
                  </v-col>
                  <!-- cantidad pagos -->
                  <v-col>
                    <p class="text-center">
                      <strong>Cantidad pagos</strong>
                    </p>
                    <h1 class="text-center h3">
                      {{ statspagoscliente.total }}
                    </h1>
                  </v-col>
                  <!-- monto pagos -->
                  <v-col>
                    <p class="text-center">
                      <strong>Monto pagado</strong>
                    </p>
                    <h1 class="text-center h3">
                      $ {{ statspagoscliente.totalpagado | formatCurrency }}
                    </h1>
                  </v-col>
                  <!-- diferencia a pagar -->
                  <v-col>
                    <p class="text-center">
                      <strong>Diferencia a pagar</strong>
                    </p>
                    <h1 class="text-center h3">
                      $
                      {{
                        statsventascliente.totalmonto -
                        statspagoscliente.totalpagado
                      }}
                    </h1>
                  </v-col>
                </v-row>
              </v-card>
              <!-- barra de estadísticas pago (vista móvil) -->
              <v-card class="white my-4 pa-3 d-flex d-sm-flex d-md-flex d-lg-none d-xl-none" elevation="1">
                <v-col class="pa-3">
                  <!-- cantidad pagos -->
                  <v-row class="d-flex justify-space-between">
                    <p>
                      <strong>Cantidad ventas:</strong>
                    </p>
                    <h1>
                      {{ statsventascliente.total }}
                    </h1>
                  </v-row>
                  <!--  total ventas $                -->
                  <v-row class="d-flex justify-space-between">
                    <p>
                      <strong>Total ventas:</strong>
                    </p>
                    <h1>
                      $ {{ statsventascliente.totalmonto | formatCurrency }}
                    </h1>
                  </v-row>
                  <!-- cantidad pagos -->
                  <v-row class="d-flex justify-space-between">
                    <p>
                      <strong>Cantidad pagos:</strong>
                    </p>
                    <h1>
                      {{ statspagoscliente.total }}
                    </h1>
                  </v-row>
                  <!-- monto pagos -->
                  <v-row class="d-flex justify-space-between">
                    <p>
                      <strong>Monto pagado:</strong>
                    </p>
                    <h1>
                      $ {{ statspagoscliente.totalpagado | formatCurrency }}
                    </h1>
                  </v-row>
                  <!-- diferencia a pagar -->
                  <v-row class="d-flex justify-space-between">
                    <p>
                      <strong>Diferencia a pagar:</strong>
                    </p>
                    <h1>
                      $
                      {{
                        statsventascliente.totalmonto -
                        statspagoscliente.totalpagado
                      }}
                    </h1>
                  </v-row>
                </v-col>
              </v-card>
              <!-- barra de vistas y descarga -->
              <div :class="isMobile ? 'd-flex flex-column' : 'd-flex'">
                <v-tabs v-model="tabctacliente" class="ml-4">
                  <v-tab class="text-capitalize">Ventas</v-tab>
                  <v-tab class="text-capitalize">Pagos</v-tab>
                </v-tabs>
                <v-spacer></v-spacer>
                <div :class="isMobile ? 'd-flex flex-column mt-4' : 'd-flex'">
                  <GeneratePdfCtaCteVue />
                  <v-btn color="primary" outlined :class="isMobile ? 'mt-2' : 'mx-3'">
                    <div class="d-flex">
                      <v-icon>mdi-microsoft-excel</v-icon>
                      <div class="mt-1 ml-1 text-capitalize rounded-lg">
                        Descargar excel
                      </div>
                    </div>
                  </v-btn>
                </div>
              </div>
              <!-- ventas y pagos -->
              <v-tabs-items v-model="tabctacliente">
                <v-tab-item key="ventas">
                  <v-card flat>
                    <v-card-text>
                      <h3 class="text-center">Ventas</h3>
                      <div class="pa-3">
                        <div>
                          <div class="pa-3">
                            <v-text-field v-model="searchVtasserCliente" append-icon="mdi-magnify"
                              placeholder="Busque por cualquier término" single-line outlined dense class="mb-2 my-2"
                              hide-details></v-text-field>
                            <v-data-table locale="ES-ES" items-per-page="5" :headers="headersVtasserCliente"
                              :loading="loadingVtasserCliente" :items="vtasserCliente" :search="searchVtasserCliente">
                              <template v-slot:item.Precio="{ item }">
                                <div class="">
                                  <div>
                                    $ {{ item.Precio | formatCurrency }}
                                  </div>
                                </div>
                              </template>
                              <template v-slot:item.Des="{ item }">
                                <div class="">
                                  <div>$ {{ item.Des | formatCurrency }}</div>
                                </div>
                              </template>
                              <template v-slot:item.Total="{ item }">
                                <div class="">
                                  <div>$ {{ item.Total | formatCurrency }}</div>
                                </div>
                              </template>
                              <template v-slot:item.Montopagado="{ item }">
                                <div class="">
                                  <div>
                                    $ {{ item.Montopagado | formatCurrency }}
                                  </div>
                                </div>
                              </template>
                              <template v-slot:no-data>
                                <v-alert :value="true">
                                  No hay resultados disponibles
                                </v-alert>
                              </template>
                            </v-data-table>
                          </div>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item key="pagos">
                  <v-card width="100%">
                    <v-card-text>
                      <h3 class="text-center">Pagos</h3>
                      <div class="pa-3">
                        <div>
                          <div class="pa-3">
                            <v-text-field v-model="searchVtasserCliente" append-icon="mdi-magnify"
                              placeholder="Busque por cualquier termino" single-line dense outlined class="mb-2 my-2"
                              hide-details></v-text-field>
                            <v-data-table locale="ES-ES" items-per-page="5" :headers="headersPagosVtasserCliente"
                              :loading="loadingPagosVtasserCliente" :items="pagosVtasserCliente"
                              :search="searchVtasserCliente">
                              <template v-slot:item.Monto="{ item }">
                                <div class="actions">
                                  <div>$ {{ item.Monto | formatCurrency }}</div>
                                </div>
                              </template>
                              <template v-slot:no-data>
                                <v-alert :value="true">
                                  No hay resultados disponibles
                                </v-alert>
                              </template>
                            </v-data-table>
                          </div>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-row>
          <!-- contenido generar guia y documentos -->
          <v-row v-if="opcionEditarDocs" class="pa-0 ma-0">
            <v-card elevation="0" width="100%">
              <v-row class="pa-0 ma-0">
                <!-- opciones de otros documentos -->
                <v-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <div :class="isMobile ? 'd-flex flex-column' : 'd-flex'">
                    <v-tabs v-model="tabTipoDocBorrador">
                      <v-tab key="otros" class="text-capitalize">Otros documentos</v-tab>
                      <v-tab key="guia" class="text-capitalize">Guía de despacho</v-tab>
                      <v-tab key="nota" class="text-capitalize">N. de Crédito / Débito</v-tab>
                    </v-tabs>
                  </div>
                  <!-- contenido  -->
                  <v-tabs-items v-model="tabTipoDocBorrador">
                    <v-tab-item key="otros">
                      <div class="mt-5">
                        <div v-if="!activeShowSearchBorrador" class="d-flex justify-center">
                          <h3 class="text-center my-3">Otros documentos</h3>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn class="ml-3 mt-1" icon v-bind="attrs" v-on="on"
                                @click="activeShowSearchBorrador = true">
                                <v-icon>mdi-magnify</v-icon>
                              </v-btn>
                            </template>
                            <span>Buscar otros documentos creados</span>
                          </v-tooltip>
                        </div>
                        <!-- buscar -->
                        <div v-if="activeShowSearchBorrador">
                          <div class="d-flex justify-center">
                            <h3 class="text-center my-3">
                              Buscar documento existente
                            </h3>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn class="ml-3 mt-1" icon v-bind="attrs" v-on="on"
                                  @click="activeShowSearchBorrador = false">
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </template>
                              <span>Cerrar búsqueda</span>
                            </v-tooltip>
                          </div>
                          <!-- busqueda de dcoumetno -->
                          <div class="d-flex">
                            <v-autocomplete class="ml-2" dense label="Seleccione documento" outlined
                              v-model="selectTipoDocBorrador" item-text="Concepto" :items="tablaimptos"></v-autocomplete>
                            <v-text-field outlined dense class="mx-2" v-model="selectNdocSearchBorrador" type="number"
                              min="0" label="N° doc"></v-text-field>
                            <v-btn color="primary" class="ml-2" :loading="activeLoadingSearchVtaserBorrador"
                              @click="btnSearchVtaserBorrador">Buscar</v-btn>
                          </div>
                          <!-- tarjeta resultado -->
                          <div v-if="vtaserBorradorSearch.cliente">
                            <v-row>
                              <v-col>
                                <h3>Cliente</h3>
                                <h2>
                                  {{ vtaserBorradorSearch.cliente }}
                                </h2>
                              </v-col>
                              <v-col>
                                <h3>Ficha</h3>
                                <h2>{{ vtaserBorradorSearch.fichan }}</h2>
                              </v-col>
                              <v-col>
                                <h3>Total</h3>
                                <h2>
                                  $
                                  {{
                                    vtaserBorradorSearch.total | formatCurrency
                                  }}
                                </h2>
                              </v-col>
                              <v-col>
                                <h3>Pagado</h3>
                                <h2>
                                  $
                                  {{
                                    vtaserBorradorSearch.montopagado
                                    | formatCurrency
                                  }}
                                </h2>
                              </v-col>
                              <v-col v-if="vtaserBorradorSearch.montopagado <
                                  vtaserBorradorSearch.total
                                  "><v-btn color="primary" @click="btnPagarVtaBorrador">Pagar</v-btn></v-col>
                            </v-row>
                          </div>
                        </div>
                        <div v-else>
                          <v-autocomplete class="ml-2" dense label="Seleccione documento" outlined
                            v-model="selectTipoDocBorrador" item-text="Concepto" :items="tablaimptos"></v-autocomplete>
                          <v-checkbox class="pa-0 ma-0 mt-3" v-model="activeShowPagoBorrador"
                            label="Este documento tiene asociado un pago"></v-checkbox>
                        </div>
                      </div>
                    </v-tab-item>
                    <!-- guia de despacho -->
                    <v-tab-item key="guia">
                      <div v-if="!activeShowSearchGuia" class="d-flex justify-center mt-3">
                        <h3 class="text-center my-3">Guía de despacho</h3>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="ml-3 mt-1" icon v-bind="attrs" v-on="on" @click="btnActiveSearchGuia">
                              <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                          </template>
                          <span>Buscar guias</span>
                        </v-tooltip>
                      </div>
                      <div v-else>
                        <div class="d-flex justify-center my-3">
                          <h3 class="text-center my-3">
                            Buscar guías existentes
                          </h3>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn class="ml-3 mt-1" icon v-bind="attrs" v-on="on" @click="
                                (activeShowSearchBorrador = false),
                                (activeShowSearchGuia = false)
                                ">
                                <v-icon>mdi-close</v-icon>
                              </v-btn>
                            </template>
                            <span>Cerrar búsqueda</span>
                          </v-tooltip>
                        </div>
                      </div>
                      <div v-if="activeShowSearchGuia">
                        <div>
                          <!-- Búsqueda de guías -->
                          <h4 class="my-3">Buscar guías generadas</h4>
                          <div class="d-flex">
                            <v-menu v-if="tipoSearchGuia == 'date'" v-model="menuFechaBusquedaGuia"
                              :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
                              min-width="auto">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="selectFechaBusquedaGuia" label="Fecha" outlined dense class="mx-2"
                                  prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                              </template>
                              <v-date-picker v-model="selectFechaBusquedaGuia"
                                @input="menuFechaBusquedaGuia = false"></v-date-picker>
                            </v-menu>
                            <v-text-field dense v-if="tipoSearchGuia == 'ndoc'" outlined
                              v-model="selectNumerodocGuiaSearch" class="mx-2" label="N° de Guía"
                              type="number"></v-text-field>
                            <v-btn color="primary" class="rounded-lg text-capitalize"
                              @click="btnBuscarGuia">Buscar</v-btn>
                          </div>
                          <!-- filtros -->
                          <div class="d-flex justify-center">
                            <h3 class="mx-2 mt-5">Tipo de filtro</h3>
                            <v-radio-group v-model="tipoSearchGuia" mandatory row>
                              <v-radio label="Fecha" value="date"></v-radio>
                              <v-radio label="N°" value="ndoc"></v-radio>
                            </v-radio-group>
                          </div>
                        </div>
                        <v-divider></v-divider>
                        <v-data-table :loading="activetLoadingSearchVtaserByDoc" :headers="headersGuiasSearch"
                          :items="selectSearchGuiasBuscadas">
                          <template v-slot:item.Total="{ item }">
                            <div class="actions">
                              <div>$ {{ item.Total | formatCurrency }}</div>
                            </div>
                          </template>
                          <template v-slot:item.action="{ item }">
                            <div class="actions">
                              <v-btn icon @click="btnPrintVtaserByDoc(item)"><v-icon>mdi-printer-outline</v-icon></v-btn>
                            </div>
                          </template>
                        </v-data-table>
                      </div>
                      <div v-else>
                        <div class="d-flex mt-4">
                          <v-autocomplete class="mr-2" outlined dense :items="motivosGuia" v-model="selectTipoMotivoGuia"
                            label="Tipo de guía"></v-autocomplete>
                          <v-text-field outlined v-model="selectPatenteGuia" dense :maxlength="6"
                            label="Patente"></v-text-field>
                        </div>
                        <div>
                          <v-text-field v-model="selectDireccionGuia" label="Dirección" outlined dense></v-text-field>
                          <div class="d-flex">
                            <v-text-field v-model="selectComunaGuia" class="mr-2" label="Comuna" outlined
                              dense></v-text-field>
                            <v-text-field v-model="selectCiudadGuia" label="Ciudad" outlined dense></v-text-field>
                          </div>
                        </div>
                      </div>
                    </v-tab-item>
                    <!-- notas de credito debito -->
                    <v-tab-item key="nota">
                      <div v-if="!activeShowSearchNota" class="d-flex justify-center mt-3">
                        <h3 class="text-center my-3">
                          Nota de crédito / Débito
                        </h3>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="ml-3 mt-1" icon v-bind="attrs" v-on="on" @click="btnActiveSearchNota">
                              <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                          </template>
                          <span>Buscar notas</span>
                        </v-tooltip>
                      </div>
                      <div v-else>
                        <div class="d-flex justify-center my-3">
                          <h3 class="text-center my-3">
                            Buscar notas existentes
                          </h3>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn class="ml-3 mt-1" icon v-bind="attrs" v-on="on" @click="
                                (activeShowSearchBorrador = false),
                                (activeShowSearchNota = false)
                                ">
                                <v-icon>mdi-close</v-icon>
                              </v-btn>
                            </template>
                            <span>Cerrar busqueda</span>
                          </v-tooltip>
                        </div>
                      </div>
                      <div v-if="!activeShowSearchNota">
                        <!-- tipo de nota y tipo impuesto -->
                        <div class="d-flex mt-4">
                          <v-select :items="tipoNotaBorrador" v-model="selectTipoNotaBorrador" class="mr-2"
                            label="Tipo de nota" return-object item-text="Concepto" dense outlined></v-select>
                          <v-select :items="tipoImpuestoNotaBorrador" label="Impuesto" v-model="selectTipoImptoNota"
                            outlined dense></v-select>
                        </div>
                        <!-- crear nueva referencia -->
                        <div>
                          <h4 class="my-3">Referencia del documento</h4>
                          <!-- razon y tipo doc ref -->
                          <div class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row">
                            <!-- razon de la anulacion -->
                            <v-select dense outlined label="Razón de anulación" :items="tipoRefNota"
                              v-model="selectRazonReferenciaNota" item-text="tipo" return-object class="mr-2">
                            </v-select>
                            <v-autocomplete dense outlined v-if="selectTipoImptoNota" :items="settablaimptosSii"
                              item-text="Concepto" return-object v-model="selectTipoImpuestoNotaBorrador"
                              label="Tipo documento referencia" class="mr-2">
                            </v-autocomplete>
                            <div v-else class="mb-3">
                              Seleccione el tipo de impuesto primero, para
                              <br />
                              luego seleccionar el tipo de documento de
                              referencia.
                            </div>
                          </div>
                          <!-- fecha  -->
                          <div class="d-flex">
                            <v-text-field dense v-model="selectNumdocrefNota" outlined type="number" class="mr-2" :min="0"
                              label="N° doc"></v-text-field>
                            <v-btn color="primary" class="text-capitalize rounded-lg"
                              :loading="activeLoadingNotaValidarDte" @click="btnCrearNuevaRerefenciaNota">Crear</v-btn>
                          </div>
                        </div>
                        <div>
                          <h4>Referencias asociadas al documento</h4>
                        </div>
                        <div v-if="selectReferenciaNotaBorrador.length > 0">
                          <v-row class="my-2">
                            <v-col>N° doc Referencia</v-col>
                            <v-col>Tipo documento</v-col>
                            <v-col>Razon</v-col>
                            <v-col>Monto</v-col>
                          </v-row>
                          <v-divider class="my-1"></v-divider>
                          <v-row class="my-1" v-for="(
                              referencia, index
                            ) in selectReferenciaNotaBorrador" :key="index">
                            <v-col>{{ referencia.Folioref }}</v-col>
                            <v-col>{{ referencia.Tipodocumentoref }}</v-col>
                            <v-col>{{ referencia.Razonref }}</v-col>
                            <v-col>{{
                              referencia.Total | formatCurrency
                            }}</v-col>
                          </v-row>
                        </div>
                        <div v-else>Sin Referencias</div>
                      </div>
                      <div v-else>
                        <div>
                          <!-- Búsqueda de notas generadas-->
                          <h4 class="mt-2">Buscar notas generadas</h4>
                          <div class="d-flex">
                            <v-menu v-if="tipoSearchNota == 'date'" v-model="menuFechaBusquedaNota"
                              :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
                              min-width="auto">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="selectFechaBusquedaNota" label="Fecha de la nota" outlined dense
                                  class="mx-2" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                  v-on="on"></v-text-field>
                              </template>
                              <v-date-picker v-model="selectFechaBusquedaNota"
                                @input="menuFechaBusquedaNota = false"></v-date-picker>
                            </v-menu>
                            <v-text-field dense v-if="tipoSearchNota == 'ndoc'" outlined
                              v-model="selectNumerodocNotaSearch" class="mx-2" label="n° de nota"
                              type="number"></v-text-field>
                            <v-btn color="primary" @click="btnBuscarNota">Buscar</v-btn>
                          </div>
                          <!-- filtros -->
                          <div class="d-flex justify-center">
                            <h3 class="mx-2 mt-5">Tipo de filtro</h3>

                            <v-select outlined dense v-model="selectSearchTipoNota" :items="tiposNotaSearch"
                              class="mt-3 mx-2"></v-select>
                            <v-radio-group v-model="tipoSearchNota" mandatory row>
                              <v-radio label="Fecha" value="date"></v-radio>
                              <v-radio label="N°" value="ndoc"></v-radio>
                            </v-radio-group>
                            <v-spacer></v-spacer>
                          </div>
                        </div>
                        <v-divider></v-divider>
                        <v-data-table :loading="activetLoadingSearchVtaserByDoc" :headers="headersGuiasSearch"
                          :items="selectSearchNotasBuscadas">
                          <template v-slot:item.Total="{ item }">
                            <div class="actions">
                              <div>$ {{ item.Total | formatCurrency }}</div>
                            </div>
                          </template>
                          <template v-slot:item.action="{ item }">
                            <div class="actions">
                              <v-btn icon @click="btnPrintVtaserByDoc(item)"><v-icon>mdi-printer-outline</v-icon></v-btn>
                            </div>
                          </template>
                        </v-data-table>
                      </div>
                    </v-tab-item>
                  </v-tabs-items>
                  <!-- ajutes de venta  -->
                  <div v-if="!activeShowSearchBorrador" class="colventacenter">
                    <!-- cliente -->
                    <h3 class="py-3">Datos para el documento</h3>
                    <!-- vendedor -->
                    <div>
                      <!--  -->
                      <v-autocomplete dense outlined :items="doctors" v-model="selectVendedor" item-text="Nombre"
                        item-value="Rut" label="Seleccione vendedor"></v-autocomplete>
                    </div>
                    <!-- cliente y forma -->
                    <div class="">
                      <!-- cliente -->
                      <div class="">
                        <div class="d-flex my-2">
                          <v-icon>mdi-account-circle-outline</v-icon>
                          <h3 class="mt-1 ml-2">Cliente</h3>
                          <v-chip class="mx-2" color="primary" outlined v-if="selectCliente.Id">{{ selectCliente.Nombre }}
                            /
                            {{ selectCliente.Amo }}</v-chip>
                          <v-chip class="ml-2" color="primary" @click="activeSearchCliente = true">Seleccionar</v-chip>
                        </div>
                        <!-- select cliente -->
                        <v-dialog v-model="activeSearchCliente" transition="dialog-bottom-transition" class="rounded-xl"
                          max-width="600">
                          <template v-slot:default="">
                            <v-card width="100%" class="pa-3 rounded-xl">
                              <v-card-title class="mt-4 ml-10">
                                <h2>Buscar cliente</h2>
                              </v-card-title>
                              <div class="pa-3">
                                <div class="d-flex">
                                  <v-text-field v-model="search" append-icon="mdi-magnify"
                                    placeholder="Para buscar por nombre, rut o teléfono" @keyup.enter="btnSearchCliente"
                                    hide-details class="mb-1"></v-text-field>
                                  <v-btn color="primary" outlined class="mt-2 ml-2 text-capitalize"
                                    @click="btnSearchCliente">Buscar</v-btn>
                                </div>
                                <v-data-table :headers="headersClientes" :items="clientes" :search="search"
                                  :loading="loadingClientes" loading-text="Buscando... Espere" class="mt-2" dense
                                  :footer-props="{
                                    'items-per-page-text': 'Filas por página',
                                  }">
                                  <template v-slot:item.action="{ item }">
                                    <div class="actions pa-1">
                                      <v-tooltip left>
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-btn color="primary" icon v-bind="attrs" v-on="on"
                                            class="text-capitalize rounded-lg"
                                            @click="btnSelectCliente(item)"><v-icon>mdi-check-circle-outline</v-icon>
                                          </v-btn>
                                        </template>
                                        <span>Seleccionar</span>
                                      </v-tooltip>
                                    </div>
                                  </template>
                                  <template v-slot:no-data>
                                    <v-alert :value="true">
                                      No hay resultados disponibles
                                    </v-alert>
                                  </template>
                                </v-data-table>
                              </div>
                            </v-card>
                          </template>
                        </v-dialog>
                      </div>
                    </div>
                    <!-- pago -->
                    <div v-if="tabTipoDocBorrador == 0">
                      <div v-if="activeShowPagoBorrador">
                        <div class="d-flex my-2">
                          <v-icon size="30">mdi-cash</v-icon>
                          <h3 class="mt-1 ml-2">Pago</h3>
                        </div>
                        <!-- tipo pago -->
                        <div class="d-flex">
                          <v-autocomplete class="my-2" label="Forma de pago" outlined dense :items="formaPago"
                            v-model="selectFormaPagoBorrador"></v-autocomplete>
                          <v-text-field v-if="selectFormaPagoBorrador == 'Tarjeta D/C' ||
                            selectFormaPagoBorrador == 'Cheque' ||
                            selectFormaPagoBorrador == 'CDC' ||
                            selectFormaPagoBorrador == 'Transferencia' ||
                            selectFormaPagoBorrador == 'Deposito' ||
                            selectFormaPagoBorrador == 'N.de Credito' ||
                            selectFormaPagoBorrador == 'Canje'
                            " class="payment-references" outlined type="number" dense v-model="selectRegBorrador"
                            label="Referencia del pago"></v-text-field>
                        </div>
                        <!-- banco y vencimiento -->
                        <div class="d-flex">
                          <v-autocomplete class="ml-2" v-if="selectFormaPagoBorrador == 'Cheque'" label="Banco"
                            :items="bancos" v-model="selectBancoChequeBorrador" dense outlined></v-autocomplete>
                          <v-menu v-if="selectFormaPagoBorrador == 'Cheque'" v-model="menuFechaChequeBorrador"
                            :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
                            min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field v-model="selectFechaChequeBorrador" label="Fecha cheque"
                                prepend-icon="mdi-calendar" outlined dense :class="isMobile ? '' : 'ml-2'" readonly
                                v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker locale="ES-es" :min="today" v-model="selectFechaChequeBorrador"
                              @input="menuFechaChequeBorrador = false"></v-date-picker>
                          </v-menu>
                        </div>
                        <!-- detalle pago -->
                        <div>
                          <v-card class="pa-2" elevation="0">
                            <div class="d-flex my-3">
                              <h3 class="mt-3 mx-2">Monto a pagar</h3>
                              <v-text-field outlined min="0" v-model="selectMontoPagadoBorrador"
                                type="number"></v-text-field>
                              <v-btn @click="btnPagarBorrador" :disabled="disabledBtnPayBorrador"
                                :loading="activeLoadingPagarBorrador" class="mx-3 mt-2" color="primary">Pagar</v-btn>
                            </div>
                            <div class="border solid 1px pa-3">
                              <div class="d-flex justify-space-between">
                                <h3 class="mx-3">Total a pagar</h3>
                                <h3 class="mx-3">
                                  $
                                  {{ calculateTotalBorrador | formatCurrency }}
                                </h3>
                              </div>
                              <div class="d-flex justify-space-between">
                                <h3 class="mx-3">Pagado</h3>
                                <h3 class="mx-3">
                                  $
                                  {{
                                    calculateTotalPagadoBorrador
                                    | formatCurrency
                                  }}
                                </h3>
                              </div>
                              <div class="d-flex justify-space-between">
                                <h3 class="mx-3">Pendiente</h3>
                                <h3 class="mx-3">
                                  $
                                  {{
                                    (calculateTotalBorrador -
                                      calculateTotalPagadoBorrador)
                                    | formatCurrency
                                  }}
                                </h3>
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </div>
                    </div>

                    <!-- btn crear documento -->
                    <div v-if="!activeShowPagoBorrador" :class="isMobile
                        ? 'd-flex justify-center'
                        : 'd-flex justify-end'
                      ">
                      <v-btn :loading="activeLoadingCreateDocumento" @click="btnCrearDocumentoBorrador"
                        class="primary text-capitalize rounded-lg mt-2">Crear documento</v-btn>
                    </div>
                  </div>
                  <!-- Generacion y descargar de documentos -->
                  <v-dialog v-model="activeGenerarDocumentoBorradorDescargar" persistent max-width="500">
                    <v-card class="pa-6 rounded-xl">
                      <v-card-title class="text-h5">
                        Documentos para imprimir
                      </v-card-title>
                      <v-card-text>
                        <div>
                          <v-card class="my-2 pa-3" elevation="0" outlined v-for="(doc, index) in vtadocBorradorDescarga"
                            :key="index">
                            <v-row>
                              <v-col class="d-flex">
                                <div class="d-flex align-center">
                                  <v-icon>mdi-file-document-outline</v-icon>
                                  <div class="ml-2">
                                    {{ doc.Folio }}
                                  </div>
                                </div>
                              </v-col>
                              <v-col class="mt-4">{{
                                doc.Vtasser[0].Tipo
                              }}</v-col>
                              <v-col>
                                <div :key="index">
                                  <GenerateDocTributarioPdfVue :dte="{
                                    cliente: doc.Cliente,
                                    vtaser: doc.Vtasser,
                                  }" />
                                </div>
                              </v-col>
                            </v-row>
                          </v-card>
                        </div>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" outlined @click="btnCloseDialogVtaBorradorDescargar">
                          Cerrar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
                <v-divider vertical class="d-none d-sm-none d-md-flex d-lg-flex d-xl-flex"></v-divider>
                <!-- carro borrador -->
                <v-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <div class="flex-column align-space-between colcart">
                    <!-- búsqueda -->
                    <div>
                      <div>
                        <div class="d-flex">
                          <div>
                            <v-icon class="mx-2 my-3" size="30">mdi-barcode-scan</v-icon>
                          </div>
                          <!-- buscar -->
                          <v-autocomplete :loading="activeLoadingCargarProdu" :items="produs" item-text="Despcod" solo label="Buscar productos" :search-input.sync="searchProdus" clearable
                            return-object v-model="selectProduBorrador">
                          </v-autocomplete>
                          <v-btn color="primary" class="mt-1 ml-2" @click="btnSearchProductByServiece"
                            @keyup.enter="btnSearchProductByServiece">Buscar</v-btn>
                          <div>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" dark v-bind="attrs" v-on="on" icon class="mx-4 mt-1"
                                  @click="activeReaderCodeVentaBorrador = true">
                                  <v-icon class="mx-2 mb-1" size="50">mdi-camera</v-icon>
                                </v-btn>
                              </template>
                              <span>Usar la cámara</span>
                            </v-tooltip>
                          </div>
                        </div>
                        <div v-if="activeReaderCodeVentaBorrador">
                          <ReaderAllCode origen="ventaborrador" @actualizarCodigoVentaBorrador="actualizarCodigoVentaBorrador
                            " />
                        </div>
                      </div>
                    </div>
                    <!-- carro -->
                    <div class="mb-2 colcartcenter overflow-y-auto cartespecial">
                      <!-- add product -->
                      <v-card color="" outlined elevation="1" class="pa-0 mb-2 ma-2 rounded-xl"
                        v-if="selectProduBorrador">
                        <div class="pa-2">
                          <div class="d-flex">
                            <h3 class="black--text my-2 mx-3">
                              {{ selectProduBorrador.Descripcion }}
                            </h3>
                          </div>
                          <!-- precio , descuento y cantidad -->
                          <div class="d-flex justify-center">
                            <div>
                              <h3 class="my-3">Precio</h3>
                              <h2>
                                {{
                                  selectProduBorrador.Vtadeta | formatCurrency
                                }}
                              </h2>
                            </div>
                            <v-divider vertical class="mx-3 my-6"> </v-divider>
                            <div>
                              <h3 class="ma-1 my-2">Aplicar descuento</h3>
                              <v-text-field dense outlined type="number" min="0" v-model="selectDescuentoBorrador"
                                label="Descuento %"></v-text-field>
                            </div>
                            <v-divider vertical class="mx-3 my-6"> </v-divider>
                            <!-- cantidad a agregar -->
                            <div class="">
                              <h3 class="ma-1 my-2">Cantidad</h3>
                              <div class="d-flex">
                                <v-text-field outlined color="primary" type="number" min="1"
                                  v-model="cantidadAgregarCarroBorrador" dense></v-text-field>
                                <v-btn @click="
                                  btnAddCarritoBorrador(
                                    selectProduBorrador.Id
                                  )
                                  " color="primary" class="mx-2">Agregar</v-btn>
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-card>
                      <h3>Carro</h3>
                      <!-- lista del carro -->
                      <div v-if="carritoBorrador.length > 0">
                        <!-- menu del carro -->
                        <v-row>
                          <v-col cols="3">Producto</v-col>
                          <v-col>Precio</v-col>
                          <v-col>Cantidad</v-col>
                          <v-col>Descuento</v-col>
                          <v-col>Total</v-col>
                          <v-col cols="1"></v-col>
                        </v-row>
                        <v-row v-for="productocarro in carritoBorrador" :key="productocarro.Id">
                          <v-col cols="3">
                            <div>
                              <v-chip small outlined color="primary" v-if="productocarro.Imp == 0">Exento</v-chip>
                              {{ productocarro.Nompro }}
                            </div>
                          </v-col>
                          <v-col>$
                            {{ productocarro.Precio | formatCurrency }}</v-col>
                          <v-col>{{ productocarro.Cantidad }}</v-col>
                          <v-col>$ {{ productocarro.Des | formatCurrency }}</v-col>
                          <v-col>$ {{ productocarro.Total | formatCurrency }}</v-col>
                          <v-col cols="1">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn @click="
                                  btnDeleteProduCartBorrador(
                                    productocarro.Cod
                                  )
                                  " icon :disabled="disabledCart" v-bind="attrs"
                                  v-on="on"><v-icon>mdi-delete-outline</v-icon></v-btn>
                              </template>
                              <span>Quitar del carro</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </div>
                      <div v-else class="my-4">Sin productos seleccionado</div>
                    </div>
                    <!-- total -->
                    <v-divider class="my-2"></v-divider>
                    <div class="my-2">
                      <div class="d-flex">
                        <h3>Total productos {{ calculateCantidadBorrador }}</h3>
                        <v-spacer></v-spacer>
                        <h3>
                          Total ${{ calculateTotalBorrador | formatCurrency }}
                        </h3>
                      </div>
                      <div class="d-flex justify-end mt-2" v-if="uidVenta">
                        <v-btn class="mx-2" color="red" outlined>Anular y limpiar</v-btn>
                        <v-btn class="mx-2" color="primary" outlined @click="btnCreateCarritoPendiente"
                          :loading="activeLoadingCreateCarritoPendiente">Continuar despues con esta venta</v-btn>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
        </div>
      </v-card>
    </div>
    <div v-else class="d-flex justify-center pa-3 ma-3">
      <div class="d-flex flex-column">
        <v-avatar tile size="300" class="d-flex justify-center">
          <v-img contain sizes="200" src="/icons/notselect.svg"></v-img>
        </v-avatar>
        <div class="d-flex justify-center">
          <h3 class="mt-3 ml-3">
            No tiene los permisos necesarios para <br />
            acceder a los datos
          </h3>
          <!-- Btn volver al menú principal -->
        </div>
        <div class="d-flex justify-center my-3">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="text-capitalize rounded-lg" v-bind="attrs" v-on="on"
                @click="$router.go(-1)">Volver</v-btn>
            </template>
            <span>Volver al inicio</span>
          </v-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import ReaderAllCode from "../../components/ReaderAllCode.vue";
import ToolbarUser from "../../components/toolbar/ToolbarUser.vue";
import GenerateDocTributarioPdfVue from "./components/GenerateDocTributarioPdf.vue";
import GeneratePdfCtaCteVue from "./components/GeneratePdfCtaCte.vue";
import GeneratePdfPagosVue from "./components/GeneratePdfPagos.vue";
import GeneratePdfResumenCajaVue from "./components/GeneratePdfResumenCaja.vue";
import GeneratePdfVtaserVue from "./components/GeneratePdfVtaser.vue";

export default {
  components: {
    ToolbarUser,
    GenerateDocTributarioPdfVue,
    GeneratePdfVtaserVue,
    GeneratePdfResumenCajaVue,
    GeneratePdfPagosVue,
    GeneratePdfCtaCteVue,
    ReaderAllCode,
  },
  data() {
    return {
      isMobile: false,
      activeLoadingCreateCierreCaja: false,
      tabContinuarVenta: null,
      menuDateCaja: false,
      activeShowAllPagos: false,
      tabcierrecaja: null,
      selectProdu: "",
      inventarios: [],
      carrito: [],
      cantidadAgregarCarro: 1,
      search: "",
      selectCarroContinuar: [],
      activeSearchCliente: false,
      selectCliente: {},
      selectNdocSearchEdit: "",
      activeDialogoDeleteCarro: false,
      headersClientes: [
        { text: "Seleccionar", value: "action" },
        { text: "Mascota", value: "Nombre" },
        { text: "Fichan", value: "Fichan" },
        { text: "Nombre", value: "Amo" },
        { text: "Rut", value: "Rut" },
        { text: "Teléfono", value: "Fono" },
      ],
      dateCaja: "",
      headersDocvtasser: [
        { text: "N° documento", value: "Nboleta" },
        { text: "Tipo", value: "Tipo" },
        { text: "Cliente", value: "Cliente" },
        { text: "Monto", value: "Monto" },
        { text: "Descargar", value: "action" },
      ],
      tipoDoc: [
        "Factura",
        "Factura de ventas y servicios no afectos o exentos de IVA",
        "Factura electrónica",
        "Factura no afecta o exenta electrónica",
        "Boleta",
        "Boleta exenta",
        "Boleta electrónica",
        "Liquidación factura",
        "Boleta exenta electrónica",
        "Liquidación factura electrónica",
        "Factura de compra",
        "Factura de compra electrónica",
        "Pago electrónico",
        "Guía de despacho",
        "Guía de despacho electrónica",
        "Nota de débito",
        "Nota de débito electrónica",
        "Nota de crédito",
        "Nota de crédito electrónica",
        "Liquidación",
        "Factura de exportación electrónica",
        "Nota de débito de exportación electrónica",
        "Nota de crédito de exportación electrónica",
      ],
      formaPago: [
        "Efectivo",
        "Tarjeta D/C",
        "Transferencia",
        "Deposito",
        "Canje",
        "Cheque",
        "N.de Credito",
        "CDC",
      ],
      docvtasser: [
        {
          Nboleta: 5,
          Cliente: "Maxi",
          Tipo: "Boleta Electrónica",
          Monto: 58900,
        },
      ],
      today: new Date().toISOString().substring(0, 10),
      menuFechaCheque: false,
      selectFormaPago: "",
      selectReg: "",
      selectDescuento: "0",
      searchVtasserCliente: "",
      selectTipoDocSearch: "",
      opcionVenta: false,
      opcionBuscarProducto: false,
      opcionContinuarVenta: false,
      opcionVerVentas: false,
      opcionVerPagos: false,
      opcionVerCtaCte: false,
      opcionEditarDocs: false,
      opcionVerCaja: false,
      menuCentral: true,
      searchProdus: "",
      searchCarritoPendiente: "",
      searchPagosVtasser: "",
      selectSearchProdu: "",
      selectTipoDoc: "",
      selectVistaVentas: "",
      cerrarCaja: false,
      settablaimptosSii: [],
      activeShowSearchGuia: false,
      headersProdus: [
        { text: "Producto o servicio", value: "Descripcion" },
        { text: "Código", value: "Codigo" },
        { text: "Precio", value: "Vtadeta" },
        { text: "Seleccionar", value: "action" },
      ],
      headersSales: [
        {
          text: "Producto o servicio",
          value: "Descripcion",
        },
        { text: "Cantidad", value: "Cantidad" },
        { text: "Precio", value: "Precio" },
        { text: "Subtotal", value: "Subtotal" },
        { text: "Impto", value: "Impuesto" },
        { text: "Total", value: "Total" },
      ],
      headersMovimientosCaja: [
        { text: "Concepto", value: "Concepto" },
        { text: "N° doc", value: "Boleta" },
        { text: "Monto", value: "Monto" },
        { text: "Plan de cuenta", value: "Familia" },
        { text: "Eliminar", value: "action" },
      ],
      headersMovimientosCajaRange: [
        { text: "Fecha", value: "Fecha" },
        { text: "Concepto", value: "Concepto" },
        { text: "N° doc", value: "Boleta" },
        { text: "Monto", value: "Monto" },
        { text: "Plan de cuenta", value: "Familia" },
      ],
      headersCierresCaja: [
        { text: "Fecha", value: "Fecha" },
        { text: "Caja inicial", value: "Totalventa" },
        { text: "Retiros", value: "Totalingresos" },
        { text: "Caja D / S", value: "Diferencias" },
        // { text: "Eliminar", value: "action" },
      ],
      headersVtasser: [
        { text: "Cliente", value: "Nombre" },
        { text: "Ficha", value: "Fichan" },
        { text: "Producto o servicio", value: "Nompro" },
        { text: "Cantidad", value: "Cantidad" },
        { text: "Precio", value: "Precio" },
        { text: "Descuento", value: "Des" },
        { text: "Total", value: "Precio" },
        { text: "N° Documento", value: "Bnumero" },
        { text: "Tipo Documento", value: "Tipo" },
        { text: "Fecha", value: "Fecha" },
        { text: "Monto Pagado", value: "Montopagado" },
        { text: "Imprimir SII", value: "action" },
      ],
      headersVtasserDiferencias: [
        { text: "Cliente", value: "Nombre" },
        { text: "Ficha", value: "Fichan" },
        { text: "Producto o servicio", value: "Nompro" },
        { text: "Cantidad", value: "Cantidad" },
        { text: "Precio", value: "Precio" },
        { text: "Descuento", value: "Des" },
        { text: "Total", value: "Precio" },
        { text: "N° Documento", value: "Bnumero" },
        { text: "Tipo Documento", value: "Tipo" },
        { text: "Fecha", value: "Fecha" },
        { text: "Monto Pagado", value: "Montopagado" },
        { text: "Monto Pendiente", value: "Pendiente" },
        { text: "Acciones", value: "action" },
      ],
      headersVtasserDiferenciasPago: [
        { text: "Cliente", value: "Nombre" },
        { text: "Producto o servicio", value: "Nompro" },
        { text: "Total", value: "Precio" },
        { text: "Monto Pagado", value: "Montopagado" },
        { text: "Monto Pendiente", value: "Pendiente" },
      ],
      headersVtasserPrint: [
        { text: "Cliente", value: "Nombre" },
        { text: "Producto o servicio", value: "Nompro" },
        { text: "Total", value: "Precio" },
        { text: "Monto Pagado", value: "Montopagado" },
      ],
      headersVtasserCliente: [
        { text: "Fecha", value: "Fecha" },
        { text: "Producto o servicio", value: "Nompro" },
        { text: "Cantidad", value: "Cantidad" },
        { text: "Precio", value: "Precio" },
        { text: "Descuento", value: "Des" },
        { text: "Total", value: "Precio" },
        { text: "Monto Pagado", value: "Montopagado" },
      ],
      headersPagosVtasserCliente: [
        { text: "Fecha", value: "Fecha" },
        { text: "Forma", value: "Forma" },
        { text: "N° doc", value: "Nboleta" },
        { text: "Tipo doc", value: "Glosa" },
        { text: "Monto", value: "Monto" },
      ],
      headersPagosVtasser: [
        { text: "Ficha", value: "Fichan" },
        { text: "Método pago", value: "Banco" },
        { text: "Forma", value: "Forma" },
        { text: "N ° Referencia", value: "Serie" },
        { text: "Monto", value: "Monto" },
        { text: "N° Documento", value: "Nboleta" },
        { text: "Tipo Documento", value: "Glosa" },
        { text: "Fecha", value: "Fecha" },
        { text: "Vendedor", value: "Usuario" },
        { text: "Sucursal", value: "Sucursal" },
      ],
      headersCarritosPendiente: [
        { text: "Cliente", value: "Nombre" },
        { text: "Ficha", value: "Fichan" },
        { text: "Fecha", value: "Fecha" },
        { text: "Ver", value: "action" },
      ],
      selectProduBorrador: false,
      carritoBorrador: [],
      uidVenta: "",
      selectVendedor: "",
      selectBancoCheque: "",
      pagos: [],
      searchVtasser: "",
      pago: {
        Id: 0,
        Fichan: 0,
        Nboleta: 0,
        Monto: 0,
        Fecha: "",
        Forma: "",
        Vence: "",
        Banco: "",
        Serie: 0,
        Ubicacion: "",
        Glosa: "",
        Reg: "",
        Usuario: "",
        Fecha3: "",
        Sucursal: "",
      },
      bancos: [
        "BANCO DE CHILE",
        "BANCO INTERNACIONAL",
        "SCOTIABANK CHILE",
        "BANCO DE CREDITO E INVERSIONES",
        "BANCO BICE",
        "HSBC BANK (CHILE)",
        "BANCO SANTANDER-CHILE",
        "BANCO ITAÚ CHILE",
        "BANCO SECURITY",
        "BANCO FALABELLA",
        "BANCO RIPLEY",
        "BANCO CONSORCIO",
        "SCOTIABANK AZUL (ex BANCO BILBAO VIZCAYA ARGENTARIA, CHILE (BBVA))",
        "BANCO BTG PACTUAL CHILE",
        "BANCO DO BRASIL S.A.",
        "JP MORGAN CHASE BANK, N. A.",
        "BANCO DE LA NACION ARGENTINA",
        "MUFG Bank, Ltd.",
        "CHINA CONSTRUCTION BANK, AGENCIA EN CHILE",
        "BANK OF CHINA, AGENCIA EN CHILE",
        "BANCO DEL ESTADO DE CHILE",
      ],
      disabledBtnPay: false,
      selectMontoPagado: "",
      disabledCart: false,
      selectCarroContinuar: "",
      selectSearchVtabydoc: [],
      selectFechaCheque: "",
      activeLoadingCreateCarritoPendiente: false,
      activeLoadingCancelarCarrito: false,
      menuDateFilterPago: false,
      dateFilterPagos: "",
      menuDateFilterCaja: false,
      dateFilterCaja: "",
      tablaimptosfix: [
        {
          Concepto: "BOLETA ELECTRONICA",
          Id: 8,
          Idnuevo: 0,
          Impresora: "Predeterminada",
          Impuesto: 19,
          Sucursal: "El Bosque",
          Tipodte: "39",
          Ultimo: 36118,
        },
        {
          Concepto: "FACTURA ELECTRONICA",
          Id: 14,
          Idnuevo: 0,
          Impresora: "Predeterminada",
          Impuesto: 19,
          Sucursal: "El Bosque",
          Tipodte: "33",
          Ultimo: 5,
        },
      ],
      //stats venta
      statsventas: {
        total: 0,
        totalmonto: 0,
        totalpagado: 0,
        totalarticulos: 0,
        totalunidades: 0,
      },
      //stats pagos
      statspagos: {
        total: 0,
        totalpagado: 0,
        totalefectivo: 0,
        totaltarjeta: 0,
        totalconvenio: 0,
        totalchequefecha: 0,
        totalchequedia: 0,
        totaltransferencia: 0,
        totalcanje: 0,
        totalndecredito: 0,
        totaldeposito: 0,
      },
      //stats venta cliente
      statsventascliente: {
        total: 0,
        totalmonto: 0,
        totalpagado: 0,
        totalarticulos: 0,
        totalunidades: 0,
      },
      //stats pagos cliente
      statspagoscliente: {
        total: 0,
        totalpagado: 0,
        totalefectivo: 0,
        totaltarjeta: 0,
        totalconvenio: 0,
        totalcheque: 0,
        totaltransferencia: 0,
      },
      activeAfecto: false,
      activeExento: false,
      //stats pagos
      dateFilterVentas: "",
      //menu ventas
      menuDateFilterVentas: false,
      activeLoadingPagar: false,
      tabctacliente: null,
      activeGenerarSii: false,
      vtasserDiferencias: [],
      cantidadAgregarCarroBorrador: 1,
      activeDialogoPagarPendiente: false,
      activeLoadingSearchVtasDiferenciaPago: false,
      selectPlanCuentaCreate: "",
      selectNDocMovimientoCaja: "0",
      selectConceptoMovimientoCaja: "",
      selectMontoMovimientoCaja: "0",
      activeLoadingCreateMovimientoCaja: false,
      dateEndMovimientosCaja: "",
      dateStartMovimientosCaja: "",
      menuStartMovimientosCaja: false,
      menuEndMovimientosCaja: false,
      showCreateGasto: false,
      tabTipoDocBorrador: false,
      activeShowPagoBorrador: false,
      uidVentaBorrador: "",
      pagosBorrador: [],
      selectTipoDocBorrador: "",
      selectDescuentoBorrador: "0",
      selectMontoPagadoBorrador: "0",
      activeShowSearchBorrador: false,
      activeLoadingPagarBorrador: false,
      disabledBtnPayBorrador: false,
      selectFormaPagoBorrador: "",
      selectRegBorrador: "",
      disabledCartBorrador: false,
      activeDialogoImprimirDocVta: false,
      selectSearchVtabydocPrint: [],
      activePrintDoc: false,
      dte: {
        cliente: {},
        vtaser: [],
      },
      showGeneratePdfVtaser: false,
      vtadoctributario: [],
      selectFichaCuenta: "",
      selectTipoCuenta: "",
      selectFichaCuentaHospital: "",
      selectCuenta: [],
      selectActiveCuentaHospital: false,
      vtaserBorradorSearch: {
        vtaser: [],
        cliente: "",
        fecha: "",
        total: 0,
        montopagado: 0,
        fichan: 0,
      },
      selectNdocSearchBorrador: "",
      menuFechaChequeBorrador: false,
      selectFechaChequeBorrador: "",
      selectBancoChequeBorrador: "",
      activeLoadingSearchVtaserBorrador: false,
      activeGenerarDocumentoBorradorDescargar: false,
      vtadocBorradorDescarga: [],
      motivosGuia: [
        "Operación constituye venta",
        "Entrega gratuita",
        "Otros traslados, no venta",
        "Guía de devolución",
        "Ventas por factura",
        "Consignaciones",
        "Traslado interno",
      ],
      selectTipoMotivoGuia: "",
      selectPatenteGuia: "",
      selectComunaGuia: "",
      selectCiudadGuia: "",
      selectDireccionGuia: "",
      tipoNotaBorrador: [
        {
          Id: 13,
          Concepto: "NOTA DE CRÉDITO ELECTRÓNICA",
          Impuesto: 19,
          Ultimo: 99,
          Impresora: "Predeterminada",
          Sucursal: "Nueva Demo",
          Tipodte: "61",
          Idnuevo: 0,
        },
        {
          Id: 13,
          Concepto: "NOTA DE DÉBITO ELECTRÓNICA",
          Impuesto: 19,
          Ultimo: 99,
          Impresora: "Predeterminada",
          Sucursal: "Nueva Demo",
          Tipodte: "55",
          Idnuevo: 0,
        },
      ],
      tipoImpuestoNotaBorrador: ["Afecto", "Exento"],
      tipoRefNota: [
        { tipo: "Anula documento", codigo: "1" },
        { tipo: "Corrige texto", codigo: "2" },
        { tipo: "Corrige monto", codigo: "3" },
      ],
      selectTipoImpuestoNotaBorrador: "",
      selectTipoImptoNota: "",
      selectReferenciaNotaBorrador: [],
      selectNumdocrefNota: "",
      selectFechaDocrefNota: "",
      menuFechaDocrefNota: false,
      selectRazonReferenciaNota: "",
      selectMotivoGuiaBorrador: "",
      selectTipoNotaBorrador: "",
      activeLoadingCreateDocumento: false,
      selectTipoMotivoGuia: "",
      activeLoadingNotaValidarDte: false,
      tipoSearchGuia: "",
      tipoSearchNota: "",
      selectNumerodocGuiaSearch: "",
      selectNumerodocNotaSearch: "",
      menuFechaBusquedaGuia: false,
      menuFechaBusquedaNota: false,
      selectFechaBusquedaGuia: "",
      selectFechaBusquedaNota: "",
      selectSearchGuiasBuscadas: [],
      selectSearchNotasBuscadas: [],
      selectSearchTipoNota: "",
      headersGuiasSearch: [
        { text: "Fecha", value: "Fecha" },
        { text: "N° doc", value: "Bnumero" },
        { text: "Total", value: "Total" },
        { text: "Ver", value: "action" },
      ],
      activeShowSearchNota: false,
      tiposNotaSearch: ["Credito", "Debito"],
      activeReaderCodeVentaNormal: false,
      activeReaderSearchProdu: false,
      activeReaderCodeVentaBorrador: false,
    };
  },
  watch: {
    carrito: function (val) {
      if (val.length > 0) {
        val.forEach((element) => {
          if (element.Imp == 0) {
            this.activeExento = true;
          }
          if (element.Imp == 19) {
            this.activeAfecto = true;
          }
        });
      } else {
        this.activeAfecto = false;
        this.activeExento = false;
      }
    },
    produs: function (val) {
      this.setProdus();
    },
    successCreateCarrito: function (val) {
      if (val) {
        this.activeLoadingCreateCarritoPendiente = false;
      }
    },
    successCreateCarritoBorrador: function (val) {
      if (val) {
      }
    },
    successCancelarCarrito: function (val) {
      if (val) {
        this.activeLoadingCancelarCarrito = false;
        this.activeDialogoDeleteCarro = false;
        this.selectCarroContinuar = [];
      }
    },
    vtasser: function (val) {
      this.setStatsVentas();
    },
    pagosvtaser: function (val) {
      this.setStatsPagos();
    },
    pagosVtasserCliente: function (val) {
      this.setStatsPagosCliente();
    },
    vtasserCliente: function (val) {
      this.setStatsVentasCliente();
    },
    successCreateMovimientoCaja: function (val) {
      if (val) {
        this.activeLoadingCreateMovimientoCaja = false;
        this.showCreateGasto = false;
        this.selectPlanCuentaCreate = "";
        this.selectMontoMovimientoCaja = "0";
        this.selectNDocMovimientoCaja = "0";
      }
    },
    successCreateCierreCaja: function (val) {
      if (val) {
        this.activeLoadingCreateCierreCaja = false;
      }
    },
    tabTipoDocBorrador: function (val) {
      this.activeLoadingCreateDocumento = false;
      this.activeShowSearchGuia = false;
      this.activeShowSearchBorrador = false;
      this.activeShowSearchNota = false;
    },
    selectTipoImptoNota: function (val) {
      if (val) {
        this.settablaimptosSii = [];
        this.tablaimptosSii.forEach((element) => {
          if (this.selectTipoImptoNota == "Afecto") {
            if (element.Tipodte == "33" || element.Tipodte == "39") {
              this.settablaimptosSii.push(element);
            }
          }
          if (this.selectTipoImptoNota == "Exento") {
            if (element.Tipodte == "34" || element.Tipodte == "41") {
              this.settablaimptosSii.push(element);
            }
          }
        });
      }
    },
    tabContinuarVenta: function (val) {
      if (val) {
        this.actionSelectFichaCuentaHospital("");
        this.actionSetCustomerEmpty("");
        this.selectCarroContinuar = [];
        this.selectFichaCuenta = "";
      }
    },
  },
  computed: {
    ...mapState("medical-module", ["produs", "doctors", "ventasdepaso", "activeLoadingCargarProdu"]),
    ...mapState("auth-module", ["user", "licen"]),
    ...mapState("customers-module", [
      "clientes",
      "cliente",
      "selectFichaClienteId",
    ]),
    ...mapState("sales-module", [
      "activeLoadingSearchInventarios",
      "carritosPendientes",
      "vtasser",
      "pagosvtaser",
      "vtasserCliente",
      "loadingVtasserCliente",
      "loadingVtasserByDoc",
      "activeLoadingVtasser",
      "tablaimptos",
      "successCreateCarrito",
      "successCancelarCarrito",
      "loadingPagosVtasserCliente",
      "pagosVtasserCliente",
      "activeLoadingPagosVtasser",
      "movimientosCaja",
      "cierresCaja",
      "planCuentas",
      "cierreCajaHoy",
      "cierreDeCajaUltimo",
      "loadingMovimientosCaja",
      "successCreateMovimientoCaja",
      "loadingMovimientosCajaRange",
      "movimientosCajaRange",
      "successCreateCierreCaja",
      "successCreateCarritoBorrador",
      "tablaimptosSii",
      "activetLoadingSearchVtaserByDoc",
    ]),
    calculateCantidad() {
      let total = 0;
      this.carrito.forEach((element) => {
        total += parseInt(element.Cantidad);
      });
      return total;
    },
    calculateCantidadBorrador() {
      let total = 0;
      this.carritoBorrador.forEach((element) => {
        total += parseInt(element.Cantidad);
      });
      return total;
    },
    setCuentaHospital() {
      let cuentasHospital = [];
      this.ventasdepaso.forEach((item) => {
        if (item.Origen == "HTS") {
          let index = cuentasHospital.find(
            (cuenta) => (cuenta.Id = item.Ficha)
          );
          if (index != -1) {
            cuentasHospital.push(item);
          }
        }
      });
      return cuentasHospital;
    },
    setCuentaAlCliente() {
      let cuentasCliente = [];
      this.ventasdepaso.forEach((item) => {
        if (item.Origen == "Ventas") {
          let index = cuentasCliente.find((cuenta) => (cuenta.Id = item.Ficha));
          if (index != -1) {
            cuentasCliente.push(item);
          }
        }
      });
      return cuentasCliente;
    },
    checkMobile() {
      return (this.isMobile = window.innerWidth <= 500); // Define aquí el ancho máximo para considerar un dispositivo móvil
    },
    calculateTotal() {
      let total = 0;
      this.carrito.forEach((element) => {
        total += element.Total;
      });
      this.selectMontoPagado = total;
      return total;
    },
    calculateTotalBorrador() {
      let total = 0;
      this.carritoBorrador.forEach((element) => {
        total += element.Total;
      });
      return total;
    },
    calculateTotalMovimientosCaja() {
      let total = 0;
      this.movimientosCaja.forEach((element) => {
        total += element.Monto;
      });
      return total;
    },
    calculateTotalMovimientosCajaRange() {
      let total = 0;
      this.movimientosCajaRange.forEach((element) => {
        total += element.Monto;
      });
      return total;
    },
    calculateTotalVtaDiferencia() {
      let total = 0;
      this.selectSearchVtabydoc.forEach((element) => {
        total += element.Total;
      });
      return total;
    },
    calculateTotalPagado() {
      let total = 0;
      this.pagos.forEach((element) => {
        total += element.monto;
      });
      return total;
    },
    calculateTotalPagadoBorrador() {
      let total = 0;
      this.pagosBorrador.forEach((element) => {
        total += element.monto;
      });
      return total;
    },
    calculateTotalPagadoVtaDiferencia() {
      let total = 0;
      this.selectSearchVtabydoc.forEach((element) => {
        total += element.Montopagado;
      });
      return total;
    },
    calculateCarritoPendienteCantidad() {
      let total = 0;
      this.selectCarroContinuar.forEach((element) => {
        total += parseInt(element.Cantidad);
      });
      return total;
    },
    calculateCarritoPendienteTotal() {
      let total = 0;
      this.selectCarroContinuar.forEach((element) => {
        total += element.Total;
      });
      return total;
    },
    calculateCarritoPendienteTotalPagado() {
      let total = 0;
      this.pagos.forEach((element) => {
        total += element.monto;
      });
      return total;
    },
  },
  methods: {
    ...mapActions("sales-module", [
      "getInventariosByCodigo",
      "postVtasserpaso",
      "getVtasserpasoPendientes",
      "getVtasserpasoCarritoUid",
      "getVtasserByDate",
      "postPagoVtasser",
      "getVtasserByCliente",
      "getPagosVtasserByCliente",
      "getVtasserByDocumento",
      "postCancelarCarrito",
      "getPagosVtasserByDate",
      "getMovimientosCajaByDate",
      "postMovimientoCaja",
      "getMovimientosCajaByDateRange",
      "postCierreCaja",
      "postVtasserpasoBorrador",
      "postPagoVtasserBorrador",
      "getVtasserBorradorByTipodoc",
      "postVtasserpasoNotaBorrador",
      "postVtasserpasoGuiaBorrador",
      "getVtasserByTipodocValidarDte",
      "getVtaserByFechaDocTipodoc",
    ]),
    ...mapActions("customers-module", [
      "getClientes",
      "getClienteReturnByFichan",
      "getClienteByFichan",
      "actionSetCustomerEmpty",
    ]),
    ...mapActions("medical-module", ["actionSelectFichaCuentaHospital", "getProdusByNameOrCode"]),
    actualizarCodigoVenta(code) {
      this.selectProdu = this.produs.find((item) => item.Codigo == code);
      if (this.selectProdu.Id) {
        this.activeReaderCodeVentaNormal = false;
      }
      if (!this.selectProdu.Id) {
        alert("Codigo no encontrado");
        return;
      }
    },
    scrollToInfo() {
      const infoSectionElement = this.$refs.infoSection;
      infoSectionElement.scrollIntoView({ behavior: 'smooth' });
    },
    irAProductoBuscado() {
      const infoProductoBuscado = this.$refs.productoBuscado;
      infoProductoBuscado.scrollIntoView({ behavior: 'smooth' });
    },
    actualizarCodigoVentaBorrador(code) {
      this.selectProduBorrador = this.produs.find(
        (item) => item.Codigo == code
      );
      if (this.selectProduBorrador.Id) {
        this.activeReaderCodeVentaBorrador = false;
      }
      if (!this.selectProduBorrador.Id) {
        alert("Codigo no encontrado");
        return;
      }
    },
    actualizarCodigoBuscarProdu(code) {
      this.searchProdus = code;
      let serachProdu = this.produs.find((item) => item.Codigo == code);
      if (!serachProdu.Id) {
        alert("Codigo no encontrado");
        return;
      }
      this.activeReaderSearchProdu = false;
    },
    setProdus() {
      this.produs.forEach((element) => {
        element.Despcod = element.Descripcion + " / " + element.Codigo;
      });
    },
    setDateStringTimeZoneShort(date) {
      var dataSet = new Date(date.substring(0, 10) + "T00:00:00");

      var day = dataSet.getDate();
      var year = dataSet.getFullYear();
      var month = dataSet.getMonth();
      var montTex = "";
      var h = dataSet.getHours();
      var m = dataSet.getMinutes();
      switch (month) {
        case 0:
          montTex = "Enero";
          break;
        case 1:
          montTex = "Febrero";
          break;
        case 2:
          montTex = "Marzo";
          break;
        case 3:
          montTex = "Abril";
          break;
        case 4:
          montTex = "Mayo";
          break;
        case 5:
          montTex = "Junio";
          break;
        case 6:
          montTex = "Julio";
          break;
        case 7:
          montTex = "Agosto";
          break;
        case 8:
          montTex = "Septiembre";
          break;
        case 9:
          montTex = "Octubre";
          break;
        case 10:
          montTex = "Noviembre";
          break;
        case 11:
          montTex = "Diciembre";
        default:
          break;
      }
      if (m == 0) {
        m = "00";
      }
      if (h == 0) {
        h = "00";
      }
      return day + " de " + montTex + " del " + year;
    },
    setDateNow() {
      var currentDateObj = new Date();
      var numberOfMlSeconds = currentDateObj.getTime();
      var addMlSeconds = 60 * 240000;
      var newDateObj = new Date(numberOfMlSeconds - addMlSeconds);
      return newDateObj.toISOString().substring(0, 10);
    },
    setDateNowString() {
      var currentDateObj = new Date();
      var numberOfMlSeconds = currentDateObj.getTime();
      var addMlSeconds = 60 * 240000;
      var newDateObj = new Date(numberOfMlSeconds - addMlSeconds);
      return newDateObj.toISOString().substring(0, 10);
    },
    setStatsPagos() {
      this.statspagos = {
        total: 0,
        totalpagado: 0,
        totalefectivo: 0,
        totaltarjeta: 0,
        totalconvenio: 0,
        totalchequefecha: 0,
        totalchequedia: 0,
        totaltransferencia: 0,
        totalcanje: 0,
        totalndecredito: 0,
        totaldeposito: 0,
      };
      let numbersfolio = [];
      this.pagosvtaser.forEach((pago) => {
        this.statspagos.totalpagado += pago.Monto;
        // let search = numbersfolio.findIndex((item) => item == vta.Bnumero);
        // if (search == -1) {
        //   numbersfolio.push(vta.Bnumero);
        // }
        this.statspagos.total += 1;
        if (pago.Forma == "Efectivo") {
          this.statspagos.totalefectivo += pago.Monto;
        }
        if (pago.Forma == "Voucher" || pago.Forma == "Tarjeta D/C") {
          this.statspagos.totaltarjeta += pago.Monto;
        }
        if (pago.Forma == "Transferencia") {
          this.statspagos.totaltransferencia += pago.Monto;
        }
        if (pago.Forma == "Convenio" || pago.Forma == "CDC") {
          this.statspagos.totalconvenio += pago.Monto;
        }
        if (pago.Forma == "Cheque") {
          let fechaInicial = new Date(pago.Fecha); // Asegúrate de proporcionar una fecha válida en formato "yyyy-mm-dd"
          let fechaFinal = new Date(pago.Vence);

          // Obtén la diferencia en milisegundos
          let diferenciaEnMilisegundos = fechaFinal - fechaInicial;

          // Ahora convierte los milisegundos en días
          // 1 segundo = 1000 milisegundos
          // 1 minuto = 60 segundos
          // 1 hora = 60 minutos
          // 1 día = 24 horas
          let diasEntreFechas =
            diferenciaEnMilisegundos / (1000 * 60 * 60 * 24);

          if (diasEntreFechas > 4) {
            this.statspagos.totalchequefecha += pago.Monto;
          } else {
            this.statspagos.totalchequedia += pago.Monto;
          }
        }
        if (pago.Forma == "N.de Credito") {
          this.statspagos.totalndecredito += pago.Monto;
        }
        if (pago.Forma == "Deposito") {
          this.statspagos.totaldeposito += pago.Monto;
        }
        if (pago.Forma == "Canje") {
          this.statspagos.totalcanje += pago.Monto;
        }
      });
    },
    setStatsVentas() {
      this.vtasserDiferencias = [];
      this.statsventas = {
        total: 0,
        totalmonto: 0,
        totalpagado: 0,
        totalarticulos: 0,
        totalunidades: 0,
      };
      let numbersfolio = [];
      this.vtasser.forEach((vta) => {
        this.statsventas.totalmonto += vta.Total;
        let search = numbersfolio.findIndex((item) => item == vta.Bnumero);
        if (search == -1) {
          numbersfolio.push(vta.Bnumero);
        }
        this.statsventas.total = numbersfolio.length;
        this.statsventas.totalpagado += vta.Montopagado;
        this.statsventas.totalarticulos += 1;
        this.statsventas.totalunidades += vta.Cantidad;
        if (vta.Total > vta.Montopagado) {
          this.vtasserDiferencias.push(vta);
        }
      });
    },
    setStatsPagosCliente() {
      this.statspagoscliente = {
        total: 0,
        totalpagado: 0,
        totalefectivo: 0,
        totaltarjeta: 0,
        totalconvenio: 0,
        totalchequefecha: 0,
        totalchequedia: 0,
        totaltransferencia: 0,
        totalcanje: 0,
        totalndecredito: 0,
        totaldeposito: 0,
      };
      this.pagosVtasserCliente.forEach((pago) => {
        this.statspagoscliente.totalpagado += pago.Monto;

        this.statspagoscliente.total += 1;
        if (pago.Forma == "Efectivo") {
          this.statspagoscliente.totalefectivo += pago.Monto;
        }
        if (pago.Forma == "Voucher" || pago.Forma == "Tarjeta D/C") {
          this.statspagoscliente.totaltarjeta += pago.Monto;
        }
        if (pago.Forma == "Transferencia") {
          this.statspagoscliente.totaltransferencia += pago.Monto;
        }
        if (pago.Forma == "Convenio" || pago.Forma == "CDC") {
          this.statspagoscliente.totalconvenio += pago.Monto;
        }
        if (pago.Forma == "Cheque") {
          let fechaInicial = new Date(pago.Fecha); // Asegúrate de proporcionar una fecha válida en formato "yyyy-mm-dd"
          let fechaFinal = new Date(pago.Vence);

          // Obtén la diferencia en milisegundos
          let diferenciaEnMilisegundos = fechaFinal - fechaInicial;
          let diasEntreFechas =
            diferenciaEnMilisegundos / (1000 * 60 * 60 * 24);
          if (diasEntreFechas > 4) {
            this.statspagos.totalchequefecha += pago.Monto;
          } else {
            this.statspagos.totalchequedia += pago.Monto;
          }
        }
        if (pago.Forma == "N.de Credito") {
          this.statspagoscliente.totalndecredito += pago.Monto;
        }
        if (pago.Forma == "Deposito") {
          this.statspagoscliente.totaldeposito += pago.Monto;
        }
      });
    },
    btnSearchProductByServiece() {
      if (!this.searchProdus) {
        alert("Debe ingresar un parametro de busqueda")
        return
      }
      this.getProdusByNameOrCode({ search: this.searchProdus })
    },
    setStatsVentasCliente() {
      this.statsventascliente = {
        total: 0,
        totalmonto: 0,
        totalpagado: 0,
        totalarticulos: 0,
        totalunidades: 0,
      };
      let numbersfolio = [];
      this.vtasserCliente.forEach((vta) => {
        this.statsventascliente.totalmonto += vta.Total;
        let search = numbersfolio.findIndex((item) => item == vta.Bnumero);
        if (search == -1) {
          numbersfolio.push(vta.Bnumero);
        }
        this.statsventascliente.total = numbersfolio.length;
        this.statsventascliente.totalpagado += vta.Montopagado;
        this.statsventascliente.totalarticulos += 1;
        this.statsventascliente.totalunidades += vta.Cantidad;
      });
    },
    btnAddCarrito(id) {
      if (!this.selectCliente.Fichan) {
        alert("debe seleccionar a un cliente");
        return;
      }
      if (!this.selectTipoDoc) {
        alert("debe seleccionar un tipo de documento");
        return;
      }
      if (!this.selectVendedor) {
        alert("debe seleccionar un vendedor");
        return;
      }
      let produ = this.produs.find((item) => item.Id == id);
      let producartsearch = this.carrito.findIndex((item) => item.Id == id);
      if (producartsearch != -1) {
        alert(
          "El producto ya esta en el carro, debe eliminar y volver agregar"
        );
        return;
      }
      if (this.carrito.length == 0) {
        if (this.uidVenta == "") {
          this.uidVenta = this.generateUUID();
        }
      }

      let total = parseInt(this.cantidadAgregarCarro * produ.Vtadeta);
      let descuento = 0;
      if (this.selectDescuento != "0") {
        let discount = parseInt(this.selectDescuento);
        descuento = total * (discount / 100);
        total = total - total * (discount / 100);
      }
      this.carrito.push({
        Nombre: this.selectCliente.Nombre,
        Cod: produ.Codigo,
        Nompro: produ.Descripcion,
        Cantidad: this.cantidadAgregarCarro,
        Unidad: "UN",
        Neto: 0,
        Iva: 0,
        Total: total,
        Pordes: parseInt(this.selectDescuento),
        Des: descuento,
        Bnumero: 0,
        Fichan: this.selectCliente.Fichan,
        Fecha: this.today,
        Tipo: "",
        Reg: this.selectCliente.Rut,
        Montopagado: 0,
        Pagadototal: produ.Ubicacion,
        Codven: this.selectVendedor,
        Fecha3: "2023-04-09 00:00:00",
        Usuario: this.user.Nombre,
        Id: produ.Id,
        Sucursal: this.user.Sucursal,
        Tipodte: "0",
        Estado: ".",
        Referencia: 0,
        Indtraslado: 0,
        Patente: "0",
        Frmt: "A",
        Subfamilia: produ.Subfamilia,
        Uid: this.uidVenta,
        Cantidad: parseInt(this.cantidadAgregarCarro),
        Precio: produ.Vtadeta,
        Imp: produ.Totalmesunit,
      });
      this.selectProdu = "";
      this.cantidadAgregarCarro = 1;
      this.selectDescuento = "0";
    },
    btnAddCarritoBorrador(id) {
      if (!this.selectCliente.Fichan) {
        alert("debe seleccionar a un cliente");
        return;
      }
      if (this.tabTipoDocBorrador == 0) {
        if (!this.selectTipoDocBorrador) {
          alert("debe seleccionar un tipo de documento");
          return;
        }
      }
      if (!this.selectVendedor) {
        alert("debe seleccionar un vendedor");
        return;
      }
      if (this.carritoBorrador.length == 0) {
        if (this.uidVentaBorrador == "") {
          this.uidVentaBorrador = this.generateUUID();
        }
      }

      let produ = this.produs.find((item) => item.Id == id);
      let total = parseInt(this.cantidadAgregarCarroBorrador * produ.Vtadeta);
      let descuento = 0;
      if (this.selectDescuentoBorrador != "0") {
        let discount = parseInt(this.selectDescuentoBorrador);
        descuento = total * (discount / 100);
        total = total - total * (discount / 100);
      }
      this.carritoBorrador.push({
        Nombre: this.selectCliente.Nombre,
        Cod: produ.Codigo,
        Nompro: produ.Descripcion,
        Cantidad: this.cantidadAgregarCarroBorrador,
        Unidad: "UN",
        Neto: 0,
        Iva: 0,
        Total: total,
        Pordes: parseInt(this.selectDescuentoBorrador),
        Des: descuento,
        Bnumero: 0,
        Fichan: this.selectCliente.Fichan,
        Fecha: new Date().toISOString().substring(0, 10),
        Tipo: "",
        Reg: this.selectCliente.Rut,
        Montopagado: 0,
        Pagadototal: produ.Ubicacion,
        Codven: this.selectVendedor,
        Fecha3: "2023-04-09 00:00:00",
        Usuario: this.user.Nombre,
        Id: produ.Codigo,
        Sucursal: this.user.Sucursal,
        Tipodte: "0",
        Estado: ".",
        Referencia: 0,
        Indtraslado: 0,
        Patente: "0",
        Frmt: "A",
        Subfamilia: produ.Subfamilia,
        Uid: this.uidVentaBorrador,
        Cantidad: parseInt(this.cantidadAgregarCarroBorrador),
        Precio: produ.Vtadeta,
        Imp: produ.Totalmesunit,
      });
      this.selectProduBorrador = "";
      this.cantidadAgregarCarroBorrador = 1;
      this.selectDescuentoBorrador = "0";
    },
    btnCloseDialogSii() {
      this.vtadoctributario = [];
      this.activeGenerarSii = false;
      this.vtadoctributarioCliente = "";
    },
    btnCloseDialogVtaBorradorDescargar() {
      this.vtadocBorradorDescarga = [];
      this.activeGenerarDocumentoBorradorDescargar = false;
    },
    async btnPagar() {
      this.activeLoadingPagar = true;
      if (this.carrito.length == 0) {
        alert("Debe ingresar los productos a vender");
        this.activeLoadingPagar = false;
        return;
      }
      if (this.selectMontoPagado == "0") {
        alert("Debe ingresar un monto de pago");
        this.activeLoadingPagar = false;
        return;
      }
      if (this.selectFormaPago == "") {
        alert("Debe seleccionar una forma de pago");
        this.activeLoadingPagar = false;
        return;
      }

      let setFormapago = this.selectFormaPago;
      if (
        this.selectFormaPago == "Tarjeta D/C" ||
        this.selectFormaPago == "Cheque" ||
        this.selectFormaPago == "CDC" ||
        this.selectFormaPago == "N.de Credito" ||
        this.selectFormaPago == "Canje" ||
        this.selectFormaPago == "Deposito"
      ) {
        if (this.selectReg == "") {
          alert("Debe ingresar una referencia al pago");
          this.activeLoadingPagar = false;
          return;
        }
        if (this.selectFormaPago == "Cheque") {
          if (this.selectFechaCheque == "") {
            alert("Debe ingresar la fecha del cheque");
            this.activeLoadingPagar = false;
            return;
          }
          if (this.selectBancoCheque == "") {
            alert("Debe ingresar el bancho del cheque del cheque");
            this.activeLoadingPagar = false;
            return;
          }
        }
      }
      this.carrito.forEach((element) => {
        // si es factura
        if (this.selectTipoDoc.Tipodte == "33") {
          if (element.Imp == 19) {
            element.Tipo = this.selectTipoDoc.Concepto;
            element.Tipodte = this.selectTipoDoc.Tipodte;
          } else {
            element.Tipo = "FACTURA EXENTA ELECTRONICA";
            element.Tipodte = "34";
          }
        }
        //boleta
        if (this.selectTipoDoc.Tipodte == "39") {
          //si es bactura
          if (element.Imp == 19) {
            element.Tipo = this.selectTipoDoc.Concepto;
            element.Tipodte = this.selectTipoDoc.Tipodte;
          } else {
            element.Tipo = "BOLETA EXENTA ELECTRONICA";
            element.Tipodte = "41";
          }
        }

        element.Reg = this.selectReg;
      });
      if (this.pagos.length == 0) {
        await this.postVtasserpaso({
          vtasserpaso: this.carrito,
          hospital: this.selectActiveCuentaHospital,
        });
        if (this.hospital) {
          this.selectCarroContinuar = [];
        }
      }
      let pago = {
        Id: 0,
        Fichan: this.selectCliente.Fichan,
        Nboleta: 0,
        Monto: parseInt(this.selectMontoPagado),
        Fecha: this.today,
        Forma: setFormapago,
        Vence: this.selectFechaCheque,
        Banco: this.selectBancoCheque,
        Serie: parseInt(this.selectReg),
        Ubicacion: this.selectVendedor,
        Glosa: this.selectTipoDoc.Concepto,
        Reg: "1",
        Usuario: this.selectVendedor,
        Fecha3: this.today,
        Sucursal: this.user.Sucursal,
      };
      let response = await this.postPagoVtasser({
        uid: this.uidVenta,
        pago: pago,
      });
      if (response.DocFirmados) {
        this.disabledCart = true;
        this.activeLoadingPagar = false;
        this.selectActiveCuentaHospital = false;
        this.pagos.push({
          monto: parseInt(this.selectMontoPagado),
          forma: setFormapago,
        });
        this.selectMontoPagado = 0;
        if (this.calculateTotalPagado >= this.calculateTotal) {
          this.disabledBtnPay = false;
          this.activeGenerarSii = true;
          response.DocFirmados.forEach((element) => {
            element.Cliente = this.selectCliente;
          });
          this.selectActiveCuentaHospital = false;
          this.vtadoctributario = response.DocFirmados;
          this.vtadoctributarioCliente = this.selectCliente;
          this.carrito = [];
          this.uidVenta = "";
          this.selectCliente = "";
          this.selectFormaPago = "";
          this.selectMontoPagado = 0;
          this.disabledCart = false;
          this.selectVendedor = "";
          this.pagos = [];
          this.selectCarroContinuar = [];
        }
      }
    },
    async btnPagarBorrador() {
      this.activeLoadingPagarBorrador = true;
      if (this.selectMontoPagadoBorrador == "0") {
        alert("Debe ingresar un monto");
        this.activeLoadingPagarBorrador = false;
        return;
      }
      if (this.selectFormaPagoBorrador == "") {
        alert("Debe seleccionar el método de pago");
        this.activeLoadingPagarBorrador = false;
        return;
      }
      let setFormapago = this.selectFormaPagoBorrador;
      if (
        this.selectFormaPagoBorrador == "Tarjeta D/C" ||
        this.selectFormaPagoBorrador == "Cheque" ||
        this.selectFormaPagoBorrador == "CDC" ||
        this.selectFormaPagoBorrador == "N.de Credito" ||
        this.selectFormaPagoBorrador == "Canje" ||
        this.selectFormaPagoBorrador == "Deposito"
      ) {
        if (this.selectRegBorrador == "") {
          alert("Debe ingresar una referencia al pago");
          this.activeLoadingPagarBorrador = false;
          return;
        }
        if (this.selectFormaPagoBorrador == "Cheque") {
          if (this.selectBancoChequeBorrador == "") {
            alert("Debe ingresar el banco del cheque al pago");
            this.activeLoadingPagarBorrador = false;
            return;
          }
        }
        if (this.selectFormaPagoBorrador == "Cheque") {
          if (this.selectFechaChequeBorrador == "") {
            alert("Debe ingresar la fecha del vencimiento del cheque al pago");
            this.activeLoadingPagarBorrador = false;
            return;
          }
        }
      }

      this.carritoBorrador.forEach((element) => {
        element.Tipo = this.selectTipoDocBorrador;
        element.Tipodte = "0";
        element.Reg = this.selectRegBorrador;
      });
      if (this.pagos.length == 0) {
        await this.postVtasserpasoBorrador({
          vtasserpaso: this.carritoBorrador,
        });
      }
      let pago = {
        Id: 0,
        Fichan: this.selectCliente.Fichan,
        Nboleta: 0,
        Monto: parseInt(this.selectMontoPagadoBorrador),
        Fecha: this.today,
        Forma: setFormapago,
        Vence: this.selectFechaChequeBorrador,
        Banco: this.selectBancoChequeBorrador,
        Serie: parseInt(this.selectRegBorrador),
        Ubicacion: this.selectVendedor,
        Glosa: this.selectTipoDocBorrador,
        Reg: "1",
        Usuario: this.selectVendedor,
        Fecha3: this.today,
        Sucursal: this.user.Sucursal,
      };
      let response = await this.postPagoVtasserBorrador({
        uid: this.uidVentaBorrador,
        pago: pago,
      });
      if (response.folio != 0) {
        this.activeLoadingPagarBorrador = false;
        this.pagosBorrador.push({
          monto: parseInt(this.selectMontoPagadoBorrador),
          forma: setFormapago,
        });
        this.selectMontoPagadoBorrador = 0;
        if (this.calculateTotalPagadoBorrador >= this.calculateTotalBorrador) {
          this.disabledBtnPayBorrador = false;
          this.activeGenerarDocumentoBorradorDescargar = true;
          this.carritoBorrador.forEach((element) => {
            element.Bnumero = response.Folio[0];
          });
          this.vtadocBorradorDescarga = [
            {
              Folio: response.Folio[0],
              Cliente: this.selectCliente,
              Vtasser: this.carritoBorrador,
            },
          ];
          this.carritoBorrador = [];
          this.selectCliente = "";
          this.selectFormaPagoBorrador = "";
          this.selectMontoPagadoBorrador = 0;
          this.disabledCartBorrador = false;
          this.selectVendedor = "";
          this.pagosBorrador = [];
        }
      }
    },

    async btnPagarVtaDiferencia() {
      this.pagos = [];
      let fichan = 0;
      let codven = "";
      let totalpagado = 0;
      let tipodoc = "";
      this.carrito = this.selectSearchVtabydoc;
      this.disabledBtnPay = false;
      this.selectSearchVtabydoc.forEach((element) => {
        this.uidVenta = element.Uid;
        fichan = element.Fichan;
        codven = element.Codven;
        totalpagado += element.Montopagado;
        if (element.Tipodte == "39") {
          tipodoc = {
            Concepto: "BOLETA ELECTRÓNICA",
            Id: 8,
            Idnuevo: 0,
            Impresora: "Predeterminada",
            Impuesto: 19,
            Sucursal: "El Bosque",
            Tipodte: "39",
            Ultimo: 36118,
          };
        }
      });
      this.selectTipoDoc = tipodoc;
      this.pagos.push({
        monto: totalpagado,
        forma: "",
      });
      this.selectCliente = await this.getClienteReturnByFichan(fichan);

      this.selectVendedor = codven;
      this.opcionVenta = false;
      this.activeDialogoPagarPendiente = false;
      this.disabledCart = true;
      this.opcionVenta = true;
    },
    async btnCreateCarritoPendiente() {
      this.activeLoadingCreateCarritoPendiente = true;
      if (!this.selectCliente.Fichan) {
        alert("debe seleccionar a un cliente");
        return;
      }
      this.carrito.forEach((element) => {
        // si es factura
        if (this.selectTipoDoc.Tipodte == "33") {
          if (element.Imp == 19) {
            element.Tipo = this.selectTipoDoc.Concepto;
            element.Tipodte = this.selectTipoDoc.Tipodte;
          } else {
            element.Tipo = "FACTURA EXENTA ELECTRONICA";
            element.Tipodte = "34";
          }
        }
        //boleta
        if (this.selectTipoDoc.Tipodte == "39") {
          //si es bactura
          if (element.Imp == 19) {
            element.Tipo = this.selectTipoDoc.Concepto;
            element.Tipodte = this.selectTipoDoc.Tipodte;
          } else {
            element.Tipo = "BOLETA EXENTA ELECTRONICA";
            element.Tipodte = "41";
          }
        }
      });
      if (this.pagos.length == 0) {
        await this.postVtasserpaso({
          vtasserpaso: this.carrito,
          hospital: this.selectActiveCuentaHospital,
        });
        this.uidVenta = "";
        this.carrito = [];
        this.selectCliente = "";
      }
    },
    btnSearchCliente() {
      this.getClientes(this.search);
    },
    btnSelectCliente(cliente) {
      this.selectCliente = cliente;
      this.activeSearchCliente = false;
    },
    btnSelectClientePerdido() {
      this.selectCliente = {
        Id: 0,
        Fichan: 999999,
        Nombre: "NN",
        Amo: "NN",
        Fono: "",
        Nacimiento: "",
        Raza: "",
        Direccion: "",
        Comuna: "",
        Ciudad: "",
        Email: "",
        Sexo: "",
        Fotoactiva: "",
        Madre: "",
        Padre: "",
        Peso: "",
        Altura: "",
        Edad: "",
        Reg: "",
        Color: "",
        Esp: "",
        Insc: "",
        Microchip: "",
        Fichaant: "",
        Clinica: "",
        Drtratante: "",
        Peluqueria: "",
        Rut: "1-9",
      };
    },
    btnSelectClienteCtaCte(cliente) {
      this.selectCliente = cliente;
      this.activeSearchCliente = false;
      this.getVtasserByCliente({ fichan: cliente.Fichan });
      this.getPagosVtasserByCliente({ fichan: cliente.Fichan });
    },
    loadDataDefault() {
      if (!this.user.Nombre) {
        this.$router.push({ path: "/" });
      }
    },
    btnFiltrarMovimientoCajaByDate() {
      this.menuDateCaja = false;
      this.getMovimientosCajaByDate({
        start_date: this.dateCaja,
        end_date: this.dateCaja,
        sucursal: this.user.Sucursal,
        plancuentas: "",
      });
      this.getPagosVtasserByDate({
        startdate: this.dateCaja,
        enddate: this.dateCaja,
      });
    },
    btnFiltrarMovimientosCajaByRangeDate() {
      this.getMovimientosCajaByDateRange({
        start_date: this.dateStartMovimientosCaja,
        end_date: this.dateEndMovimientosCaja,
        sucursal: this.user.Sucursal,
        plancuentas: this.selectPlanCuentaCreate,
      });
    },
    //seleccionar menu
    btnSelectOptionMenu(option) {
      switch (option) {
        case "sale":
          //validad el menu
          if (this.selectActiveCuentaHospital) {
            this.selectActiveCuentaHospital = false;
            this.carrito = [];
            this.selectCliente = "";
            this.disabledCart = false;
          }
          this.opcionContinuarVenta = false;
          this.opcionBuscarProducto = false;
          this.opcionVerVentas = false;
          this.opcionVerPagos = false;
          this.opcionVerCtaCte = false;
          this.opcionEditarDocs = false;
          this.opcionVerCaja = false;
          this.opcionVenta = true;

          break;
        case "buscarProducto":
          this.opcionContinuarVenta = false;
          this.opcionVenta = false;
          this.opcionVerVentas = false;
          this.opcionVerPagos = false;
          this.opcionVerCtaCte = false;
          this.opcionEditarDocs = false;
          this.opcionVerCaja = false;
          this.opcionBuscarProducto = true;
          break;
        case "continuarVenta":
          this.actionSetCustomerEmpty("");
          this.actionSelectFichaCuentaHospital("");
          this.opcionVenta = false;
          this.opcionBuscarProducto = false;
          this.opcionVerVentas = false;
          this.opcionVerPagos = false;
          this.opcionVerCtaCte = false;
          this.opcionEditarDocs = false;
          this.opcionVerCaja = false;
          this.opcionContinuarVenta = true;
          break;
        case "sales":
          this.opcionVenta = false;
          this.opcionBuscarProducto = false;
          this.opcionContinuarVenta = false;
          this.opcionVerPagos = false;
          this.opcionVerCtaCte = false;
          this.opcionEditarDocs = false;
          this.opcionVerCaja = false;
          this.opcionVerVentas = true;
          break;
        case "pagos":
          this.opcionVenta = false;
          this.opcionBuscarProducto = false;
          this.opcionContinuarVenta = false;
          this.opcionVerVentas = false;
          this.opcionVerCtaCte = false;
          this.opcionEditarDocs = false;
          this.opcionVerCaja = false;
          this.opcionVerPagos = true;
          break;
        case "vercaja":
          this.selectOpcionCaja();
          this.opcionVenta = false;
          this.opcionBuscarProducto = false;
          this.opcionContinuarVenta = false;
          this.opcionVerVentas = false;
          this.opcionVerCtaCte = false;
          this.opcionEditarDocs = false;
          this.opcionVerPagos = false;
          this.opcionVerCaja = true;
          break;
        case "ctacte":
          this.opcionVenta = false;
          this.opcionBuscarProducto = false;
          this.opcionContinuarVenta = false;
          this.opcionVerVentas = false;
          this.opcionVerPagos = false;
          this.opcionEditarDocs = false;
          this.opcionVerCaja = false;
          this.opcionVerCtaCte = true;
          break;
        case "editardoc":
          this.opcionVenta = false;
          this.opcionBuscarProducto = false;
          this.opcionContinuarVenta = false;
          this.opcionVerVentas = false;
          this.opcionVerPagos = false;
          this.opcionVerCaja = false;
          this.opcionVerCtaCte = false;
          this.opcionEditarDocs = true;
          break;
      }
      this.menuCentral = false;
    },
    async selectOpcionCaja() {
      this.vtasser = [];
      this.pagosvtaser = [];
      let date = this.setDateNow();
      await this.getVtasserByDate({
        startdate: date,
        enddate: date,
      });
      await this.getPagosVtasserByDate({
        startdate: date,
        enddate: date,
      });
    },
    async btnSerachSaleProdu(produ) {
      this.selectSearchProdu = produ.Descripcion;
      this.selectProdu = produ;
      this.inventarios = await this.getInventariosByCodigo({
        codigo: produ.Codigo,
      });
    },
    generateUUID() {
      // Public Domain/MIT
      var d = new Date().getTime(); //Timestamp
      var d2 =
        (typeof performance !== "undefined" &&
          performance.now &&
          performance.now() * 1000) ||
        0; //Time in microseconds since page-load or 0 if unsupported
      return "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = Math.random() * 16; //random number between 0 and 16
        if (d > 0) {
          //Use timestamp until depleted
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        } else {
          //Use microseconds since page-load if supported
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      });
    },
    loadCarritosPendientes() {
      this.getVtasserpasoPendientes();
    },
    async btnSelectCarritoPendienteByUid(uid) {
      this.selectCarroContinuar = await this.getVtasserpasoCarritoUid({
        uid: uid,
      });
    },
    async btnConfirmaDeleteCarrito() {
      this.activeLoadingCancelarCarrito = true;
      let uid = "";
      this.selectCarroContinuar.forEach((element) => {
        uid = element.Uid;
      });
      this.postCancelarCarrito({ uid: uid });
    },
    btnDeleteProduCart(codigo) {
      let indexSearch = this.carrito.findIndex((item) => item.Cod == codigo);
      this.carrito.splice(indexSearch, 1);
    },
    btnDeleteProduCartBorrador(codigo) {
      let indexSearch = this.carritoBorrador.findIndex(
        (item) => item.Cod == codigo
      );
      this.carritoBorrador.splice(indexSearch, 1);
    },
    async btnContinuarCarritoPendiente() {
      if (this.selectActiveCuentaHospital) {
        if (this.selectTipoDoc == "") {
          alert("seleccione el tipo de documento para la cuenta");
          return;
        }
      }
      let fichan = 0;
      let codven = "";
      this.carrito = this.selectCarroContinuar;
      let typedoc = "";
      this.selectCarroContinuar.forEach((element) => {
        this.uidVenta = element.Uid;
        fichan = element.Fichan;
        codven = element.Codven;

        if (element.Tipodte == "39" || element.Tipodte == "41") {
          typedoc = "bol";
        }
        if (element.Tipodte == "33" || element.Tipodte == "34") {
          typedoc = "fact";
        }
      });

      if (typedoc != "") {
        if (typedoc == "bol") {
          this.selectTipoDoc = {
            Concepto: "BOLETA ELECTRONICA",
            Id: 8,
            Idnuevo: 0,
            Impresora: "Predeterminada",
            Impuesto: 19,
            Sucursal: "El Bosque",
            Tipodte: "39",
            Ultimo: 36118,
          };
        } else {
          this.selectTipoDoc = {
            Concepto: "FACTURA ELECTRÓNICA",
            Id: 14,
            Idnuevo: 0,
            Impresora: "Predeterminada",
            Impuesto: 19,
            Sucursal: "El Bosque",
            Tipodte: "33",
            Ultimo: 5,
          };
        }
      }
      this.selectCliente = await this.getClienteReturnByFichan(fichan);
      this.selectVendedor = codven;
      this.opcionContinuarVenta = false;
      this.opcionVenta = true;
      this.disabledCart = true;
    },
    async btnSearchVtasserByDocumento(vta) {
      this.selectSearchVtabydoc = [];
      this.activeDialogoPagarPendiente = true;
      this.activeLoadingSearchVtasDiferenciaPago = true;
      this.selectSearchVtabydoc = await this.getVtasserByDocumento({
        ndoc: vta.Bnumero,
        tipodoc: vta.Tipodte,
        fichan: vta.Fichan,
        fecha: vta.Fecha,
      });
      if (this.selectSearchVtabydoc.length > 0) {
        this.activeLoadingSearchVtasDiferenciaPago = false;
      }
    },
    async btnSearchVtasserByDocumentoImprimir(vta) {
      this.selectSearchVtabydocPrint = this.vtasser.filter(
        (item) => item.Bnumero == vta.Bnumero
      );
      this.activeDialogoImprimirDocVta = true;
      let total = 0;
      let totalpagado = 0;
      this.activePrintDoc = false;
      let fichan = 0;
      this.selectSearchVtabydocPrint.forEach((element) => {
        total += element.Total;
        totalpagado += element.Montopagado;
        fichan = element.Fichan;
      });
      let cliente = await this.getClienteReturnByFichan(fichan);
      this.dte.vtaser = this.selectSearchVtabydocPrint;
      this.dte.cliente = cliente;
      if (totalpagado >= total) {
        this.activePrintDoc = true;
      }
    },
    async btnCancelarSearchVtasserByDocumento() {
      this.selectSearchVtabydoc = [];
      this.activeDialogoPagarPendiente = false;
    },
    async btnCancelarSearchVtasserByDocumentoImprimir() {
      this.selectSearchVtabydocPrint = [];
      this.activeDialogoImprimirDocVta = false;
      this.activePrintDoc = false;
    },
    actionSetDoc(typodoc) {
      let setdoc = "0";

      switch (typodoc) {
        case "Factura":
          setdoc = "30";
          break;
        case "Factura de ventas y servicios no afectos o exentos de IVA":
          setdoc = "32";
          break;
        case "Factura electrónica":
          setdoc = "33";
          break;
        case "Factura no afecta o exenta electrónica":
          setdoc = "34";
          break;
        case "Boleta":
          setdoc = "35";
          break;
        case "Boleta exenta":
          setdoc = "38";
          break;
        case "Boleta electrónica":
          setdoc = "39";
          break;
        case "Liquidación factura":
          setdoc = "40";
          break;
        case "Boleta exenta electrónica":
          setdoc = "41";
          break;
        case "Liquidación factura electrónica":
          setdoc = "43";
          break;
        case "Factura de compra":
          setdoc = "45";
          break;
        case "Factura de compra electrónica":
          setdoc = "46";
          break;
        case "Pago electrónico":
          setdoc = "48";
          break;
        case "Guía de despacho":
          setdoc = "50";
          break;
        case "Guía de despacho electrónica":
          setdoc = "52";
          break;
        case "Nota de débito":
          setdoc = "55";
          break;
        case "Nota de débito electrónica":
          setdoc = "56";
          break;
        case "Nota de crédito":
          setdoc = "60";
          break;
        case "Nota de crédito electrónica":
          setdoc = "61";
          break;
        case "Liquidación":
          setdoc = "103";
          break;
        case "Factura de exportación electrónica":
          setdoc = "110";
          break;
        case "Nota de débito de exportación electrónica":
          setdoc = "111";
          break;
        case "Nota de crédito de exportación electrónica":
          setdoc = "112";
          break;
      }
      return setdoc;
    },
    async btnFilterDateVtaser() {
      this.menuDateFilterVentas = false;
      await this.getVtasserByDate({
        startdate: this.dateFilterVentas,
        enddate: this.dateFilterVentas,
      });
    },
    async btnFilterDatePagosVtasser() {
      this.menuDateFilterPago = false;
      await this.getPagosVtasserByDate({
        startdate: this.dateFilterPagos,
        enddate: this.dateFilterPagos,
      });
    },
    async btnFilterDateCaja() {
      this.menuDateFilterCaja = false;
      await this.getVtasserByDate({
        startdate: this.dateFilterCaja,
        enddate: this.dateFilterCaja,
      });

      await this.getPagosVtasserByDate({
        startdate: this.dateFilterCaja,
        enddate: this.dateFilterCaja,
      });
    },
    //movimiento caja
    btnCreateMovimientoCaja() {
      this.activeLoadingCreateMovimientoCaja = true;
      let movimientoCaja = {
        Fecha: this.dateCaja,
        Boleta: parseInt(this.selectNDocMovimientoCaja),
        Var1: ".",
        Monto: parseInt(this.selectMontoMovimientoCaja),
        Familia: this.selectPlanCuentaCreate,
        Concepto: this.selectConceptoMovimientoCaja,
        Rut: "0",
        Hora: "1899-12-30",
        Var2: "0",
        Var3: "0",
        Sucursal: this.user.Sucursal,
      };
      this.postMovimientoCaja({
        movimientoCaja: movimientoCaja,
        date: this.dateCaja,
        sucursal: this.user.Sucursal,
      });
    },
    btnCrearCierreCaja() {
      this.activeLoadingCreateCierreCaja = true;
      this.postCierreCaja({
        date: this.dateCaja,
        sucursal: this.user.Sucursal,
      });
    },

    //exportaciond excel
    btnExportExcelVtasser: function () {
      let set_sheet = [];
      this.vtasser.forEach((element) => {
        let setSheet = {};
        setSheet.Bnumero = element.Bnumero;
        setSheet.Tipo = element.Tipo;
        setSheet.Nombre = element.Nombre;
        setSheet.Fichan = element.Fichan;
        setSheet.Nompro = element.Nompro;
        setSheet.Cantidad = element.Cantidad;
        setSheet.Precio = element.Precio;
        setSheet.Des = element.Des;
        setSheet.Total = element.Total;
        setSheet.Montopagado = element.Montopagado;
        setSheet.Sucursal = element.Sucursal;
        setSheet.Fecha = element.Fecha;
        set_sheet.push(setSheet);
      });
      let data = XLSX.utils.json_to_sheet(set_sheet, {
        header: [
          "Bnumero",
          "Tipo",
          "Nombre",
          "Fichan",
          "Nompro",
          "Cantidad",
          "Precio",
          "Des",
          "Total",
          "Montopagado",
          "Sucursal",
          "Fecha",
        ],
      });
      data["A1"].v = "N ° doc";
      data["B1"].v = "Tipo Doc";
      data["C1"].v = "Cliente";
      data["D1"].v = "Fichan n°";
      data["E1"].v = "Producto o servicio";
      data["F1"].v = "Cantidad";
      data["G1"].v = "Precio";
      data["H1"].v = "Descuento";
      data["I1"].v = "Total";
      data["J1"].v = "Monto pagado";
      data["K1"].v = "Sucursal";
      data["L1"].v = "Fecha";

      const workbook = XLSX.utils.book_new();
      let today_text = new Date().toISOString().substring(0, 10);
      const filename = "ventas" + today_text;
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    btnExportExcelPagos: function () {
      let set_sheet = [];
      this.pagosvtaser.forEach((element) => {
        let setSheet = {};
        setSheet.NBoleta = element.Nboleta;
        setSheet.Glosa = element.Glosa;
        setSheet.Forma = element.Forma;
        setSheet.Serie = element.Serie;
        setSheet.Monto = element.Monto;
        setSheet.Sucursal = element.Sucursal;
        set_sheet.push(setSheet);
      });
      let data = XLSX.utils.json_to_sheet(set_sheet, {
        header: ["NBoleta", "Glosa", "Forma", "Serie", "Monto", "Sucursal"],
      });
      data["A1"].v = "N ° doc";
      data["B1"].v = "Tipo doc";
      data["C1"].v = "Forma";
      data["D1"].v = "Referencia forma";
      data["E1"].v = "Monto";
      data["F1"].v = "Sucursal";

      const workbook = XLSX.utils.book_new();
      let today_text = new Date().toISOString().substring(0, 10);
      const filename = "pagos" + today_text;
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    //hospital

    btnLimpiarFormVenta() {
      this.disabledBtnPay = false;
      this.selectActiveCuentaHospital = false;
      this.carrito = [];
      this.uidVenta = "";
      this.selectCliente = "";
      this.selectFormaPago = "";
      this.selectMontoPagado = 0;
      this.disabledCart = false;
      this.selectVendedor = "";
      this.pagos = [];
    },
    loadingStartCuentaHospital() {
      this.actionSelectFichaCuentaHospital("");
      if (this.selectFichaCuentaHospital) {
        this.selectFichaCuenta = this.selectFichaCuentaHospital;
        this.btnSelectCuentaHospital();
      }
    },

    async btnSelectCuentaHospital() {
      this.actionSelectFichaCuentaHospital(this.selectFichaCuenta);
      if (!this.cliente.Ficha) {
        await this.getClienteByFichan(this.selectFichaCuenta);
      }
      this.selectCuenta = this.ventasdepaso.filter(
        (item) =>
          (item.Ficha == this.selectFichaCuenta) & (item.Origen == "HTS")
      );
      this.uidVenta = this.generateUUID();
      let setcart = [];
      this.selectCuenta.forEach((element) => {
        let produSearch = this.produs.find(
          (item) => item.Codigo == element.Codigo
        );
        let vta = {
          Nombre: element.Mascota,
          Cod: element.Codigo,
          Nompro: element.Descripcion,
          Cantidad: element.Cantidad,
          Unidad: "0",
          Neto: element.Subtotal,
          Iva: element.Impuesto,
          Total: element.Total,
          Pordes: 0,
          Des: element.Descuento,
          Bnumero: 0,
          Fichan: element.Ficha,
          Fecha: this.today,
          Tipo: "",
          Reg: "",
          Montopagado: 0,
          Pagadototal: element.Familia,
          Codven: element.Var3,
          Fecha3: "2023-04-09 00:00:00",
          Usuario: "nn",
          Id: element.Codigo,
          Sucursal: element.Sucursal,
          Tipodte: "0",
          Estado: ".",
          Referencia: 0,
          Indtraslado: 0,
          Patente: "0",
          Frmt: "A",
          Subfamilia: produSearch.Subfamilia,
          Uid: this.uidVenta,
          Precio: element.Precio,
          Imp: produSearch.Totalmesunit,
        };
        setcart.push(vta);
      });
      this.selectCarroContinuar = setcart;
      this.selectActiveCuentaHospital = true;
    },
    async btnSelectCuentaCliente() {
      this.actionSelectFichaCuentaHospital(this.selectFichaCuenta);
      if (!this.cliente.Ficha) {
        await this.getClienteByFichan(this.selectFichaCuenta);
      }
      this.selectCuenta = this.ventasdepaso.filter(
        (item) =>
          (item.Ficha == this.selectFichaCuenta) & (item.Origen == "Ventas")
      );
      this.uidVenta = this.generateUUID();
      let setcart = [];
      this.selectCuenta.forEach((element) => {
        let produSearch = this.produs.find(
          (item) => item.Codigo == element.Codigo
        );
        let vta = {
          Nombre: element.Mascota,
          Cod: element.Codigo,
          Nompro: element.Descripcion,
          Cantidad: element.Cantidad,
          Unidad: "0",
          Neto: element.Subtotal,
          Iva: element.Impuesto,
          Total: element.Total,
          Pordes: 0,
          Des: element.Descuento,
          Bnumero: 0,
          Fichan: element.Ficha,
          Fecha: this.today,
          Tipo: "",
          Reg: "",
          Montopagado: 0,
          Pagadototal: element.Familia,
          Codven: element.Var3,
          Fecha3: "2023-04-09 00:00:00",
          Usuario: "nn",
          Id: element.Codigo,
          Sucursal: element.Sucursal,
          Tipodte: "0",
          Estado: ".",
          Referencia: 0,
          Indtraslado: 0,
          Patente: "0",
          Frmt: "A",
          Subfamilia: produSearch.Subfamilia,
          Uid: this.uidVenta,
          Precio: element.Precio,
          Imp: produSearch.Totalmesunit,
        };
        setcart.push(vta);
      });
      this.selectCarroContinuar = setcart;
      this.selectActiveCuentaHospital = true;
    },
    async btnSearchVtaserBorrador() {
      this.activeLoadingSearchVtaserBorrador = true;
      if (this.selectNdocSearchBorrador == "") {
        alert("Debe ingresar el n° de documento a buscar");
        return;
      }
      if (this.selectTipoDocBorrador == "") {
        alert("Debe ingresar el n° de documento a buscar");
        return;
      }
      let search = await this.getVtasserBorradorByTipodoc({
        ndoc: this.selectNdocSearchBorrador,
        tipodoc: this.selectTipoDocBorrador,
      });
      if (search) {
        this.activeLoadingSearchVtaserBorrador = false;
      }
      let setSearch = {
        vtaser: [],
        cliente: "",
        fecha: "",
        total: 0,
        montopagado: 0,
        fichan: 0,
      };
      search.forEach((element) => {
        setSearch.fichan = element.Fichan;
        setSearch.cliente = element.Nombre;
        setSearch.fecha = element.Fecha;
        setSearch.total += element.Total;
        setSearch.montopagado += element.Montopagado;
        setSearch.vtaser.push(element);
      });
      this.vtaserBorradorSearch = setSearch;
    },
    async btnPagarVtaBorrador() {
      this.pagosBorrador = [];
      let fichan = 0;
      let codven = "";
      let totalpagado = 0;
      let tipodoc = "";
      let tipodte = "";
      let tipodtecodigo = "";
      this.carritoBorrador = this.vtaserBorradorSearch.vtaser;
      this.disabledBtnPayBorrador = false;
      this.vtaserBorradorSearch.vtaser.forEach((element) => {
        this.uidVentaBorrador = element.Uid;
        fichan = element.Fichan;
        codven = element.Codven;
        totalpagado += element.Montopagado;
        tipodte = element.Tipo;
        tipodtecodigo = element.Tipodte;
      });
      this.activeShowPagoBorrador = true;
      tipodoc = this.tablaimptos.find(
        (item) => item.Concepto == tipodte && item.Tipodte == tipodtecodigo
      );
      this.selectTipoDocBorrador = tipodoc.Concepto;
      this.pagosBorrador.push({
        monto: this.vtaserBorradorSearch.montopagado,
        forma: "",
      });
      this.selectCliente = await this.getClienteReturnByFichan(fichan);

      this.selectVendedor = codven;

      this.activeShowSearchBorrador = false;
    },
    async btnCrearDocumentoBorrador() {
      switch (this.tabTipoDocBorrador) {
        case 0:
          alert("verificar punto");
          this.btnCreateBorradorSinPago();
          break;
        case 1:
          this.btnCrearGuiaTipoBorrador();
          break;
        case 2:
          this.btnCrearNotaTipo();
          break;
        case 3:
          alert("cotizacion");
          break;
      }
    },

    //notas
    async btnCrearNuevaRerefenciaNota() {
      if (this.selectNumdocrefNota == "") {
        alert("Debe ingresar el n° del documento de referencia");
        return;
      }
      if (this.selectTipoImpuestoNotaBorrador == "") {
        alert("Debe selecionar el tipo del documento de referencia");
        return;
      }
      if (this.selectRazonReferenciaNota == "") {
        alert("Debe selecionar la razon de la la nota");
        return;
      }
      this.activeLoadingNotaValidarDte = true;
      let result = await this.getVtasserByTipodocValidarDte({
        ndoc: this.selectNumdocrefNota,
        tipodoc: this.selectTipoImpuestoNotaBorrador.Concepto,
      });
      if (result.length > 0) {
        this.activeLoadingNotaValidarDte = false;
        let total = 0;
        result.forEach((element) => {
          total += element.Total;
        });
        let referencia = {
          Id: 0,
          Numlinearef: "",
          Tipodocumentoref: this.selectTipoImpuestoNotaBorrador.Tipodte,
          Folioref: parseInt(this.selectNumdocrefNota),
          Fecharef: result[0].Fecha,
          Codigoref: this.selectRazonReferenciaNota.codigo,
          Razonref: this.selectRazonReferenciaNota.tipo,
          Numdocto: 0,
          Tipodocto: this.selectTipoNotaBorrador.Tipodte,
          Total: total,
        };

        this.selectReferenciaNotaBorrador.push(referencia);
        this.selectTipoImpuestoNotaBorrador = "";
        this.selectFechaDocrefNota = "";
        this.selectNumdocrefNota = "";
        this.selectRazonReferenciaNota = "";
      } else {
        alert("no existe documento de referencia");
        this.activeLoadingNotaValidarDte = false;
      }
    },
    async btnCrearNotaTipo() {
      if (this.selectTipoNotaBorrador == "") {
        alert("Debe seleccionar el tipo de nota a crear ");
        return;
      }
      if (this.carritoBorrador == 0) {
        alert("No hay ningun producto o servicio en el carro ");
        return;
      }
      if (this.selectTipoImptoNota == "") {
        alert("Debe seleccionar el tipo de impuesto ");
        return;
      }
      if (this.selectReferenciaNotaBorrador.length == 0) {
        alert("Debe al menos ingresar una referencia a la nota");
        return;
      }
      this.activeLoadingCreateDocumento = true;

      this.carritoBorrador.forEach((element) => {
        element.Tipo = this.selectTipoNotaBorrador.Concepto;
        element.Tipodte = this.selectTipoNotaBorrador.Tipodte;
      });

      let response = await this.postVtasserpasoNotaBorrador({
        nota: {
          Vtasserpasov1: this.carritoBorrador,
          ReferenciasDoc: this.selectReferenciaNotaBorrador,
        },
      });
      if (response.DocFirmados.length > 0) {
        this.activeGenerarDocumentoBorradorDescargar = true;
        this.vtadocBorradorDescarga = [
          {
            Folio: response.DocFirmados[0].Folio,
            Cliente: this.selectCliente,
            Vtasser: response.DocFirmados[0].Vtasser,
          },
        ];
        this.activeLoadingCreateDocumento = false;
        this.carritoBorrador = [];
        this.selectReferenciaNotaBorrador = [];
        this.selectCliente = "";
        this.disabledCartBorrador = false;
        this.selectVendedor = "";
      }
    },
    async btnCreateBorradorSinPago() {
      if (this.carritoBorrador == 0) {
        alert("No hay ningun producto o servicio en el carro ");
        return;
      }
      if ((this.selectCliente = "")) {
        alert("No hay ningun cliente seleccioando ");
        return;
      }

      this.activeLoadingCreateDocumento = true;
      this.carritoBorrador.forEach((element) => {
        element.Tipo = this.selectTipoDocBorrador;
        element.Tipodte = "0";
        element.Reg = this.selectRegBorrador;
      });
      await this.postVtasserpasoBorrador({
        vtasserpaso: this.carritoBorrador,
      });

      this.activeGenerarDocumentoBorradorDescargar = true;
      this.carritoBorrador.forEach((element) => {
        element.Bnumero = "";
      });
      this.vtadocBorradorDescarga = [
        {
          Folio: "0",
          Cliente: this.selectCliente,
          Vtasser: this.carritoBorrador,
        },
      ];
      this.carritoBorrador = [];
      this.selectCliente = "";
      this.disabledCartBorrador = false;
      this.selectVendedor = "";
      this.activeLoadingCreateDocumento = false;
    },
    async btnCrearGuiaTipoBorrador() {
      if (this.carritoBorrador == 0) {
        alert("No hay ningun producto o servicio en el carro ");
        return;
      }
      if (this.selectTipoMotivoGuia == "") {
        alert("Debe seleccionar el motivo de la guia");
        return;
      }
      if (this.selectDireccionGuia == "") {
        alert("Debe ingresar la direccion de la guia");
        return;
      }
      if (this.selectPatenteGuia == "") {
        alert("Debe ingresar la patente de la guia");
        return;
      }

      if (this.selectCiudadGuia == "") {
        alert("Debe ingresar la ciudad de la guia");
        return;
      }
      if (this.selectComunaGuia == "") {
        alert("Debe ingresar la comuna de la guia");
        return;
      }

      this.activeLoadingCreateDocumento = true;

      this.carritoBorrador.forEach((element) => {
        element.Tipo = "GUIA DE DESPACHO ELECTRONICA";
        element.Tipodte = "52";
        element.Indtraslado = "1";
        element.Referencia = 0;
        element.Patente = this.selectPatenteGuia;
      });

      let response = await this.postVtasserpasoGuiaBorrador({
        guia: {
          Vtasserpasov1: this.carritoBorrador,
          Direccion: {
            Direccion: this.selectDireccionGuia,
            Comunda: this.selectComunaGuia,
            Ciudad: this.selectCiudadGuia,
            MotivoGuia: this.selectTipoMotivoGuia,
          },
        },
      });
      if (response.DocFirmados.length > 0) {
        this.activeGenerarDocumentoBorradorDescargar = true;
        this.vtadocBorradorDescarga = [
          {
            Folio: response.DocFirmados[0].Folio,
            Cliente: this.selectCliente,
            Vtasser: response.DocFirmados[0].Vtasser,
          },
        ];
        this.activeLoadingCreateDocumento = false;
        this.carritoBorrador = [];
        this.selectReferenciaNotaBorrador = [];
        this.selectCliente = "";
        this.disabledCartBorrador = false;
        this.selectVendedor = "";
      }
    },
    btnActiveSearchGuia() {
      this.activeShowSearchGuia = true;
      this.activeShowSearchBorrador = true;
    },
    async btnBuscarGuia() {
      if (this.tipoSearchGuia == "date") {
        if (!this.selectFechaBusquedaGuia) {
          alert("Debe ingresar la fecha de la busqueda de la guia");
          return;
        }
        this.selectNumerodocGuiaSearch = "";
      }
      if (this.tipoSearchGuia == "ndoc") {
        if (!this.selectNumerodocGuiaSearch) {
          alert("Debe ingresar el n° de la guia");
          return;
        }
        this.selectFechaBusquedaGuia = "";
      }
      this.selectSearchGuiasBuscadas = [];
      let result = await this.getVtaserByFechaDocTipodoc({
        fecha: this.selectFechaBusquedaGuia,
        tipodoc: "52",
        ndoc: this.selectNumerodocGuiaSearch,
      });
      result.forEach((element) => {
        let indexSearch = -1;
        indexSearch = this.selectSearchGuiasBuscadas.findIndex(
          (item) => item.Bnumero == element.Bnumero
        );
        if (indexSearch == -1) {
          this.selectSearchGuiasBuscadas.push({
            Fecha: element.Fecha,
            Bnumero: element.Bnumero,
            Total: element.Total,
            Items: [element],
          });
        } else {
          this.selectSearchGuiasBuscadas[indexSearch].Total += element.Total;
          this.selectSearchGuiasBuscadas[indexSearch].Items.push(element);
        }
      });
    },
    async btnBuscarNota() {
      if (!this.selectSearchTipoNota) {
        alert("Debe ingresar el tipo de nota a buscar");
        return;
      }
      let tipodte = "0";
      if (this.selectSearchTipoNota == "Credito") {
        tipodte = "61";
      }
      if (this.selectSearchTipoNota == "Debito") {
        tipodte = "56";
      }
      if (this.tipoSearchNota == "date") {
        if (!this.selectFechaBusquedaNota) {
          alert("Debe ingresar la fecha de la busqueda de la nota");
          return;
        }
        this.selectNumerodocNotaSearch = "";
      }
      if (this.tipoSearchNota == "ndoc") {
        if (!this.selectNumerodocNotaSearch) {
          alert("Debe ingresar el n° de la nota");
          return;
        }
        this.selectFechaBusquedaNota = "";
      }
      this.selectSearchNotasBuscadas = [];
      let result = await this.getVtaserByFechaDocTipodoc({
        fecha: this.selectFechaBusquedaNota,
        tipodoc: tipodte,
        ndoc: this.selectNumerodocNotaSearch,
      });
      //console.log("el resultado", result);
      result.forEach((element) => {
        let indexSearch = -1;
        indexSearch = this.selectSearchNotasBuscadas.findIndex(
          (item) => item.Bnumero == element.Bnumero
        );
        if (indexSearch == -1) {
          this.selectSearchNotasBuscadas.push({
            Fecha: element.Fecha,
            Bnumero: element.Bnumero,
            Total: element.Total,
            Items: [element],
          });
        } else {
          this.selectSearchNotasBuscadas[indexSearch].Total += element.Total;
          this.selectSearchNotasBuscadas[indexSearch].Items.push(element);
        }
      });
    },
    async btnActiveSearchNota() {
      this.activeShowSearchNota = true;
      this.activeShowSearchBorrador = true;
    },
    async btnPrintVtaserByDoc(item) {
      let cliente = "";
      let folio = "";
      item.Items.forEach((element) => {
        cliente = element.Fichan;
        folio = element.Bnumero;
      });
      let searchCliente = await this.getClienteReturnByFichan(cliente);
      this.vtadocBorradorDescarga = [
        {
          Folio: folio,
          Cliente: searchCliente,
          Vtasser: item.Items,
        },
      ];
      this.activeGenerarDocumentoBorradorDescargar = true;
    },
  },
  created() {
    this.today = this.setDateNow();
    this.dateCaja = this.setDateNowString();
    this.dateFilterCaja = this.setDateNowString();
    this.dateFilterVentas = this.setDateNowString();
    this.dateFilterPagos = this.setDateNowString();
    this.selectFechaCheque = this.setDateNowString();
    this.selectFechaChequeBorrador = this.setDateNowString();
    this.setProdus();
    this.loadDataDefault();
    this.loadCarritosPendientes();
    this.setStatsVentas();
    this.setStatsPagos();
    this.loadingStartCuentaHospital();
    window.addEventListener("resize", this.checkMobile); // Escucha los cambios de tamaño de ventana
    this.checkMobile(); // Verifica el estado inicial
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkMobile); // Elimina el listener al destruir el componente
  },
};
</script>
<style>
.colcart {
  height: 88vh;
}

.colventa {
  height: 88vh;
}

.colcartcenter {
  height: 75%;
}

.contentcentral {
  height: 90%;
}

.colventacenter {
  height: 65%;
}

.cartespecial {
  overflow-x: none !important;
}

/* Diseño web responsivo */
/* Datos cliente */
@media only screen and (max-width: 980px) {
  .client-data {
    margin-bottom: 0.5em;
    margin-top: 0.5em;
  }

  .search-products {
    margin-top: 3em;
  }

  .btn-continue-after {
    margin-top: 0.5em;
  }
}

/* Eliminar select Sort By desplegado en vista móvil de tabla  */
.v-data-table-header-mobile__wrapper {
  display: none;
  border-style: none;
}

.v-data-table-header-mobile {
  display: none;
}

/* Eliminar espacio entre Monto a pagar y botón pagar */
.v-text-field__details {
  display: none !important;
}
</style>
