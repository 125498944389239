

export default [
    {
        path: '/produ',
        name: 'produ',
        component: ()=> import('../views/administration/ProduPage.vue'),
        meta: {requireAuth: true}
    },

]