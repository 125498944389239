import axios from "axios";
export default {
  //crear cliente
  async postCliente({ commit }, customer) {
    let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
    let query = `https://service.clivenapp.cl/clientes?token=${clinicaUser.Token}`;
    let headers = {
      "Content-type": "application/json",
    };
    await axios.post(query, customer, { headers: headers }).then((result) => {
      if ((result.status = 201)) {
        commit("setSuccessCreateCliente", true);
      }
    });
  },
  async postUpdateCliente({ commit }, customer) {
    let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
    let query = `https://service.clivenapp.cl/clientes/update/${customer.id}?token=${clinicaUser.Token}`;
    let headers = {
      "Content-type": "application/json",
    };
    await axios.post(query, customer, { headers: headers }).then((result) => {
      if ((result.status = 201)) {
        commit("setSuccessUpdateCliente", true);
      }
    });
  },
  // obtener los clientes
  async getClientes({ commit }, filter) {
    commit("setLoadingClientes", true);
    let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
    let query = `https://service.clivenapp.cl/clientes?filter=${filter}&token=${clinicaUser.Token}`;
    await axios
      .get(query)
      .then((result) => {
        commit("addClientes", result.data);
      })
      .catch((error) => {
        // console.log(error)
      });
    commit("setLoadingClientes", false);
  },
  //obtener raza
  async getRazas({ commit }) {
    let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
    let query = `https://service.clivenapp.cl/razas?token=${clinicaUser.Token}`;
    await axios
      .get(query)
      .then((result) => {
        commit("addRazas", result.data);
      })
      .catch((error) => {
        // console.log(error)
      });
  },
  async getPlanes({ commit }) {
    let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
    let query = `https://service.clivenapp.cl/planes?token=${clinicaUser.Token}`;
    await axios
      .get(query)
      .then((result) => {
        commit("addPlanes", result.data);
      })
      .catch((error) => {
        // console.log(error)
      });
  },
  //obtener especies
  async getEspecies({ commit }) {
    let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
    let query = `https://service.clivenapp.cl/especie?token=${clinicaUser.Token}`;
    await axios
      .get(query)
      .then((result) => {
        commit("addEspecies", result.data);
      })
      .catch((error) => {
        // console.log(error)
      });
  },
  //selecionar ficha cliente
  selectFichaCliente({ commit }, id) {
    commit("addSelectFichaClienteId", id);
  },
  async getClienteByFichan({ commit }, fichan) {
    try {
      commit("setLoadingClientes", true);
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/clientes/fichan/${fichan}?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios.get(query, { headers: headers }).then((result) => {
        if (result.status == 200) {
          commit("addCliente", result.data);
          commit("setLoadingClientes", false);
        }
      });
    } catch (error) {
      throw new Error("");
    }
  },
  async getClienteReturnByFichan({ commit }, fichan) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/clientes/fichan/${fichan}?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      let cliente = "";
      await axios.get(query, { headers: headers }).then((result) => {
        if (result.status == 200) {
          cliente = result.data;
        }
      });
      return cliente;
    } catch (error) {
      throw new Error("");
    }
  },
  //crear especie
  async postEspecie({ commit, dispatch }, especie) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/especie?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios.post(query, especie, { headers: headers }).then((result) => {
        if (result.status == 201) {
          commit("addSuccessCreateEspecie", true);
          dispatch("getEspecies");
        }
      });
    } catch (error) {
      // console.log(error)
      throw new Error("no se pudo grabar la especie");
    }
  },

  async updateEspecie({ commit, dispatch }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/especie/${data.especie.Id}?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios
        .post(query, data.especie, { headers: headers })
        .then((result) => {
          if (result.status == 201) {
            commit("setSuccessUpdateEspecie", true);
            dispatch("getEspecies");
          }
        });
    } catch (error) {
      throw new Error("no se pudo grabar la especie");
    }
  },
  async deleteEspecie({ commit, dispatch }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/especie/${data.id}?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios.delete(query, { headers: headers }).then((result) => {
        if (result.status == 201) {
          commit("setSuccessDeleteEspecie", true);
          dispatch("getEspecies");
        }
      });
    } catch (error) {
      throw new Error("no se pudo eliminar la especie");
    }
  },
  //crear raza
  async postRaza({ commit, dispatch }, raza) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/razas?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios.post(query, raza, { headers: headers }).then((result) => {
        if (result.status == 201) {
          commit("addSuccessCreateRaza", true);
          dispatch("getRazas");
        }
      });
    } catch (error) {
      throw new Error("no se pudo grabar la raza");
    }
  },
  //actualizar raza
  async updateRaza({ commit, dispatch }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/razas/${data.raza.Id}?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios
        .post(query, data.raza, { headers: headers })
        .then((result) => {
          if (result.status == 201) {
            commit("addSuccessUpdateRaza", true);
            dispatch("getRazas");
          }
        });
    } catch (error) {
      throw new Error("no se pudo grabar la raza");
    }
  },
  //elimianr raza
  async deleteRaza({ commit, dispatch }, data) {
    try {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      let query = `https://service.clivenapp.cl/razas/${data.id}?token=${clinicaUser.Token}`;
      let headers = {
        "content-type": "application/json",
      };
      await axios.delete(query, { headers: headers }).then((result) => {
        if (result.status == 201) {
          commit("setSuccessDeleteRaza", true);
          dispatch("getRazas");
        }
      });
    } catch (error) {}
  },
  actionSetCustomerEmpty({ commit }, data) {
    commit("setCustomerEmpty", "");
  },
};
