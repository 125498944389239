<template>
    <div class="d-flex text-center flex-column flex-md-row flex-grow-1 main_container">
      <v-sheet
        color="primary"
        class="layout-side d-flex justify-center flex-column flex-sm-row flex-md-row"
      >
        <img class="w-full" src="" />
        <div class="ma-auto">
          <div class="white--text logotext">ClivenApp</div>
          <div class="white--text">Software cloud de gestión veterinaria</div>
        </div>
      </v-sheet>
      <div
        class="
          pa-2 pa-md-4
          flex-grow-1
          align-center
          justify-center
          d-flex
          flex-column
          layout-content
        "
      >
        <div class="d-flex justify-center mx-5">
          <slot></slot>
        </div>
      </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("app", ["product"]),
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap");
.layout-side {
  width: 25%;
}

.layout-content {
  background: #FBFDFF;
  width: 800px;
}
.logotext {
  font-size: 48px;
  font-weight: 600;
  font-family: "Ubuntu", sans-serif;
}
/* Contenedor de la vista */
.main_container {
  background: #FBFDFF;
}
</style>
