<template >
  <div>
    <div class="d-flex">
      <v-select
        v-model="selectedCamera"
        :items="cameras"
        item-text="label"
        outlined
        dense
        item-value="id"
        @change="changeCamera"
      >
      </v-select>
      <v-btn color="primary" class="mb-2 mx-2" @click="startScanning"
        >Abrir Escaner</v-btn
      >
      <v-btn
        ref="centercam"
        icon
        color="primary"
        class="mb-2"
        @click="btnCloseCamera"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </div>
    <h3>Codigo {{ code }}</h3>
    <div
      id="reader"
      :class="{
        'qr-read-success': qrReadSuccess,
        'qr-read-repeat': qrReadRepeat,
      }"
      style="width: 100%"
    ></div>
  </div>
</template>
<script>
import { Html5QrcodeScanner, Html5Qrcode } from "html5-qrcode";
import { mapActions, mapState } from "vuex";

export default {
  props: {
    qrbox: {
      type: Number,
      default: 250,
    },
    fps: {
      type: Number,
      default: 10,
    },
    origen: {
      type: String,
      default: "",
    },
  },
  name: "readercode",
  data() {
    return {
      code: "",
      showScanConfirmation: false,
      showScanDuplicate: false,

      alertColor: "primary",
      alertContent: "",
      html5QrCode: null,
      cameras: [],
      selectedCamera: null,
      qrReadSuccess: false,
      qrReadRepeat: false,
    };
  },
  mounted() {
    this.html5QrCode = new Html5Qrcode("reader");
    Html5Qrcode.getCameras()
      .then((devices) => {
        this.cameras = devices;
        let rearCamera = devices.find(
          (device) => device.facingMode === "environment"
        );

        if (rearCamera) {
          this.selectedCamera = rearCamera.id;
        } else if (devices.length > 0) {
          this.selectedCamera = devices[0].id;
        } else {
          // No cameras available
          console.error("No cameras found.");
          return;
        }

        // Start the QR code scanning
        this.startScanning();
      })
      .catch((err) => {
        console.error(err);
      });
  },
  computed: {},
  methods: {
    centerView() {
      setTimeout(() => {
        var element = this.$refs["centercam"];
        var top = element.offsetTop;
        window.scrollTo({ top: top, behavior: "smooth" });
      }, 300);
    },
    startScanning() {
      this.html5QrCode
        .start(
          this.selectedCamera,
          {
            fps: 10,
            qrbox: { width: 250, height: 250 },
          },
          (decodedText, decodedResult) => {
            this.readCode(decodedText);
            //console.log(`Code matched = ${decodedText}`, decodedResult);
          },
          (errorMessage) => {
            //console.warn(`Code scan error = ${errorMessage}`);
          }
        )
        .catch((err) => {
          console.error(`Start failed: ${err}`);
        });
    },
    stopScanning() {
      this.html5QrCode
        .stop()
        .then((ignore) => {
          //console.log("QR Code scanning is stopped.");
        })
        .catch((err) => {
          console.error(`Stop failed: ${err}`);
        });
    },
    changeCamera() {
      this.stopScanning();
      this.startScanning();
    },
    playScanSound() {
      const audio = new Audio("/path/to/scan-sound.mp3");
      audio.play();
    },
    vibrateDevice() {
      if ("vibrate" in navigator) {
        navigator.vibrate([200, 100, 200]); // Patrón de vibración
      }
    },

    readCode(code) {
      this.code = code;
      if (this.origen == "inventario") {
        this.$emit("actualizarVariable", code);
      }
      if (this.origen == "ventanormal") {
        this.$emit("actualizarCodigoVenta", code);
      }
      if (this.origen == "buscarprodu") {
        this.$emit("actualizarCodigoBuscarProdu", code);
      }
      if (this.origen == "ventaborrador") {
        this.$emit("actualizarCodigoVentaBorrador", code);
      }
      if (this.origen == "ventacargarinsumos") {
        this.$emit("actualizarCodigoCargarInsumo", code);
      }
      this.stopScanning();
    },
    btnCloseCamera() {
      this.$parent.activeReaderAll = false;
      this.stopScanning();
    },
  },
  beforeDestroy() {
    if (this.html5QrCode) {
      this.html5QrCode.stop();
    }
    if (this.stream) {
      // Apaga la cámara deteniendo todos los MediaStreamTracks asociados.
      this.stream.getTracks().forEach((track) => track.stop());
    }
  },
};
</script>
<style >
.qr-read-success {
  background-color: green;
}

.alert-success {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5em;
}
.qr-read-repeat {
  background-color: red;
}

.alert-repeat {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5em;
}
</style>