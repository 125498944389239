import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { auth } from '../src/config/firebase'
import './config/formatCurrency'
import VueSignaturePad from 'vue-signature-pad';
import VueQrcode from '@chenfengyuan/vue-qrcode';

import './filters/formatCurrency'

Vue.component(VueQrcode.name, VueQrcode);
Vue.use(VueSignaturePad);

Vue.config.productionTip = false

const user = JSON.parse(localStorage.getItem('user'))
const clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
  if (user) {
    new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
    store.dispatch('auth-module/detectUser', {user:user, clinicaUser: clinicaUser})
  }else{
    new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
    store.dispatch('auth-module/detectUser', {user:false, clinicaUser: false})
  }






