<template>
  <v-app>
     <component :is="currentLayout" v-if="isRouterLoaded">
      <transition name="fade" mode="in-out">
        <router-view />
      </transition>
    </component>
  </v-app>
</template>

<script>
import authLayout from '../src/layouts/AuthLayout.vue'
import defaultLayout from '../src/layouts/DefaultLayout.vue'
export default {
  name: 'App',
  components: {
    authLayout, defaultLayout
  },
  data: () => ({
    //
  }),
  computed: {
    isRouterLoaded: function() {
      if (this.$route.name !== null) return true

      return false
    },
    currentLayout: function() {
      const layout = this.$route.meta.layout || 'default'

      return layout + 'Layout'
    }
  },
};
</script>
<style>
/* Estilos generales */
* {
  
  font-family: 'Ubuntu', sans-serif;
}
.w-full{
  height: 100vh;
  
}
.logo{
  font-size: 40;
  font-weight: 700;
}



</style>
