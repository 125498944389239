<template>
  <v-menu offset-y left transition="slide-y-transition">
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on"><v-icon>mdi-account-circle-outline</v-icon></v-btn>
    </template>
    <!-- Menú de usuario -->
    <v-card class="rounded-lg">
      <v-list dense nav>
        <div class="pa-1">
          <v-list-item to="/user" link>
            <v-list-item-icon class="mr-3">
              <v-icon color="rgba(0, 0, 0, 0.54)">mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Cuenta</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="my-1"></v-divider>
          <v-list-item @click="processLogOut()">
            <v-list-item-icon class="mr-3">
              <v-icon small color="rgba(0, 0, 0, 0.54)"
                >mdi-logout-variant</v-icon
              >
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Salir</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions, mapState } from "vuex";
import config from "../../config";

export default {
  data() {
    return {
      menu: config.toolbar.user,
      today: new Date(),
      start_date: new Date(),
      end_date: "",
      today_cicle: new Date().toISOString().substring(0, 10),
      filter_date: {
        start_date: "",
        end_date: "",
      },
    };
  },
  methods: {
    ...mapActions("auth-module", [
      "logOut",
      "getLicen",
      "getLogoStorageByToken",
      "getClinica",
      "getSedes",
    ]),
    ...mapActions("customers-module", [
      "getClientes",
      "getRazas",
      "getEspecies",
    ]),
    ...mapActions("sales-module", [
      "getVtasserByDate",
      "getPagosVtasserByDate",
      "getTablaimptos",
      "getMovimientosCajaByDate",
      "getCierresCajaByDate",
      "getPlanCuentas",
      "getAllInventarios",
      "getProveedores"
    ]),
    ...mapActions("medical-module", [
      "getServisByDate",
      "getAtenciondias",
      "getDoctors",
      "getMotivovisitas",
      "getFames",
      "getEspecialidad",
      "getMemos",
      "getHospitalizados",
      "getControlesHospitalizados",
      "getVentasdepaso",
      "getDirectoryFilesByToken",
      "getPackcodigos",
      "getAgendas",
    ]),
    processLogOut() {
      this.logOut();
    },
    setDateNow() {
      var currentDateObj = new Date();
      var numberOfMlSeconds = currentDateObj.getTime();
      var addMlSeconds = 60 * 180000;
      var newDateObj = new Date(numberOfMlSeconds - addMlSeconds);
      return newDateObj;
    },
    obtenerCantidadDias(mes) {
      const mesesCon31Dias = [1, 3, 5, 7, 8, 10, 12];
      const mesesCon30Dias = [4, 6, 9, 11];

      if (mes === 2) {
        return 28; // En este caso, asumiremos que el año no es bisiesto
      } else if (mesesCon31Dias.includes(mes)) {
        return 31;
      } else if (mesesCon30Dias.includes(mes)) {
        return 30;
      } else {
        return -1; // Si el mes no es válido, se devuelve -1
      }
    },
    loadGetCierresCajaByDate() {
      let today = this.setDateNow().toISOString().substring(0, 10);
      const mes = parseInt(today.substring(6, 8));
      const cantidadDias = this.obtenerCantidadDias(mes);
      this.getCierresCajaByDate({
        start_date: today.substring(0, 8) + "01",
        end_date: today.substring(0, 8) + cantidadDias,
        sucursal: this.user.Sucursal,
      });
    },
  },
  computed: {
    ...mapState("auth-module", ["user"]),
    ...mapState("medical-module", ["doctor"]),
  },
  async created() {
    let today = this.setDateNow();
    await this.getLicen({
      email: this.user.Email,
      sucursal: this.user.Sucursal,
    });
    this.getAtenciondias({
      fecha: today.toISOString().substring(0, 10),
      sucursal: this.user.Sucursal,
    });
    this.getHospitalizados({ sucursal: this.user.Sucursal });
    this.getRazas();
    this.getEspecies();

    this.getDoctors();
    this.getMotivovisitas();
    this.getFames();
    this.getEspecialidad();

    this.getMemos();
    this.getSedes();

    // await this.getDownloadLogo()
    this.getLogoStorageByToken();
    this.getClinica();
    this.getServisByDate();

    // this.getLogoStorageByToken()
    this.getVentasdepaso();
    this.getTablaimptos();
    this.getPackcodigos();
    this.getVtasserByDate({
      startdate: today.toISOString().substring(0, 10),
      enddate: today.toISOString().substring(0, 10),
    });
    this.getPagosVtasserByDate({
      startdate: today.toISOString().substring(0, 10),
      enddate: today.toISOString().substring(0, 10),
    });
    this.getMovimientosCajaByDate({
      start_date: today.toISOString().substring(0, 10),
      end_date: today.toISOString().substring(0, 10),
      sucursal: this.user.Sucursal,
      plancuentas: "",
    });
    this.loadGetCierresCajaByDate();
    this.getPlanCuentas();
    this.getAllInventarios({sucursal:this.user.Sucursal});
    this.getProveedores()
  },
};
</script>
