import Vue from 'vue'
import Router from 'vue-router'
import { auth } from '../config/firebase'

// Routes
import CustomersRoute from './customers.routes'
import MedicalRoute from './medical.routes'
import PetsRoute from './pets.routes'
import SalesRoutes from './sales.routes'
import AdministrationRoutes from './administration.routes'

Vue.use(Router)

export const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Home.vue'),
    meta: { requireAuth: true }
  },
  {
    path: '/auth/login',
    name: 'auth-login',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/auth/LoginPage.vue'),
    // meta: { requireAuth: true }
    meta: {
      layout: "empty"
    }
  },
  {
    path: '/verify-doc/:id',
    name: 'verify-doc',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/verifydocs/VerifyDocPage.vue'),
    // meta: { requireAuth: true }
    meta: {
      layout: "empty"
    }
  },
  {
    path: '/auth/login-user',
    name: 'auth-loginuser',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/auth/LoginUserPage.vue'),
    // meta: { requireAuth: true }
    meta: {
      layout: "empty"
    }
  },
  {
    path: '/user',
    name: 'user',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/user/UserPage.vue'),
    // meta: { requireAuth: true }
    meta: { requireAuth: true }
  },
  {
    path: '/feedbacks',
    name: 'feedbacks',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/feedbacks/FeedbacksPage.vue'),
    // meta: { requireAuth: true }
    meta: { requireAuth: true }
  },
  ...CustomersRoute,
  ...MedicalRoute,
  ...PetsRoute,
  ...SalesRoutes,
  ...AdministrationRoutes,

]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes
})

/**
 * Before each route update
 */
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireAuth)) {
    const user = JSON.parse(localStorage.getItem('user'))
    if (!user) {
      next({ path: '/auth/login' })
    }
    else {
      next()
    }
  } else {
    next()
  }
})

/**
 * After each route update
 */


export default router
