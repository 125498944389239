<template>
  <v-menu offset-y left transition="slide-y-transition">
    <template v-slot:activator="{ on }">
      <v-badge
        bordered
        :content="notificationsCount"
        offset-x="22"
        offset-y="22"
      >
        <v-btn icon v-on="on">
          <v-icon>mdi-bell-outline</v-icon>
        </v-btn>
      </v-badge>
    </template>
    <!-- Tarjeta de despliegue -->
    <v-card class="rounded-lg">
      <div class="pa-1">
        <v-list dense max-width="500">
          <v-subheader class="pa-2 font-weight-bold"><h3>Notificaciones</h3></v-subheader>
          <div v-for="notification in notifications" :key="notification.id">
            <v-divider v-if="notifications.length> 0 && index < notifications.length" inset></v-divider>
  
            <v-list-item @click="">
              <v-list-item-avatar size="32" color="primary">
                <v-icon dark small>mdi-information-outline</v-icon>
              </v-list-item-avatar>
  
              <v-list-item-content>
                <v-list-item-title v-text="notification.title"></v-list-item-title>
                <v-list-item-subtitle class="caption" v-text="notification.description"></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action class="align-self-center">
                <v-list-item-action-text >3 min</v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>
          </div>
        </v-list>
        <!-- Botones de acciones -->
        <div class="d-flex justify-center pa-3">
          <v-btn small color="primary" class="mr-3 rounded-lg text-capitalize">Ver todas</v-btn>
          <v-btn small color="primary" outlined class="rounded-lg text-capitalize">Marcar como leídas</v-btn>
        </div>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  data() {
    return {
      items: [
        {
          title: 'Brunch this weekend?',
          color: 'primary',
          icon: 'mdi-account-circle',
          subtitle: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sint, repudiandae?',
          time: '3 min'
        },
        {
          title: 'Summer BBQ',
          color: 'success',
          icon: 'mdi-email-outline',
          subtitle: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sint, repudiandae?',
          time: '3 min'
        },
        {
          title: 'Oui oui',
          color: 'teal lighten-1',
          icon: 'mdi-airplane-landing',
          subtitle: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sint, repudiandae?',
          time: '4 min'
        },
        {
          title: 'Disk capacity is at maximum',
          color: 'teal accent-3',
          icon: 'mdi-server',
          subtitle: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sint, repudiandae?',
          time: '3 hr'
        },
        {
          title: 'Recipe to try',
          color: 'blue-grey lighten-2',
          icon: 'mdi-noodles',
          subtitle: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sint, repudiandae?',
          time: '8 hr'
        }
      ]
    }
  },
  methods:{
    ...mapActions('notifications-module', ['getNotifications'])
  },
  computed:{
    ...mapState('notifications-module', ['notifications']),
    ...mapState("auth-app", ["user"]),
    ...mapGetters('notifications-module', ['notificationsCount'])
  },
  created(){
    this.getNotifications(this.user.uid)
  }

}
</script>
