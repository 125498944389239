// Menú de navegación lateral

export default [
  {
    icon: 'mdi-view-dashboard-outline', text: 'Home', link: '/',
  },
  {
    icon: 'mdi-paw', text: 'Clientes', link: '/customers',
  },
  {
    icon: 'mdi-medical-bag', text: 'Área médica',
    items: [
      { text: "Visitas", link: '/medical/visits' },
      { text: "Agenda", link: '/medical/agenda' },
      { text: "Ficha médica", link: '/medical/records' },
      { text: "Hospital", link: '/medical/hospital' },
      { text: "Cargar insumos a cuentas", link: '/medical/sales' },

    ]
  },
  {
    icon: 'mdi-cash-register', text: 'Área comercial', modulocomercial: true, items: [
      { text: 'Punto de venta', link: '/sales/point' },
      { text: 'Gestión inventarios', link: '/inventories' },
      // {icon: 'mdi-api', text: 'Api', link: '/app/api' },
    ]
  },
  {
    icon: 'mdi-store-cog', text: 'Administracion', modulocomercial: true, items: [
      {
        text: 'Médica', items: [
          { text: "Especies y razas", link: '/pets/types' },
          { text: "Especialidades", link: '/medical/specialties' },
          { text: "Doctores y personal", link: '/medical/professionals' },
        ]
      },
      {
        text: 'Comercial', items: [
          { text: 'Productos y servicios', link: '/produ' },
          // { text: 'Horarios ', link: '/inventories' },
        ]
      },
      // {icon: 'mdi-api', text: 'Api', link: '/app/api' },
    ]
  },
  {
    icon: 'mdi-cog', text: 'Ajustes', link: '/setup', items: [
      { text: 'Cuenta', link: '/user' },
      // {icon: 'mdi-cellphone-cog', text: 'Preferencias', link: '/settings' },
      // {icon: 'mdi-api', text: 'Api', link: '/app/api' },
    ]
  },
  {
    icon: 'mdi-comment-quote', text: 'Comentarios de la app', link: '/feedbacks',
  },
]
