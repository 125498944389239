export default {
    user: {
        Nombre: ""
    },
    clinicaUser: null,
    errorPassLogin: false,
    errorPassLoginMsg: "",
    errorLoginUser: false,
    sedes: [],
    errorLoginLicen: false,
    licen: {
        Var0: 0,
        Var1: 0,
        Var2: 0,
        Var3: 0,
        Var4: 0,
        Var5: 0,
        Var6: 0,
        Var7: 0,
        Var8: 0,
        Var9: 0,
        Var10: 0,
        Var11: 0,
        Var12: 0,
        Var13: 0,
        Var14: 0,
        Var15: 0,
        Var16: 0,
        Var17: 0,
        Var18: 0,
        Var19: 0,
        Var20: 0,
        Var21: 0,
        Var22: 0,
        Var23: 0,
        Var24: 0,
        Var25: 0,
        Var26: 0,
        Var27: 0,
        Var28: 0,
        Var29: 0,
        Var30: 0,
        Var31: 0,
        Var32: 0,
        Var33: 0,
        Var34: 0,
        Var35: 0,
        Var36: 0,
        Var37: 0,
        Var38: 0,
        Var39: 0,
        Var40: 0,
        Var41: 0,
        Var42: 0,
        Var43: 0,
        Var44: 0,
        Var45: 0,
        Var46: 0,
        Var47: 0,
        Var48: 0,
        Var49: 0,
        Var50: 0,
    },
    successChangePasswordLoginLicen: false,
    failChangePasswordLoginLicen: false,
    logoUpload:"",
    clinica: {
        Nombre: "",
        Razon: "",
        Direccion: "",
        Comuna: "",
        Ciudad: "",
        Fono: "",
        Email: "",
        Web: "",
        Var1: "",
        Var2: "",
        Var3: "",
        Var4: "",
        Rut: "",
        Var6: "",
        Id: 1,
        Giro: "",
    },
    successCreateEsign: false,
    profileNotFound: false,
}