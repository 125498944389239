export default {
    clientes: [
    ],
    cliente: {
        Fichan: 0,
        Nombre: "",
        Amo: "",
        Fono: "",
        Nacimiento: "",
        Raza: "",
        Direccion: "",
        Comuna: "",
        Ciudad: "",
        Email: "",
        Sexo: "",
        Fotoactiva: "",
        Madre: "",
        Padre: "",
        Peso: "",
        Altura: "",
        Edad: "",
        Reg: "",
        Color: "",
        Esp: "",
        Insc: "",
        Microchip: "",
        Fichaant: "",
        Clinica: "",
        Drtratante: "",
        Peluqueria: "",
        Rut: "",
        Id: 0
    },
    successCreateCliente: false,
    successCreateEspecie:false,
    successCreateRaza: false,
    successDeleteRaza: false,
    successUpdateEspecie:false,
    successUpdateRaza: false,
    successDeleteEspecie:false,
    especies: [],
    razas: [],
    loadingClientes: false,
    selectFichaClienteId: 0,
    successUpdateRaza: false,
    planes:[],
    successUpdateCliente: false
    
}