<template>
  <div>
    <!--  Primer nivel del menú -->
    <nav-menu-item v-for="(level1Item, level1Index) in menuFilter" :key="level1Index" :menu-item="level1Item">
      <template v-if="level1Item.items">
        <!-- Segundo nivel del menú -->
        <nav-menu-item
          v-for="(level2Item, level2Index) in level1Item.items"
          :key="level2Index"
          :menu-item="level2Item"
          subgroup
          small
        >
          <template v-if="level2Item.items">
            <!-- Tercer nivel del menú -->
            <nav-menu-item
              v-for="(level3Item, level3Index) in level2Item.items"
              :key="level3Index"
              :menu-item="level3Item"
              small
            />
          </template>
        </nav-menu-item>
      </template>
    </nav-menu-item>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import NavMenuItem from './NavMenuItem'
export default {
  components: {
    NavMenuItem
  },
  props: {
    menu: {
      type: Array,
      default: () => []
    }
  },
  data(){
    return {
      menuFilter : []
    }
  },
   computed:{
    ...mapState("auth-module", ["clinicaUser"])
  }, 
  methods:{
    loadMenu(){
      this.menuFilter = []
      this.menu.forEach(element =>{
    
        if(element.text == "Área médica"){
          if(this.clinicaUser.Areamedica == 1){
            this.menuFilter.push(element)
          }
        }
        if(element.text == "Área comercial"){
          if(this.clinicaUser.Areacomercial == 1){
            this.menuFilter.push(element)
          }
        }
        if(element.text != "Área comercial" && element.text != "Área médica"){
          
            this.menuFilter.push(element)
          
        }
      })
    }
  },
  created(){
    this.loadMenu()
  }
}
</script>
