import {
  auth,
  db, storage
} from "../../../config/firebase";
import router from "../../../router";
import { uploadBytes, ref, list, listAll, getDownloadURL, getStream, getBytes, getBlob } from 'firebase/storage'
import axios from "axios";
async function readFilesDirectoryByStorage(pathref) {

  const pathcopy = pathref
  const listRef = ref(storage, pathref);
  let list = []
  // Find all the prefixes and items.
  await listAll(listRef)
    .then((res) => {
      res.items.forEach((itemRef) => {
        //console.log(pathcopy)
        // All the items under listRef.
        list.push({ name: itemRef.name, path: pathcopy + "/" + itemRef.name })
      });
    })
  return list

}
async function downloadFileStorage(pathref) {
  // let url = await getDownloadURL(ref(storage, pathref))
  // let metada = await getBytes(ref(storage, pathref))
  // console.log("metada", metada)

  // let url = `https://storage.googleapis.com/clivenapp-3909d.appspot.com${pathref}`
  // getDownloadURL(ref(storage, pathref))
  //   .then((url) => {
  //     // `url` is the download URL for 'images/stars.jpg'

  //     // This can be downloaded directly:
  //     const xhr = new XMLHttpRequest();
  //     xhr.responseType = 'blob';
  //     xhr.onload = (event) => {
  //       const blob = xhr.response;
  //       console.log("blo", blob)
  //     };
  //     xhr.open('GET', url);
  //     xhr.send();
  //     console.log(url)

  //     // Or inserted into an <img> element
  //     const img = document.getElementById('myimg');
  //     img.setAttribute('src', url);
  //   })
  //   .catch((error) => {
  //     // Handle any errors
  //   });
  // let blouds = await getBlob(ref(storage, pathref))


  // getBlob(ref(storage, pathref))
  //   .then(function (myBlob) {
  //     let uri = URL.createObjectURL(
  //       new Blob([myBlob], { type: "image/png" })
  //     );
  //     // commit('addLogo', uri)
  //     console.log("logo", uri)
  //   })
  //   .catch((e) => {
  //     console.log(e)
  //     // console.log("e", e);
  //   });
  // console.log("revisando el blod")
  //     let uri = URL.createObjectURL(
  //       new Blob([blouds], { type: "image/jpeg" })
  //     );
  //     console.log('addLogocompleto', uri)
  // let options = {
  //   method: "GET",
  //   headers: new Headers({
  //     "Content-Type": "application/json",
  //   }),
  //   mode:"cors",
  //   cache: "default",
  // };
  // let strMimeType;
  // let strFileName;

  // //Perform a GET Request to server
  // fetch(url, options)
  //   .then(function (response) {
  //     console.log("respon", response)
  //     let contentType = response.headers.get("Content-Type"); //Get File name from content type
  //     console.log("file",response.headers.get("content-type"))
  //     strMimeType = response.headers.get("content-type").split(";")[0];
  //     strFileName = contentType.split(";")[1];
  //     // console.log(strFileName)
  //     return response.blob();
  //   })
  //   .then(function (myBlob) {
  //     console.log("revisando el blod")
  //     let uri = URL.createObjectURL(
  //       new Blob([myBlob], { type: strMimeType })
  //     );
  //     console.log('addLogocompleto', uri)
  //   })
  //   .catch((e) => {
  //     console.log("e", e);
  //   });

}
export default {
  // login({commit}, user){
  //     // console.log(user)
  // },

  async signInClinica({ commit }, user) {
    try {

      let query = "https://service.clivenapp.cl/auth/login"
      let headers = {
        "content-type": "application/json"
      }
      let clinicaUser = {
        Token: "",
        Email: "",
        Name: ""
      }
      await axios.post(query, { email: user.email, passusuario: user.password }, { headers: headers })
        .then((result) => {
          commit('addErrorLoginUser', false)
          if (result.data.Token) {
            clinicaUser.Token = result.data.Token
            clinicaUser.Email = result.data.Email
            clinicaUser.Name = result.data.Name
            clinicaUser.Areamedica = result.data.Areamedica
            clinicaUser.Areacomercial = result.data.Areacomercial
            clinicaUser.Areafinanzas = result.data.Areafinanzas
            localStorage.setItem('clinicaUser', JSON.stringify(clinicaUser))
            commit("addClinicaUser", clinicaUser);
            router.push('/auth/login-user')
          } else {
            commit('addErrorLoginUser', true)
          }
        })
    } catch (error) {
      // console.log(error);
    }
  },
  async signInUserLicen({ commit }, userInput) {
    try {

      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let query = `https://service.clivenapp.cl/auth/login-user?token=${clinicaUser.Token}`
      let headers = {
        "Content-type": "application/json",
      }
      let user = {
        Nombre: "",
        Sucursal: "",
        Email: "",
      }
      await axios.post(query, { email: userInput.email, passusuario: userInput.password, sucursal: userInput.sucursal }, { headers: headers })
        .then((result) => {
          if (result.status == 200) {
            if (result.data.Email != "") {
              user.Nombre = result.data.Nombre,
                user.Sucursal = result.data.Sucursal,
                user.Email = result.data.Email
               
              localStorage.setItem('user', JSON.stringify(user))
              commit("addUser", user);
              router.push('/')
            } else {
              user = undefined
              commit('addErrorLoginLicen', true)
            }
          }
        })

    } catch (error) {
      // console.log(error);
    }
  },
  async getSedes({ commit }) {
    let clinicaUser = await JSON.parse(localStorage.getItem('clinicaUser'))
    let query = `https://service.clivenapp.cl/sedes?token=${clinicaUser.Token}`
    let headers = {
      "Content-type": "application/json",
    }
    // console.log(query)
    await axios.get(query, { headers: headers })
      .then((result) => {
        // console.log(result)
        if (result.status == "200") {
          commit('addSedes', result.data)
        }
      })
  },
  async getLicen({ commit, dispatch }, data) {
    let clinicaUser = await JSON.parse(localStorage.getItem('clinicaUser'))
    let query = `https://service.clivenapp.cl/licen?token=${clinicaUser.Token}&email=${data.email}&sucursal=${data.sucursal}`
    let headers = {
      "Content-type": "application/json",
    }
    await axios.get(query, { headers: headers })
      .then(async(result) => {

        if (result.status == "200") {
          commit('addLicen', result.data)
          if(result.data.Var37 == 0){
            let queryDoctor = `https://service.clivenapp.cl/doctors?token=${clinicaUser.Token}`
            let headers = {
              "content-type": "application/json"
            }
            await axios.get(queryDoctor, { headers: headers })
              .then((doctors) => {
                if (doctors.status == 200) {
                  let doctorsfilter = doctors.data.filter(item => item.Email == data.email)
                 
                  if(doctorsfilter[0]){
                    dispatch('medical-module/actionSelectDoctor', doctorsfilter[0], {root:true})
                  }else{
                    commit('setProfileNotFound', true)
                  }
                }
              })
          }
          //console.log(result.data)
        }
      })
  },
  async postEsignByEmail({ commit, dispatch }, data) {
    let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
    let query = `https://service.clivenapp.cl/licen/sing?token=${clinicaUser.Token}`
    let headers = {
      "Content-type": "application/json",
    }
    await axios.post(query, data.esign, { headers: headers })
      .then((result) => {
        if (result.status == 201) {
          commit('addSuccessCreateEsign', true)
          dispatch('getLicen', { email: data.esign.email, sucursal: data.sucursal })
        } else {

        }
      })
      .catch((error) => {

      })
  },
  async getClinica({ commit },) {
    let clinicaUser = await JSON.parse(localStorage.getItem('clinicaUser'))
    let query = `https://service.clivenapp.cl/clinica?token=${clinicaUser.Token}`
    let headers = {
      "Content-type": "application/json",
    }
    await axios.get(query, { headers: headers })
      .then((result) => {

        if (result.status == "200") {
          commit('addClinica', result.data)
        }
      })
  },
  async postUpdatePasswordLoginLicen({ commit }, data) {
    let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
    let query = `https://service.clivenapp.cl/auth/login-user/pass?token=${clinicaUser.Token}`
    let headers = {
      "Content-type": "application/json",
    }
    let changePasswordLoginLicen = {
      Email: data.email,
      Passusuario: data.passusuario,
      Sucursal: data.sucursal,
      NewPassusuario: data.NewPassusuario
    }
    // console.log(changePasswordLoginLicen)
    await axios.post(query, changePasswordLoginLicen, { headers: headers })
      .then((result) => {
        if (result.data == "ok") {
          commit('addSuccessChangePasswordLoginLicen', true)
        } else {
          commit('addFailChangePasswordLoginLicen', true)
        }
      })
      .catch((error) => {
        commit('addFailChangePasswordLoginLicen', true)
      })
  },
  async getDownloadLogo({ commit }) {

    let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
    let url = `https://service.clivenapp.cl/logo?token=${clinicaUser.Token}`;
    let options = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      mode: "cors",
      cache: "default",
    };
    let strMimeType;
    let strFileName;
    
    //Perform a GET Request to server
    fetch(url, options)
      .then(function (response) {
        let contentType = response.headers.get("Content-Type"); //Get File name from content type
        strMimeType = response.headers.get("content-type").split(";")[0];
        strFileName = contentType.split(";")[1];
        // console.log(strFileName)
        return response.blob();
      })
      .then(function (myBlob) {
        let uri = URL.createObjectURL(
          new Blob([myBlob], { type: strMimeType })
        );
        commit('addLogo', uri)
      })
      .catch((e) => {
        // console.log("e", e);
      });
  },
  async getLogoStorageByToken({ commit, dispatch }) {
   
    let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
    let path = `/logo/${clinicaUser.Token}`
    let list = await readFilesDirectoryByStorage(path)
    let logoref = list[0]
    

    getDownloadURL(ref(storage, logoref.path))
    .then((url) => {
      // Insert url into an <img> tag to "download"
      let options = {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
        mode: "cors",
        cache: "default",
      };
      let strMimeType;
      let strFileName;
      //Perform a GET Request to server
      fetch(url)
        .then(function (response) {
          let contentType = response.headers.get("Content-Type"); //Get File name from content type
          strMimeType = response.headers.get("content-type").split(";")[0];
          strFileName = contentType.split(";")[1];
          // console.log(strFileName)
          return response.blob();
        })
        .then(function (myBlob) {
          let uri = URL.createObjectURL(
            new Blob([myBlob], { type: strMimeType })
          );
          
          commit('addLogo', uri)
      
        })
        .catch((e) => {
         
          // console.log("e", e);
        });
    })
    .catch((error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/object-not-found':
          // File doesn't exist
          break;
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
  
        // ...
  
        case 'storage/unknown':
          // Unknown error occurred, inspect the server response
          break;
      }
   
    });
  
  },
  detectUser({ commit }, data) {
    commit('addUser', data.user)
    commit('addClinicaUser', data.clinicaUser)
  },
  logOut({ commit }) {
    localStorage.removeItem('user')
    localStorage.removeItem('clinicaUser')
    router.push('/auth/login')
    window.location.reload();
  },
  setErrorLoginLicen({ commit }) {
    commit('addErrorLoginLicen', false)
  },
  setErrorLoginUser({ commit }) {
    commit('addErrorLoginUser', false)
  }
};
