import { initializeApp, getApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {
  getAuth,
} from "firebase/auth";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
const firebaseConfig = {
    apiKey: "AIzaSyBjjVuO9UNIWQHUAh21eOIlfddc9Ett748",
    authDomain: "clivenapp-3909d.firebaseapp.com",
    projectId: "clivenapp-3909d",
    storageBucket: "clivenapp-3909d.appspot.com",
    messagingSenderId: "979225985618",
    appId: "1:979225985618:web:b0cb6388a652ce3b21558b",
  };


// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

const storage = getStorage()

const auth = getAuth();


export { db, auth, storage };