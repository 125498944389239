export default {
    medicalCareVisits: [
    ],

    //atenciondia
    atenciondias: [],
    atenciondia:{},
    atenciondiasByCliente: [],
    activeLoadingSearchAtenciondias: false,

    doctors: [],
    motivovisitas: [],
    diagnostico: {
        Fichan: 0,
        Nombre: "",
        Fecha: "",
        Temperatura: "",
        Peso: "",
        Frecar: "",
        Freres: "",
        Anamnecis: "",
        Inspeccion: "",
        Palpacion: "",
        Auscultacion: "",
        Otros: "",
        Prediagnostico: "",
        Diagnostico: "",
        Categoria: "",
        Tratamiento: "",
        Diagnosticofamilia: "",
        Id: 0
    },
    fames: [],
    especialidades: [],
    activeLoadingCargarFamilias: false,
    activeLoadingImportProdu:false,
    successCreateProdu:false,
    successDeleteProdu:false,
    familias:[],
    produs: [],
    servisHistory: [],
    servis:[],
    filterProdus: [],
    checklists: [],
    activeLoadingCargarProdu:false,
    activeCreateServisEntregado:false,
    checklist: {
        Id: 0,
        Ficha: 0,
        Fecha: "",
        Peso: "",
        Cc: "",
        Pelaje: "",
        Pelobs: "",
        Orejas: "",
        Oreobs: "",
        Ojos: "",
        Ojoobs: "",
        Nariz: "",
        Narobs: "",
        Garganta: "",
        Garobs: "",
        Boca: "",
        Bocobs: "",
        Encias: "0",
        Encobs: "0",
        Extremidades: "",
        Extobs: "",
        Diag: "",
        Soles: "",
        Solobs: "",
        Requiere: "",
        Fechare: "",
        Solobs2: "0"
    },
    successCreateAtenciondia: false,
    successCreateEspecialidad: false,
    successCreateDiagnostico:false,
    successCreateChecklist: false,
    successCreateService:false,
    successDeleteFile: false,
    successUpdateEspecialidad:false,
    successDeleteEspecialidad: false,
    directories: [],
    aces: [],
    doctor: {
        Nombre: "",
        Rut: "",
        Direccion: "",
        Fono: "",
        Comi: "0",
        Fecha_ingreso: "",
        Estado: 0,
        Especialidad: "No Registra",
        Imagen: "n",
        Descripcion: "",
        Email: "",
        Horariospersonal:"",
    },
    successCreateDoctor: false,
    successDeleteVentadepaso:false,
    loadingVentadepaso: false,
    //receta
    recetas:[],
    receta:{
        Ficha: 0,
        Fecha: '',
        Dr:'',
        Receta:''
    },
    successCreateReceta: false,
    activefindsuccesshorarios: false,
    successCreateHorariopersonal: false,
    successCreateProgramacion:false,
    successDeleteHorariopersonal:false,
    programaciones: [],
    successDeleteProgramacionById: false,
    memos:{},
    successDeleteAtenciondiaById: false,

    //email
    successSentEmail:false,

    //hospital
    hospitalizados: [],
    controlesHospitalizados: [],
    hospitalizado: {
        Fecha: "",
        Ficha: 0,
        Rn: "0",
        Hora: "",
        Temp: "",
        Frecar: "",
        Freres: "",
        Pressang: "",
        Observaciones: "",
        Var1: "",
        Var2: "",
        Var3: "",
        Fecha2: "",
        Tratamiento: "",
        Diagnostico: "",
        Id: 0,
        Sucursal: ""
    },
    selectFichaCuentaHospital: "",
    selectFichaHospitalizado: "",
    successCreateControlHospitalizado: false,
    successUpdateControlHospitalizado: false,
    successCreateAltaHospitalizado:false,
    activeLoadingSearchHospitalizados: false,
    //procedimiento
    procedimientos:[],
    successCreateProcedimiento:false,


    //ventasdepaso
    ventasdepaso: [],
    successCreateVentadepaso: false,
    loadingUpload: false,
    packcodigos: [],
    agendas:[],
}