<template>
  <div class="w-full">
    <!-- Barra de menú superior -->
    <v-app-bar height="100px" color="white" app clipped fixed>
      <v-card
        class="flex-grow-1 d-flex"
        color="white"
        :class="[isToolbarDetached ? 'pa-1 mt-3 mx-1' : 'pa-0 ma-0']"
        :flat="!isToolbarDetached"
      >
        <div class="d-flex flex-grow-1 align-center">
          <!-- Input de buscar versión móvil -->
          <v-autocomplete
            v-if="showSearch"
            v-model="select"
            :items="menu"
            item-text="name"
            item-value="link"
            itemscope
            flat
            append-icon="mdi-close"
            @click:append="showSearch = false"
            class="d-flex d-sm-flex d-md-flex d-lg-none d-xl-none"
            hide-no-data
            hide-details
            rounded
            color="primary"
            label="Buscar por cualquier término"
            solo
            outlined
            @change="click(item)"
          ></v-autocomplete>
          <div v-else class="d-flex flex-grow-1 align-center">
            <!-- Botón hamburguer que despliega menú lateral -->
            <v-app-bar-nav-icon
              @click.stop="drawer = !drawer"
            ></v-app-bar-nav-icon>
            <v-spacer></v-spacer>
            <!-- Input de buscar versión escritorio -->
            <v-autocomplete
              v-model="select"
              :items="menu"
              item-text="name"
              item-value="link"
              itemscope
              flat
              append-icon="mdi-magnify"
              class="d-none d-sm-none d-md-none d-lg-flex d-xl-flex justify-center"
              hide-no-data
              hide-details
              rounded
              color="primary"
              label="Buscar por cualquier término"
              solo
              outlined
              @change="click(item)"
            ></v-autocomplete>
            <v-spacer></v-spacer>
            <v-spacer class="d-block d-sm-none"></v-spacer>
            <!-- Campana de notificaciones y perfil de usuario + ícono de buscar en versión móvil -->
            <div class="mr-5 d-flex">
              <v-btn
                class="d-block d-sm-flex d-md-flex d-lg-none d-xl-none"
                icon
                @click="showSearch = true"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <div :class="[$vuetify.rtl ? 'ml-1' : 'mr-1']">
                <toolbar-notifications />
              </div>
              <toolbar-user />
            </div>
          </div>
        </div>
      </v-card>
    </v-app-bar>
    <!-- Menú de navegación lateral -->
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      app
      fixed
      color="primary"
    >
      <!-- Ícono de desplegar y contraer -->
      <v-list-item class="px-2">
        <v-btn icon @click.stop="mini = !mini">
          <v-icon v-if="mini" color="white">mdi-chevron-right</v-icon>
          <v-icon v-else color="white">mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <!-- Saludo al usuario -->
      <v-list-item class="px-2">
        <v-avatar size="35" color="white" class="ma-1 pa-1">
          <span class="primary--text text-h6 ma-3">{{
            user.Nombre.substring(0, 1)
          }}</span>
        </v-avatar>
        <h4 class="white--text ml-2 mr-3 align-self-center">
          {{ user.Nombre }}
        </h4>
      </v-list-item>
      <!-- Ubicación -->
      <v-list-item class="d-flex">
        <div class="">
          <v-icon size="20" color="white" class="ml-1">mdi-map-marker</v-icon>
        </div>
        <div>
          <h4 class="white--text pl-1">Sucursal: {{ user.Sucursal }}</h4>
        </div>
      </v-list-item>
      <v-divider class="mx-5"></v-divider>
      <main-menu :menu="navigation.menu" />
    </v-navigation-drawer>
    <!-- Contenedor del contenido central -->
    <v-main>
      <v-container class="mx-20 my-10">
        <slot> </slot>
      </v-container>
    </v-main>
  </div>
</template>

<script>
// Configuraciones del menú de navegación
import config from "../config";
import MainMenu from "../components/navigation/MainMenu.vue";
import menuList from "../config/menus/apps.menu";
import ToolbarUser from "../components/toolbar/ToolbarUser.vue";
import ToolbarNotifications from "../components/toolbar/ToolbarNotifications.vue";
import { mapState } from "vuex";
export default {
  components: {
    MainMenu,
    ToolbarUser,
    ToolbarNotifications,
  },
  data() {
    return {
      drawer: null,
      showSearch: false,
      search: null,
      select: null,
      navigation: config.navigation,
      menu: [
        {
          name: "Clientes",
          link: "/customers",
        },
        {
          name: "Especies y razas",
          link: "/pets/types",
        },
        {
          name: "Especialidades",
          link: "/medical/specialties",
        },
        {
          name: "Doctores y personal",
          link: "/medical/professionals",
        },
        {
          name: "Visitas",
          link: "/medical/visits",
        },
        {
          name: "Ficha médica",
          link: "/medical/records",
        },
      ],
      mini: true,
      menuList,
      items: [
        {
          action: "mdi-magnify",
          items: [{ title: "Mascota", link: "/customers" }],
          title: "Attractions",
        },
        {
          action: "mdi-silverware-fork-knife",
          active: true,
          items: [
            { title: "Breakfast & brunch" },
            { title: "New American" },
            { title: "Sushi" },
          ],
          title: "Dining",
        },
        {
          action: "mdi-school",
          items: [{ title: "List Item" }],
          title: "Education",
        },
        {
          action: "mdi-human-male-female-child",
          items: [{ title: "List Item" }],
          title: "Family",
        },
        {
          action: "mdi-bottle-tonic-plus",
          items: [{ title: "List Item" }],
          title: "Health",
        },
        {
          action: "mdi-briefcase",
          items: [{ title: "List Item" }],
          title: "Office",
        },
        {
          action: "mdi-tag",
          items: [{ title: "List Item" }],
          title: "Promotions",
        },
      ],
    };
  },
  created() {
    for (let i of menuList) {
      for (let o of i.items) {
        this.menu.push({ text: o.text, link: o.link });
      }
      if (i.items.length > 0) {
      } else {
        this.menu.push({ text: i.text, link: i.link });
      }
    }
  },
  methods: {
    click() {
      this.$router.push(this.select);
      this.showSearch = false;
    },
  },
  computed: {
    ...mapState("auth-module", ["user"]),
  },
};
</script>

<style>
/* Estilos generales */
* {
  font-family: "Ubuntu", sans-serif;
  font-size: 13px;
}

/* Títulos */
h1 {
  font-family: "Ubuntu", sans-serif;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 2px;
}

/* Subtítulos */
h2 {
  font-family: "Ubuntu", sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 2px;
}

/* Información adicional */
h3 {
  font-family: "Ubuntu", sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2px;
}
/* Modificar rotación de ícono versión escritorio */
.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: none;
}

</style>
