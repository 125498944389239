import axios from "axios"
export default {
  async postFeedback({commit}, data){
    try {
      let clinicaUser = JSON.parse(localStorage.getItem('clinicaUser'))
      let query = `https://service.clivenapp.cl/feedbacks?token=${clinicaUser.Token}`
      await axios.post(query, data.feedback)
      .then((result)=>{
        if(result.status == 201){
          commit('addSuccessCreateFeedback', true)
        }
      })
    } catch (error) {
      
    }
  }
}