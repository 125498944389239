<template>
  <div class="d-flex pa-3 justify-center">
    <v-card width="100%" elevation="0">
      <div class="pa-3">
        <!-- titulo y accion de cerrar componente -->
        <div :class="isMobile? 'd-flex flex-column justify-center' : 'd-flex justify-center align-center'">
          <v-btn
            :loading="activeLoadingSentEmail"
            @click="btnGeneratePdfEmail"
            outlined
            class="rounded-lg text-capitalize mr-2"
            color="primary"
            >Enviar por email</v-btn
          >
          <v-btn
            color="primary"
            @click="btnGeneratePdf"
            :class="isMobile? 'mt-2 text-capitalize rounded-lg' : 'ma-0 text-capitalize rounded-lg'"
            >Imprimir</v-btn
          >
        </div>
      </div>
    </v-card>
    <v-snackbar
      color="primary"
      v-model="activeSuccessCreateEmail"
      :timeout="1000"
    >
      Email enviado

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          class="text-capitalize"
          v-bind="attrs"
          @click="activeSuccessCreateEmail = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="certificado"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="A4"
      pdf-orientation="portrait"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
    >
      <!-- Contenido del PDF -->
      <section slot="pdf-content">
        <div>
          <!-- Contenedor principal -->
          <div :id="refdinamiac" class="content-sii">
            <!-- Primera fila de información -->
            <div class="first-row">
              <!-- Tarjeta contenedora de rut + número boleta + fecha -->
              <div class="first-row-container">
                <div class="first-row-content">
                  RUT: <span class="black-font">{{ clinica.Rut }}</span>
                </div>
                <div class="first-row-content">
                  {{ dte.vtaser[0].Tipo }}
                  <br />
                  Nº <span class="black-font">{{ dte.vtaser[0].Bnumero }}</span>
                </div>
                <div class="first-row-content">
                  Fecha:
                  <span class="black-font">{{
                    setDateStringShortPrint(dte.vtaser[0].Fecha)
                  }}</span>
                </div>
              </div>
            </div>
            <!-- Información de la clínica veterinaria (Segunda fila) -->
            <div class="">
              <div style="text-align: center">{{ clinica.Nombre }}</div>
              <div style="text-align: center">
                {{ clinica.Direccion }}, {{ clinica.Comuna }}
              </div>
              <div style="text-align: center">{{ user.Sucursal }}</div>
            </div>
            <!-- Línea divisoria -->
            <hr class="middle-line" />
            <!-- Tercera fila  -->
            <div
              v-if="
                dte.vtaser[0].Tipodte == '33' || dte.vtaser[0].Tipodte == '34'
              "
            >
              <div class="third-row-content">
                Razón social: {{ dte.cliente.Amo }}
              </div>
              <div class="third-row-content">RUT: {{ dte.cliente.Rut }}</div>
              <div class="third-row-content">Giro: {{ dte.cliente.Reg }}</div>
              <div class="third-row-content">
                Dirección: {{ dte.cliente.Direccion }}
              </div>
              <div class="third-row-content">
                Comuna: {{ dte.cliente.Comuna }}
              </div>
            </div>
            <div v-else>
              <div class="third-row-content">
                <span class="black-font">Cliente: </span>{{ dte.cliente.Amo }}
              </div>
              <div class="third-row-content">
                <span class="black-font">Mascota: </span>
                {{ dte.cliente.Nombre }} -{{ dte.cliente.Fichan }}
              </div>
              <div class="third-row-content">
                <span class="black-font">RUT: </span> {{ dte.cliente.Rut }}
              </div>
            </div>
            <!-- Línea divisoria -->
            <hr class="middle-line" />
            <!-- Detalle de productos y servicios -->
            <div style="text-align: center">
              <span class="black-font">Detalle de productos y servicios</span>
            </div>
            <div
              v-for="produ in dte.vtaser"
              :key="produ.Cod"
              class="receipt-body"
            >
              <!-- Items de la venta -->
              <div class="item">
                <div class="name">{{ produ.Nompro }}</div>
                <div class="details">
                  <span class="black-font">Cantidad: {{ produ.Cantidad }}</span>
                  <span class="black-font"
                    >$ {{ produ.Total | formatCurrency }}</span
                  >
                </div>
              </div>
            </div>
            <!-- Línea divisoria -->
            <hr class="middle-line" />
            <!-- Totales -->
            <div
              v-if="
                dte.vtaser[0].Tipodte == '33' || dte.vtaser[0].Tipodte == '34'
              "
            >
              <div class="total-item">
                <div class="label">Subtotal</div>
                <div class="amount">
                  $ {{ calculateSubtotal | formatCurrency }}
                </div>
              </div>

              <div class="total-item">
                <div class="label">Impuesto IVA 19%</div>
                <div class="amount">$ {{ calculateIva | formatCurrency }}</div>
              </div>

              <div class="total-item">
                <div class="label">Total</div>
                <div class="amount">
                  $ {{ calculateTotal | formatCurrency }}
                </div>
              </div>
            </div>
            <div
              v-if="
                dte.vtaser[0].Tipodte == '39' || dte.vtaser[0].Tipodte == '41'
              "
            >

          

              <div class="total-item">
                <div class="label">Total</div>
                <div class="amount">
                  $ {{ calculateTotal | formatCurrency }}
                </div>
              </div>
              <br>
                <hr class="middle-line" />
              <div class="total-item">
                <div class="label">El impuesto de esta venta es:</div>
                <div class="amount">$ {{ calculateIva | formatCurrency }}</div>
              </div>
                <hr class="middle-line" />
            </div>
            <div v-if="dte.vtaser[0].Tipodte == '61'">
              <div class="total-item">
                <div class="label">El impuesto de esta nota es:</div>
                <div class="amount">$ {{ calculateIva | formatCurrency }}</div>
              </div>
              <!-- Línea divisoria -->
              <hr class="middle-line" />
              <div class="total-item">
                <div class="label">Total</div>
                <div class="amount">
                  $ {{ calculateTotal | formatCurrency }}
                </div>
              </div>
            </div>
            <div v-if="dte.vtaser[0].Tipodte == '0'">
              <div class="total-item">
                <div class="label">Total</div>
                <div class="amount">
                  $ {{ calculateTotal | formatCurrency }}
                </div>
              </div>
            </div>

            <div class="imglogo" v-if="dte.vtaser[0].Tipodte != '0'">
              <img
                v-if="imgbase != false"
                :src="'data:image/png;base64,' + imgbase"
                alt="Logo"
                width="285px"
                crossorigin="anonymous"
              />
              <div v-else>
                <img
                  style="padding-right: 14px"
                  src="https://developers.nubox.com/hubfs/Captura%20de%20Pantalla%202020-06-25%20a%20la(s)%2019.05.18.png"
                  alt="Logo"
                  width="285px"
                  crossorigin="anonymous"
                />
              </div>
            </div>
            <div v-if="dte.vtaser[0].Tipodte != '0'" style="text-align: center">
              Timbre electrónico SII
            </div>
            <div v-if="dte.vtaser[0].Tipodte != '0'" style="text-align: center">
              RES 80 del 22/08/2014
            </div>
            <div style="text-align: center; margin: 10px 0px 10px 0px">
              Verifique su documento electronico
            </div>
            <div></div>
            <div style="text-align: center; margin: 10px 0px 10px 0px">
              Muchas gracias por su preferencia
            </div>
          </div>
        </div>
      </section>
    </vue-html2pdf>
    <!-- Documento a imprimir -->
  </div>
</template>
<script>
import VueHtml2pdf from "vue-html2pdf";
import html2pdf from "html2pdf.js";
import { mapActions, mapState } from "vuex";
import { PDFDocument } from "pdf-lib";
export default {
  name: "GenerateDocTributario",
  components: {
    VueHtml2pdf,
  },
  props: {
    dte: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isMobile: false,
      today: new Date().toISOString(),
      refdinamiac: "",
      tipodoc: "",
      imgbase: "",
      pdfbase64str: "",
      activeSuccessCreateEmail: "",
      activeLoadingSentEmail: false,
    };
  },

  methods: {
    ...mapActions("sales-module", ["postPDFDocumentByEmail"]),
    setDateShortString(date) {
      var dataSet = new Date(date.substring(0, 10) + "T00:00:00");
      var day = dataSet.getDate();
      var year = dataSet.getFullYear();
      var month = dataSet.getMonth();
      var montTex = "";
      var h = dataSet.getHours();
      var m = dataSet.getMinutes();
      switch (month) {
        case 0:
          montTex = "Enero";
          break;
        case 1:
          montTex = "Febrero";
          break;
        case 2:
          montTex = "Marzo";
          break;
        case 3:
          montTex = "Abril";
          break;
        case 4:
          montTex = "Mayo";
          break;
        case 5:
          montTex = "Junio";
          break;
        case 6:
          montTex = "Julio";
          break;
        case 7:
          montTex = "Agosto";
          break;
        case 8:
          montTex = "Septiembre";
          break;
        case 9:
          montTex = "Octubre";
          break;
        case 10:
          montTex = "Noviembre";
          break;
        case 11:
          montTex = "Diciembre";
        default:
          break;
      }
      if (m == 0) {
        m = "00";
      }
      if (h == 0) {
        h = "00";
      }
      return day + " de " + montTex + " de " + year;
    },
    checkMobile() {
      return (this.isMobile = window.innerWidth <= 500); // Define aquí el ancho máximo para considerar un dispositivo móvil
    },
    btnGenerateDocTributario() {
      this.$refs.refdinamiac.generatePdf();
    },
    setDateString(date) {
      var dataSet = new Date(date);
      dataSet = this.setDateNow(dataSet);
      var day = dataSet.getDate();
      var year = dataSet.getFullYear();
      var month = dataSet.getMonth();
      var montTex = "";
      var h = dataSet.getHours();
      var m = dataSet.getMinutes();
      switch (month) {
        case 0:
          montTex = "Enero";
          break;
        case 1:
          montTex = "Febrero";
          break;
        case 2:
          montTex = "Marzo";
          break;
        case 3:
          montTex = "Abril";
          break;
        case 4:
          montTex = "Mayo";
          break;
        case 5:
          montTex = "Junio";
          break;
        case 6:
          montTex = "Julio";
          break;
        case 7:
          montTex = "Agosto";
          break;
        case 8:
          montTex = "Septiembre";
          break;
        case 9:
          montTex = "Octubre";
          break;
        case 10:
          montTex = "Noviembre";
          break;
        case 11:
          montTex = "Diciembre";
        default:
          break;
      }
      if (m == 0) {
        m = "00";
      }
      if (h == 0) {
        h = "00";
      }
      return day + " de " + montTex + " del " + year + " a las " + h + ":" + m;
    },
    setDateStringPrint(date) {
      var dataSet = new Date(date);
      dataSet = dataSet;
      var day = dataSet.getDate();
      var year = dataSet.getFullYear();
      var month = dataSet.getMonth();
      var montTex = "";
      var h = dataSet.getHours();
      var m = dataSet.getMinutes();
      switch (month) {
        case 0:
          montTex = "Enero";
          break;
        case 1:
          montTex = "Febrero";
          break;
        case 2:
          montTex = "Marzo";
          break;
        case 3:
          montTex = "Abril";
          break;
        case 4:
          montTex = "Mayo";
          break;
        case 5:
          montTex = "Junio";
          break;
        case 6:
          montTex = "Julio";
          break;
        case 7:
          montTex = "Agosto";
          break;
        case 8:
          montTex = "Septiembre";
          break;
        case 9:
          montTex = "Octubre";
          break;
        case 10:
          montTex = "Noviembre";
          break;
        case 11:
          montTex = "Diciembre";
        default:
          break;
      }
      if (m == 0) {
        m = "00";
      }
      if (h == 0) {
        h = "00";
      }
      return (
        day +
        " de " +
        montTex +
        " del " +
        year +
        " a las " +
        h +
        ":" +
        m +
        " horas"
      );
    },
    setDateStringShortPrint(date) {
      var dataSet = new Date(date + "T00:00:00");
      dataSet = dataSet;
      var day = dataSet.getDate();
      var year = dataSet.getFullYear();
      var month = dataSet.getMonth();
      var montTex = "";
      var h = dataSet.getHours();
      var m = dataSet.getMinutes();
      switch (month) {
        case 0:
          montTex = "Enero";
          break;
        case 1:
          montTex = "Febrero";
          break;
        case 2:
          montTex = "Marzo";
          break;
        case 3:
          montTex = "Abril";
          break;
        case 4:
          montTex = "Mayo";
          break;
        case 5:
          montTex = "Junio";
          break;
        case 6:
          montTex = "Julio";
          break;
        case 7:
          montTex = "Agosto";
          break;
        case 8:
          montTex = "Septiembre";
          break;
        case 9:
          montTex = "Octubre";
          break;
        case 10:
          montTex = "Noviembre";
          break;
        case 11:
          montTex = "Diciembre";
        default:
          break;
      }
      if (m == 0) {
        m = "00";
      }
      if (h == 0) {
        h = "00";
      }
      return day + " de " + montTex + " del " + year;
    },
    setDateNow(date) {
      var currentDateObj = date;
      var numberOfMlSeconds = currentDateObj.getTime();
      var addMlSeconds = 60 * 180000;
      var newDateObj = new Date(numberOfMlSeconds - addMlSeconds);
      return newDateObj;
    },
    generateUUID() {
      // Public Domain/MIT
      var d = new Date().getTime(); //Timestamp
      var d2 =
        (typeof performance !== "undefined" &&
          performance.now &&
          performance.now() * 1000) ||
        0; //Time in microseconds since page-load or 0 if unsupported
      return "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = Math.random() * 16; //random number between 0 and 16
        if (d > 0) {
          //Use timestamp until depleted
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        } else {
          //Use microseconds since page-load if supported
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      });
    },
    btnGeneratePdf() {
      let firmabase = this.dte.vtaser[0].Frmt;
      const regex = /^[A-Za-z0-9+/=]+$/;
      let resulttest = regex.test(firmabase);
      if (resulttest) {
        this.imgbase = firmabase;
           if(this.imgbase.length < 100){
          this.imgbase = false;
        }
      } else {
        this.imgbase = false;
      }

      let element = document.getElementById(this.refdinamiac);

      // Calculamos la altura en función del contenido.

      // Calculamos la altura en función del contenido.
      let width = element.offsetWidth;
      let height = element.offsetHeight;
      height = height * 0.74;
      // Definimos el ancho en puntos.
      let pdfWidth = 221; // 7.8cm en puntos.

      // Calculamos el alto proporcionalmente al ancho definido.
      let pdfHeight = Math.round(height);

      // Opciones para html2pdf.
      let options = {
        margin: 1,
        filename: `${this.dte.vtaser[0].Tipo} ${this.dte.vtaser[0].Bnumero}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, scale: 2, useCORS: true },
        jsPDF: {
          unit: "pt",
          format: [pdfWidth, pdfHeight],
          orientation: "portrait",
        },
      };

      // Generamos el PDF.
      // html2pdf().from(element).set(options).save();
      html2pdf()
        .from(element)
        .set(options)
        .outputPdf("datauristring") // Genera una cadena de datos en lugar de descargar el archivo
        .then(function (pdfDataUri) {
          // Abrir el PDF en una nueva ventana
          var newWindow = window.open();
          newWindow.document.write(
            '<iframe src="' +
              pdfDataUri +
              '" style="width:100%; height:100%;" frameborder="0"></iframe>'
          );
        });
    },
    async btnGeneratePdfEmail() {
      let firmabase = this.dte.vtaser[0].Frmt;
      const regex = /^[A-Za-z0-9+/=]+$/;
      let resulttest = regex.test(firmabase);
     
      if (resulttest) {
        this.imgbase = firmabase;
        if(this.imgbase.length > 40){
          this.imgbase = false;
        }
      } else {
        this.imgbase = false;
      }
      let element = document.getElementById(this.refdinamiac);

      // Calculamos la altura en función del contenido.

      // Calculamos la altura en función del contenido.
      let width = element.offsetWidth;
      let height = element.offsetHeight;
      height = height * 0.67;
      // Definimos el ancho en puntos.
      let pdfWidth = 221; // 7.8cm en puntos.

      // Calculamos el alto proporcionalmente al ancho definido.
      let pdfHeight = Math.round(height);

      // Opciones para html2pdf.
      let options = {
        margin: 1,
        filename: `${this.dte.vtaser[0].Tipo} ${this.dte.vtaser[0].Bnumero}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, scale: 2, useCORS: true },
        jsPDF: {
          unit: "pt",
          format: [pdfWidth, pdfHeight],
          orientation: "portrait",
        },
      };
      let pdfbase = "";
      let pdfname = `${this.dte.vtaser[0].Tipo} ${this.dte.vtaser[0].Bnumero}.pdf`;
      // Generamos el PDF.
      // html2pdf().from(element).set(options).save();

      let pdfBase64 = "";
      this.activeLoadingSentEmail = true;
      await html2pdf()
        .from(element)
        .set(options)
        .outputPdf("blob") // Genera un objeto Blob en lugar de descargar el archivo
        .then(async (pdfBlob) => {
          // Leer el archivo PDF como ArrayBuffer
          const reader = new FileReader();
          reader.onloadend = async () => {
            const pdfBytes = new Uint8Array(reader.result);

            // Crear un nuevo PDFDocument con los bytes del PDF
            await PDFDocument.load(pdfBytes)
              .then((doc) => {
                // Convertir el PDF a Base64
                return doc.saveAsBase64();
              })
              .then(async (pdfBase64) => {
                // Utilizar el contenido Base64 del PDF como desees
                let email = {
                  Email: this.dte.cliente.Email,
                  Clinica: this.clinica.Nombre,
                  Attachments: [],
                };
                email.Attachments.push({
                  Content: pdfBase64,
                  Filename: pdfname,
                });
                let resultsend = await this.postPDFDocumentByEmail({
                  email: email,
                });
                if (resultsend == 201) {
                  this.activeSuccessCreateEmail = true;
                  this.activeLoadingSentEmail = false;
                }
              })
              .catch((error) => {
                console.error("Error al convertir el PDF a Base64:", error);
              });
          };
          reader.readAsArrayBuffer(pdfBlob);
        })
        .catch((error) => {
          console.error("Error al generar el PDF:", error);
        });
    },
  },
  computed: {
    ...mapState("auth-module", ["clinica", "user"]),
    calculateTotal() {
      let total = 0;
      this.dte.vtaser.forEach((element) => {
        total += element.Total;
      });
      return total;
    },
    calculateIva() {
      let total = 0;
      this.dte.vtaser.forEach((element) => {
        total += element.Iva;
      });
      return total;
    },
    calculateSubtotal() {
      let total = 0;
      this.dte.vtaser.forEach((element) => {
        total += element.Neto;
      });
      return total;
    },
  },
  created() {
    this.refdinamiac = this.generateUUID();
    this.checkMobile(); // Verifica el estado inicial
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkMobile); // Elimina el listener al destruir el componente
  },
};
</script>
<style>
/* Contenedor principal */
.content-sii {
  padding: 1em;
}
/* Primera línea de información */
.first-row {
  display: flex;
  justify-content: center;
}
/* Tarjeta contenedora de rut + número boleta + fecha */
.first-row-container {
  border: 1px solid #e5e5e5;
  border-radius: 0.2em;
  width: 100%;
  padding: 10px;
  margin-bottom: 0.5em;
  display: flex;
  flex-direction: column;
}
/* Contenido de primera tarjeta */
.first-row-content {
  text-align: center;
}

/* Fuente en negrita */
.black-font {
  font-weight: 800;
}
/* Línea intermedia */
.middle-line {
  border: 1px solid #e5e5e5;
  color: #e5e5e5;
  margin: 0.5em 0 0.5em 0;
}

/* Contenido de tercera fila */
.third-row-content {
  margin: 0 0.5em 0 0.5em;
}
/* Cuerpo del recibo (detalle) */
.receipt-body {
  margin-top: 10px;
  margin-bottom: 10px;
}

.imglogo {
  margin-top: 10px;
  height: 160px;
}

/* Nombre del producto */
.receipt-body .item .name {
  text-align: start;
  margin: 0 0.5em 0 0.5em;
}

.receipt-body .item .details {
  display: flex;
  justify-content: space-between;
  margin: 0 0.5em 0 0.5em;
}
.total-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin: 0 0.5em 0 0.5em;
}

.total-item .label {
  font-weight: bold;
}
</style>
