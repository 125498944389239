<template>
  <div>
    <!-- Documento a imprimir -->
    <v-btn
      class="mx-2"
      color="primary"
      outlined
      @click="btnGeneratePdfFileVtaser"
    >
      <div class="d-flex">
        <v-icon>mdi-file-pdf-box</v-icon>
        <div class="mt-1 rounded-lg text-capitalize">Descargar pdf</div>
      </div>
    </v-btn>
    <div>
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="989999"
        filename="ventas"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="letter"
        pdf-orientation="portrait"
        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="generateVtaserPdf"
      >
        <section slot="pdf-content">
          <div style="padding:10px">
            <h3 class="text-center">Resumen de ventas</h3>
            <div>
              <div>{{ clinica.Nombre }}</div>
              <div>{{ clinica.Direccion }} {{ clinica.Ciudad }}</div>
              <div>{{ clinica.Fono }}</div>
              <div>Sucursal: {{ user.Sucursal }}</div>
              <div>Fecha: {{ onChangeDateVtaser }}</div>
              <div>
                -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </div>
              <div class="content-title-vta">
                <div class="text-center content-title-vta-tipodte">Tipo</div>
                <div class="text-center content-title-vta-bnumero">Numero</div>
                <div class="text-center content-title-vta-tipodte">Ficha</div>
                <div class="text-center content-title-vta-nombre">Nombre</div>
                <div class="content-title-vta-produ">Producto o servicio</div>
                <div class="text-center content-title-vta-cantidad">
                  Cantidad
                </div>
                <div class="text-center content-title-vta-total">Total</div>
                <div class="text-center content-title-vta-nombre">
                  Monto Pagado
                </div>
                <div class="text-center content-title-vta-nombre">Vendedor</div>
              </div>
              <div>
                -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </div>
              <div
                class="content-title-vta"
                v-for="vta in vtasser"
                :key="vta.Id"
              >
                <div class="text-center content-title-vta-tipodte">
                  {{ vta.Tipodte }}
                </div>
                <div class="content-title-vta-bnumero">{{ vta.Bnumero }}</div>
                <div class="text-center content-title-vta-tipodte">
                  {{ vta.Fichan }}
                </div>
                <div class="text-center content-title-vta-nombre">
                  {{ vta.Nombre }}
                </div>
                <div class="content-title-vta-produ">{{ vta.Nompro }}</div>
                <div class="text-center content-title-vta-cantidad">
                  {{ vta.Cantidad }}
                </div>
                <div class="content-title-vta-total">
                  $ {{ vta.Total | formatCurrency }}
                </div>
                <div class="content-title-vta-nombre">
                  $ {{ vta.Montopagado | formatCurrency }}
                </div>
                <div class="content-title-vta-nombre">{{ vta.Codven }}</div>
              </div>
              <div>
                -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </div>
              <div class="total-vta">
                <div>Total Ventas $ {{ calculateTotal | formatCurrency }}</div>
                <div>
                  Pagos recibidos {{ calculateTotalPagado | formatCurrency }}
                </div>
                <div>
                  Por cobrar $
                  {{ (calculateTotal - calculateTotalPagado) | formatCurrency }}
                </div>
              </div>
            </div>
          </div>
        </section>
      </vue-html2pdf>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "generateVtaserPdf",
  components: {
    VueHtml2pdf,
  },
  props: {
    dateVtaser: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      today: new Date().toISOString(),
    };
  },
  methods: {
    setDateShortString(date) {
      var dataSet = new Date(date.substring(0, 10) + "T00:00:00");
      var day = dataSet.getDate();
      var year = dataSet.getFullYear();
      var month = dataSet.getMonth();
      var montTex = "";
      var h = dataSet.getHours();
      var m = dataSet.getMinutes();
      switch (month) {
        case 0:
          montTex = "Enero";
          break;
        case 1:
          montTex = "Febrero";
          break;
        case 2:
          montTex = "Marzo";
          break;
        case 3:
          montTex = "Abril";
          break;
        case 4:
          montTex = "Mayo";
          break;
        case 5:
          montTex = "Junio";
          break;
        case 6:
          montTex = "Julio";
          break;
        case 7:
          montTex = "Agosto";
          break;
        case 8:
          montTex = "Septiembre";
          break;
        case 9:
          montTex = "Octubre";
          break;
        case 10:
          montTex = "Noviembre";
          break;
        case 11:
          montTex = "Diciembre";
        default:
          break;
      }
      if (m == 0) {
        m = "00";
      }
      if (h == 0) {
        h = "00";
      }
      return day + " de " + montTex + " de " + year;
    },
    btnGeneratePdfFileVtaser() {
      this.$refs.generateVtaserPdf.generatePdf();
    },
    setDateString(date) {
      var dataSet = new Date(date);
      dataSet = this.setDateNow(dataSet);
      var day = dataSet.getDate();
      var year = dataSet.getFullYear();
      var month = dataSet.getMonth();
      var montTex = "";
      var h = dataSet.getHours();
      var m = dataSet.getMinutes();
      switch (month) {
        case 0:
          montTex = "Enero";
          break;
        case 1:
          montTex = "Febrero";
          break;
        case 2:
          montTex = "Marzo";
          break;
        case 3:
          montTex = "Abril";
          break;
        case 4:
          montTex = "Mayo";
          break;
        case 5:
          montTex = "Junio";
          break;
        case 6:
          montTex = "Julio";
          break;
        case 7:
          montTex = "Agosto";
          break;
        case 8:
          montTex = "Septiembre";
          break;
        case 9:
          montTex = "Octubre";
          break;
        case 10:
          montTex = "Noviembre";
          break;
        case 11:
          montTex = "Diciembre";
        default:
          break;
      }
      if (m == 0) {
        m = "00";
      }
      if (h == 0) {
        h = "00";
      }
      return day + " de " + montTex + " del " + year + " a las " + h + ":" + m;
    },
    setDateStringPrint(date) {
      var dataSet = new Date(date);
      dataSet = dataSet;
      var day = dataSet.getDate();
      var year = dataSet.getFullYear();
      var month = dataSet.getMonth();
      var montTex = "";
      var h = dataSet.getHours();
      var m = dataSet.getMinutes();
      switch (month) {
        case 0:
          montTex = "Enero";
          break;
        case 1:
          montTex = "Febrero";
          break;
        case 2:
          montTex = "Marzo";
          break;
        case 3:
          montTex = "Abril";
          break;
        case 4:
          montTex = "Mayo";
          break;
        case 5:
          montTex = "Junio";
          break;
        case 6:
          montTex = "Julio";
          break;
        case 7:
          montTex = "Agosto";
          break;
        case 8:
          montTex = "Septiembre";
          break;
        case 9:
          montTex = "Octubre";
          break;
        case 10:
          montTex = "Noviembre";
          break;
        case 11:
          montTex = "Diciembre";
        default:
          break;
      }
      if (m == 0) {
        m = "00";
      }
      if (h == 0) {
        h = "00";
      }
      return (
        day +
        " de " +
        montTex +
        " del " +
        year +
        " a las " +
        h +
        ":" +
        m +
        " horas"
      );
    },
    setDateStringShortPrint(date) {
      var dataSet = new Date(date + "T00:00:00");
      dataSet = dataSet;
      var day = dataSet.getDate();
      var year = dataSet.getFullYear();
      var month = dataSet.getMonth();
      var montTex = "";
      var h = dataSet.getHours();
      var m = dataSet.getMinutes();
      switch (month) {
        case 0:
          montTex = "Enero";
          break;
        case 1:
          montTex = "Febrero";
          break;
        case 2:
          montTex = "Marzo";
          break;
        case 3:
          montTex = "Abril";
          break;
        case 4:
          montTex = "Mayo";
          break;
        case 5:
          montTex = "Junio";
          break;
        case 6:
          montTex = "Julio";
          break;
        case 7:
          montTex = "Agosto";
          break;
        case 8:
          montTex = "Septiembre";
          break;
        case 9:
          montTex = "Octubre";
          break;
        case 10:
          montTex = "Noviembre";
          break;
        case 11:
          montTex = "Diciembre";
        default:
          break;
      }
      if (m == 0) {
        m = "00";
      }
      if (h == 0) {
        h = "00";
      }
      return day + " de " + montTex + " del " + year;
    },
    setDateNow(date) {
      var currentDateObj = date;
      var numberOfMlSeconds = currentDateObj.getTime();
      var addMlSeconds = 60 * 180000;
      var newDateObj = new Date(numberOfMlSeconds - addMlSeconds);
      return newDateObj;
    },
    getAge(dateString) {
      var now = new Date();
      var today = new Date(now.getYear(), now.getMonth(), now.getDate());

      var yearNow = now.getYear();
      var monthNow = now.getMonth();
      var dateNow = now.getDate();

      var dob = new Date(
        dateString.substring(0, 4),
        dateString.substring(5, 7) - 1,
        dateString.substring(8, 10)
      );

      var yearDob = dob.getYear();
      var monthDob = dob.getMonth();
      var dateDob = dob.getDate();
      var age = {};
      var ageString = "";
      var yearString = "";
      var monthString = "";
      var dayString = "";

      let yearAge = yearNow - yearDob;

      if (monthNow >= monthDob) var monthAge = monthNow - monthDob;
      else {
        yearAge--;
        var monthAge = 12 + monthNow - monthDob;
      }

      if (dateNow >= dateDob) var dateAge = dateNow - dateDob;
      else {
        monthAge--;
        var dateAge = 31 + dateNow - dateDob;

        if (monthAge < 0) {
          monthAge = 11;
          yearAge--;
        }
      }

      age = {
        years: yearAge,
        months: monthAge,
        days: dateAge,
      };

      if (age.years > 1) yearString = " años";
      else yearString = " año";
      if (age.months > 1) monthString = " meses";
      else monthString = " mes";
      if (age.days > 1) dayString = " días";
      else dayString = " día";

      if (age.years > 0 && age.months > 0 && age.days > 0)
        ageString =
          age.years +
          yearString +
          ", " +
          age.months +
          monthString +
          ", y " +
          age.days +
          dayString;
      else if (age.years == 0 && age.months == 0 && age.days > 0)
        ageString = "solo " + age.days + dayString;
      else if (age.years > 0 && age.months == 0 && age.days == 0)
        ageString = age.years + yearString + " Feliz cumpleaños!!";
      else if (age.years > 0 && age.months > 0 && age.days == 0)
        ageString = age.years + yearString + " y " + age.months + monthString;
      else if (age.years == 0 && age.months > 0 && age.days > 0)
        ageString =
          age.months + monthString + " y " + age.days + dayString + ".";
      else if (age.years > 0 && age.months == 0 && age.days > 0)
        ageString = age.years + yearString + " y " + age.days + dayString + ".";
      else if (age.years == 0 && age.months > 0 && age.days == 0)
        ageString = age.months + monthString + " .";
      else ageString = "Oops! No es posible calcular!";

      return ageString;
    },
  },
  computed: {
    ...mapState("sales-module", ["vtasser"]),
    ...mapState("auth-module", ["clinica", "user"]),
    calculateTotal() {
      let total = 0;
      this.vtasser.forEach((element) => {
        total += element.Total;
      });
      return total;
    },
    calculateTotalPagado() {
      let total = 0;
      this.vtasser.forEach((element) => {
        total += element.Montopagado;
      });
      return total;
    },
    onChangeDateVtaser() {
      return this.setDateStringShortPrint(this.dateVtaser);
    },
  },
  created() {},
};
</script>
<style>
/* Contenido receta a4 */
strong {
  font-weight: 800;
  letter-spacing: 2px;
}
.body-vtaser {
  padding: 10px;
}
.text-center {
  text-align: center;
}
.content-title-vta {
  display: flex;
  width: 100%;
}
.content-title-vta-tipodte {
  width: 5%;
}
.content-title-vta-cantidad {
  width: 8%;
}
.content-title-vta-bnumero {
  width: 10%;
}
.content-title-vta-total {
  width: 14%;
}
.content-title-vta-produ {
  width: 20%;
}
.content-title-vta-nombre {
  width: 12%;
}
</style>